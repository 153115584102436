import React, { useEffect } from "react";
import { routes } from "./Routes";
import { useLocation, useRoutes } from "react-router-dom";

//This is Routing index file here we invoked all Routes
export const AppRoutes = () => {
  const location = useLocation();
  const element = useRoutes(routes);

  function previousPathSetter() {
    if (location.pathname !== sessionStorage.getItem("previousPath")) {
      if (location.pathname !== "/") {
        sessionStorage.setItem("previousPath", location.pathname);
      }
    }
  }
  useEffect(() => {
    previousPathSetter();
  }, [location]);
  return <>{element}</>;
};
