import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const UnauthorizedAccess = () => {
  const [time, setTime] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    let timerId = setTimeout(() => {
      setTime((pre) => pre - 1);
    }, 1000);
    if (time === 0) {
      clearTimeout(timerId);
      navigate("/dashboard");
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [time]);
  return (
    <>
      {/* <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "300px",
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2>Page Not Found</h2>
        <p>You are redirect to Main page with 0{time} seconds.</p>
      </div>
    </div> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#362580",
        }}
      >
        <Typography variant="h1" style={{ color: "white" }}>
          Sorry!
        </Typography>
        <Typography variant="h6" style={{ color: "white" }}>
          You are not authorized to view this page!
        </Typography>
        <br />
        <Typography variant="h6" style={{ color: "white" }}>
          Back to Main Page in 0{time} seconds.
        </Typography>
      </Box>
    </>
  );
};

export default UnauthorizedAccess;
