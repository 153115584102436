import React from 'react'

const ReuseableLabel = (props) => {
  return (
    <>
      <span>{props.label} <span style={{color:"red"}}>*</span></span>
    </>
  )
}

export default ReuseableLabel
