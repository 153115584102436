import AddQuestion from "../Pages/AddQuestion";
import Login from "../Pages/Login";
import QuestionTable from "../Pages/QuestionTable";
import JobRoleTable from "../Components/Dummy/JobRoleTable";
import CheckBoxTable from "../Components/Dummy/CheckBoxTable";
import HiringCompanySetUp from "../Pages/HiringCompanySetUp";
import RecruiterTabDashboard from "../Pages/RecruiterTabDashboard";
import JobTabDashboard from "../Pages/JobTabDashboard";
import SelectedQuestion from "../Pages/SelectedQuestion";
import SelectQuestion from "../Pages/SelectQuestion";
import EditForm from "../Components/Add_Edit_QuestionForm/EditForm";
import OpenningsTable from "../Components/Table/OpenningsTable";
import OpeningsTabDashboard from "../Pages/OpeningsTabDashboard";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../Pages/PageNotFound";
import HiringTabDashboard from "../Pages/HiringTabDashboard";
import RoleMoule from "../Components/RoleModule/RoleMoule";
import RoleModuleDashBoard from "../Pages/RoleModuleDashBoard";
import Dashboard from "../Pages/Dashboard";
import UnauthorizedAccess from "../Pages/UnauthorizedAccess";

// import SelectQuestionTable from "../Components/Table/SelectQuestionTable";
export const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "dashboard",
    element: <PrivateRoute element={<Dashboard />} roleModule="Dashboard" />,
  },
  {
    path: "questions",
    element: <PrivateRoute element={<QuestionTable />} roleModule="Questions" />,
  },
  {
    path: "questions/add_question",
    element: <PrivateRoute element={<AddQuestion />} roleModule="Questions" />,
  },
  {
    path: "questions/edit_question/:qId",
    element: <PrivateRoute element={<EditForm />} roleModule="Questions" />,
  },
  {
    path: "hiring_company",
    element: <PrivateRoute element={<HiringTabDashboard />} roleModule="Hiring Company" />,
  },
  {
    path: "openings_table",
    element: <PrivateRoute element={<OpeningsTabDashboard />} roleModule="Openings" />,
  },
  {
    path: "recruiter_tab_dashboard",
    element: <PrivateRoute element={<RecruiterTabDashboard />} roleModule="Recruitment Company" />,
  },
  {
    path: "selected_question/select_question/:openingId/:jobRole/:companyName",
    element: <PrivateRoute element={<SelectQuestion />} roleModule="Selected Questions" />,
  },
  {
    path: "selected_question",
    element: <PrivateRoute element={<SelectedQuestion />} roleModule="Selected Questions" />,
  },
  {
    path: "selected_question/:jobRoleId/:recCompanyIds",
    element: <PrivateRoute element={<SelectedQuestion />} roleModule="Selected Questions" />,
  },
  {
    path: "job_role_table",
    element: <PrivateRoute element={<JobTabDashboard />} roleModule="Job Role" />,
  },
  {
    path:"role_module",
    element:<PrivateRoute element={<RoleModuleDashBoard/>}/>
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/access_denied",
    element: <UnauthorizedAccess />,
  },
  // {
  //     path:"jobTable",
  //     element:<JobRoleTable/>
  // },
  // {
  //     path:"ChkBox_question",
  //     element:<CheckBoxTable/>
  // }
];
