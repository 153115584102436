import {
  Card,
  FormControlLabel,
  Typography,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// import { Box, Stack } from "@mui/system";
import React from "react";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#041c44" : "#ff9900",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#041c44" : "#ff9900",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const flex = {
  display: "flex",
  justifyContent: "space-between",
  alignItem: "center",
  rowGap: "20px",
};

const switchClick = (e) => {
  console.log("hello", e.altKey);
};

const ModuleCard = (props) => {
  return (
    <Card
      sx={{
        maxWidth: 397,
        height: "230px",
        padding: "10px 10px 10px 10px",
        backgroundColor: "rgba(17, 25, 40, 0.19)",
        borderRadius: "12px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px) saturate(200%)",
        WebkitBackdropFilter: "blur(5px) saturate(200%)",
        border: "1px solid rgba(255, 255, 255, 0.125)",
      }}
    >
      <Typography variant="h5" textAlign={"center"}>
        {props.header}
      </Typography>
      <Stack direction={"column"} gap={2} mt="30px">
        <Box style={flex}>
          <Typography>{props.list}</Typography>
          <FormControlLabel
            control={<IOSSwitch onClick={(e) => switchClick(e)} />}
          />
        </Box>
        <Box style={flex}>
          <Typography>{props.add}</Typography>
          <FormControlLabel
            control={<IOSSwitch onClick={() => switchClick} />}
          />
        </Box>
        <Box style={flex}>
          <Typography>{props.edit}</Typography>
          <FormControlLabel
            control={<IOSSwitch onClick={() => switchClick} />}
          />
        </Box>
        <Box style={flex}>
          <Typography>{props.delete}</Typography>
          <FormControlLabel
            control={<IOSSwitch onClick={() => switchClick} />}
          />
        </Box>
      </Stack>
    </Card>
  );
};

export default ModuleCard;
