import * as types from "./actionTypes";
import axios from "axios";

import { environmentDetails } from "../../environment/environment";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import {accessTokenHeadersObject} from '../../utils/headers'

const apiUrl = environmentDetails.apiUrl;
const countryUrl = environmentDetails.countryUrl;
const cityUrl = environmentDetails.cityUrl;

const getCountryData = () => (dispatch) => {
  dispatch({ type: types.GET_COUNTRY_REQUEST });
  return privateAxiosInstance.get(
      `/questionnaire/get-allcountry`,{headers:{...accessTokenHeadersObject()}}
    )
    .then((res) => {
      return dispatch({
        type: types.GET_COUNTRY_SUCCESS,
        payload: res.data.data,
        datalength: res.data.dataLength,
      });
    })
    .catch((err) => {
     // console.log("err", err);
      return dispatch({ type: types.GET_COUNTRY_FAILURE, payload: err });
    });
};

const getCountryName =() => (dispatch) => {
  dispatch({ type: types.GET_COUNTRY_NAME_REQUEST });
  return axios.get(
      countryUrl
    )
    .then((res) => {
      return dispatch({
        type: types.GET_COUNTRY_NAME_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
     // console.log("err", err);
      return dispatch({ type: types.GET_COUNTRY_NAME_FAILURE, payload: err });
    });
}

const getCityName =(data) => (dispatch) => {
  dispatch({ type: types.GET_CITY_NAME_REQUEST});
  return axios.post(
      cityUrl,data
    )
    .then((res) => {
      return dispatch({
        type: types.GET_CITY_NAME_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
     // console.log("err", err);
      return dispatch({ type: types.GET_CITY_NAME_FAILURE, payload: err });
    });
}

export { getCountryData,getCountryName,getCityName };
