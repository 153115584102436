import React from "react";
import Login from "../Pages/Login";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { roleModulePermissions } from "../utils/roleInstance";

const PrivateRoute = ({ element, roleModule }) => {
  const location = useLocation();
  const isUserLoggedIn = useSelector((state) => state.authReducer.data);
  //console.log("private route page: window history: ",window.history)
  // console.log("private route called Location: ", location);
  //   if (isUserLoggedIn) {
  //     return children;
  //   } else {
  //     return <Login />;
  //   }
  
  const roleData = {
    roleModule
  }
  let roleAccess = roleModulePermissions.checkUserRoles(roleData,'view')
  if(roleAccess == true){
    return isUserLoggedIn ? (
      element
    ) : (
      <Navigate to="/" state={{ from: location?.pathname,name:"hello world" }} replace />
    );
  }
  else{
    if(roleModule == "Dashboard"){
      return isUserLoggedIn ? (
        element
      ) : (
        <Navigate to="/" state={{ from: location?.pathname,name:"hello world" }} replace />
      );
    }
    else{
      if(isUserLoggedIn){
        if(roleModule != "Dashboard"){
          return <Navigate to="/access_denied" state={{ from: location?.pathname,name:"hello world" }} replace />
        }
      }
      else{
        return <Navigate to="/" state={{ from: location?.pathname,name:"hello world" }} replace />
      }
    }    
  }  
};

export default PrivateRoute;
