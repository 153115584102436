import * as types from "./actionTypes";
import axios from "axios";

import { environmentDetails } from "../../environment/environment";

const jobOpeningUrl=environmentDetails.jobOpeningUrl;

const getJDFromATS= ()=> (dispatch) => {
    dispatch({ type: types.GET_JD_FROM_ATS_REQUEST });
    return axios.get(
        jobOpeningUrl
      )
      .then((res) => {
        return dispatch({
          type: types.GET_JD_FROM_ATS_SUCCESS,
          payload: res.data,
          
        });
      })
      .catch((err) => {
       // console.log("err", err);
        return dispatch({ type: types.GET_JD_FROM_ATS_FAILURE, payload: err });
      });
}

export {getJDFromATS};