import * as types from "./actionTypes";
import axios from "axios";

import { environmentDetails } from "../../../src/environment/environment";
import { accessTokenHeadersObject } from "../../utils/headers";
import { privateAxiosInstance } from "../../utils/axiosInstance";

const apiUrl = environmentDetails.apiUrl;

//This Function Calling Question GET API
const getQuestionsData = (page=1, rowsPerPage=10,questionQuery="") => (dispatch) => {
  dispatch({ type: types.GET_QUESTIONS_REQUEST });
  return privateAxiosInstance
    .get(`/questionnaire/get-que?page=${page}&pageSize=${rowsPerPage}&question=${questionQuery}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.GET_QUESTIONS_SUCCESS,
        payload: res.data.data,
        datalength: res.data.dataLength,
      });
    })
    .catch((err) => {
     // console.log("err", err);
      return dispatch({ type: types.GET_QUESTIONS_FAILURE, payload: err });
    });
};

const getQuestionIdData = (qId) => (dispatch) => {
  dispatch({ type: types.GET_QUESTIONID_REQUEST });
  return privateAxiosInstance
    .get(`/questionnaire/get-que/${qId}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
     // console.log("res", res);
      return dispatch({
        type: types.GET_QUESTIONID_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      //console.log("err", err);
      return dispatch({ type: types.GET_QUESTIONID_FAILURE, payload: err });
    });
};

const postQuestionsData = (data) => (dispatch) => {
  dispatch({ type: types.POST_QUESTIONS_REQUEST });
  return privateAxiosInstance
    .post(`/questionnaire/save-que`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.POST_QUESTIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.POST_QUESTIONS_FAILURE, payload: err });
    });
};

//This Function Calling Question EDIT/UPDATE API
const editQuestionData = (id, updateData) => (dispatch) => {
  dispatch({ type: types.EDIT_QUESTION_REQUEST });
  return privateAxiosInstance
    .put(`/questionnaire/update-que/${id}`, updateData, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.EDIT_QUESTIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.EDIT_QUESTIONS_FAILURE, payload: err });
    });
};

//This Function Calling Question DELETE API
const deleteQuestionData = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_QUESTION_REQUEST });
  return privateAxiosInstance
    .delete(`/questionnaire/delete-que/${id}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      //("ReducerDelete", res);
      return dispatch({
        type: types.DELETE_QUESTIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.DELETE_QUESTIONS_FAILURE, payload: err });
    });
};

export {
  getQuestionsData,
  getQuestionIdData,
  postQuestionsData,
  editQuestionData,
  deleteQuestionData,
};
