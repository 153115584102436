import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import SearchFeild from "../Components/Table/SearchFeild";
import TableRows from "../Components/Table/TableRow";
import { useNavigate, useLocation } from "react-router-dom";
import Slide from "@mui/material/Slide";
import DeleteAlert from "../Components/AlertBox/DeleteAlert";
import { useDispatch, useSelector } from "react-redux";
import ApiAlert from "../Components/AlertBox/ApiAlert";
import { useDebounce } from "../hooks/useDebounce";
import {
  getQuestionsData,
  editQuestionData,
  postQuestionsData,
  deleteQuestionData,
} from "../Redux/Questions/action";
import { columns, columns2 } from "../constants/masterQuestion";
import { IOSSwitchButton } from "../Components/ReusableComponents/SwitchButtons";
import { roleModulePermissions } from "../utils/roleInstance";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  sticky: {
    position: "sticky",
    right: 0,
  },
  editIconButton: {
    "&:hover": {
      color: "rgb(254, 154, 37)", // Set EditIcon hover text color here
    },
  },
  deleteIconButton: {
    "&:hover": {
      color: "red", // Set deleteIconButton hover text color here
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      // borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid gray`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
});

const QuestionTable = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [quesData, setQuesData] = useState([]);
  const navigate = useNavigate();
  //const [idToDelete, setIdToDelete] = useState(null);
  const [switchInactive, setSwitchInactive] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [open, setOpen] = React.useState(false);
  const { debouncedValue } = useDebounce(search, 500);
  const classes = useStyles();

  const handleClickOpen = (data) => {
    setOpen(true);
    setSwitchInactive(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    // const newPage = page + 1;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch();
  const masterQuestions = useSelector(
    (state) => state.questionReducer.questions
  );

  const masterQuestionsLength = useSelector(
    (state) => state.questionReducer.length
  );
  // console.log("masterQuestionsLength", masterQuestionsLength);

  var alert_content = JSON.parse(localStorage.getItem("questionToast"));

  const getQuestionsDataFn = () => {
    dispatch(getQuestionsData(page + 1, rowsPerPage, debouncedValue));
  };

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [debouncedValue && masterQuestionsLength]);

  useEffect(() => {
    getQuestionsDataFn();
    if (alert_content != null) {
      if (
        alert_content.alertContent != "" ||
        alert_content.alertContent != undefined
      ) {
        setAlert(true);
        setalertcontent(alert_content.alertContent);
        setAlertStatus(alert_content.alertStatus);
        setTimeout(() => {
          setAlert(false);
          localStorage.removeItem("questionToast");
        }, 1000);
      }
    }
  }, [page, rowsPerPage, debouncedValue]);

  // const handleClick = () => {
  //   dispatch(deleteQuestionData(idToDelete))
  //     .then((res) => {
  //       setAlert(true);
  //       setAlertStatus(res.payload.status);
  //       setalertcontent(res.payload.message);
  //       handleClose();
  //       dispatch(getQuestionsData());
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  let x = !switchInactive?.questionStatus;
  //console.log(x, "switchInactive", switchInactive);

  const updateMasterQuestionStatus = (data) => {
    let x = !data.questionStatus;
    //  let y =data.questionStatus
    //console.log(x,data)
    dispatch(editQuestionData(data.qId, { questionStatus: x })).then((res) => {
      if (res.type === `EDIT_QUESTIONS_SUCCESS`) {
        handleClose();
        getQuestionsDataFn();
      } else {
        console.log("edit master question err: ", res);
        getQuestionsDataFn();
      }
    });
  };

  // Check Role Permission
  const [questionEditAction, setQuestionEditAction] = useState("");
  const [questionDeleteAction, setQuestionDeleteAction] = useState("");

  // const checkQuestionEditAction = async () => {
  //   const roleData = {
  //     roleModule: "Questions",
  //   };
  //   let rolePermission = await roleModulePermissions.checkUserRoles(
  //     roleData,
  //     "edit"
  //   );
  //   // return rolePermission
  //   setQuestionEditAction(rolePermission);
  // };

  // const checkQuestionDeleteAction = async () => {
  //   const roleData = {
  //     roleModule: "Questions",
  //   };
  //   let rolePermission = await roleModulePermissions.checkUserRoles(
  //     roleData,
  //     "delete"
  //   );
  //   // return rolePermission
  //   setQuestionDeleteAction(rolePermission);
  // };

  // useEffect(() => {
  //   checkQuestionEditAction();
  //   checkQuestionDeleteAction();
  // },[page]);
  // Check Role Permission

  useEffect(() => {
    const checkQuestionAction = async () => {
      const roleData = {
        roleModule: "Questions",
      };
      const [editPermission, deletePermission] = await Promise.all([
        roleModulePermissions.checkUserRoles(roleData, "edit"),
        roleModulePermissions.checkUserRoles(roleData, "delete"),
      ]);
      setQuestionEditAction(editPermission);
      setQuestionDeleteAction(deletePermission);
    };
    checkQuestionAction();
  }, [page]);

  // console.log(`master question length== ${masterQuestionsLength}`);
  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Box sx={{ width: "100%", height: "100%" }}>
        <SearchFeild
          onChange={(e) => setSearch(e.target.value)}
          buttonName="Add Master Question"
          Navigate={() => navigate("add_question")}
          placeholder="Search Question or Question Type"
          width="100%"
          TextFieldWidth="30%"
          marginBottom={"15px"}
          roleModule={"Questions"}
          // medium="40px"
        />
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "calc(100vh - 170px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* This Columns Data getting from manually Array of object */}
                    {columns2.map((column, idx) => (
                      <TableCell
                        key={idx}
                        className={
                          (questionEditAction != undefined &&
                            questionEditAction != "") ||
                          (questionDeleteAction != undefined &&
                            questionDeleteAction != "")
                            ? column.id === "Action"
                              ? classes.sticky
                              : null
                            : ""
                        }
                        sx={{
                          // backgroundColor: "rgb(30, 13, 97)",
                          //color: "rgb(254, 154, 37)",
                          backgroundColor: "primary.main",
                          color: "secondary.main",
                          minWidth: column.minWidth,
                        }}
                        align="center"
                      >
                        {column.id == "Action"
                          ? (questionEditAction != undefined &&
                              questionEditAction != "") ||
                            (questionDeleteAction != undefined &&
                              questionDeleteAction != "")
                            ? column.label
                            : ""
                          : column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* This is Table Row components import from TableRow File. */}
                <TableBody>
                  {/* This quesData getting from  get-question*/}
                  {masterQuestions?.map((item, index) => {
                    return (
                      <>
                        <TableRow
                          key={index}
                          sx={{
                            opacity: item?.questionStatus ? null : "0.7",
                            backgroundColor: item?.questionStatus
                              ? null
                              : "primary.disabledBackground",
                          }}
                        >
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {item?.Question !== "" ? item.Question : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {item?.questionType ? item.questionType : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.Weight !== "" ? item.Weight : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.Ack !== "" ? item.Ack : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.defaultValue !== ""
                              ? item.defaultValue
                              : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.["Next Question Prefix"] === ""
                              ? "N/A"
                              : item?.["Next Question Prefix"]}
                          </TableCell>
                          <TableCell align="center">
                            {item?.["Help Text"] !== ""
                              ? item?.["Help Text"]
                              : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.expectDocument !== ""
                              ? item?.expectDocument === "yes"
                                ? "Yes"
                                : "No"
                              : "N/A"}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              cursor: "pointer",
                              position:
                                (questionDeleteAction != "" &&
                                  questionDeleteAction != undefined) ||
                                (questionEditAction != "" &&
                                  questionEditAction != undefined)
                                  ? "sticky"
                                  : "",
                              right: 0,
                              // background: "white",
                              backgroundColor: item.questionStatus
                                ? "primary.background"
                                : "primary.disabledBackground",
                              //boxShadow: "5px 2px 5px grey",
                            }}
                          >
                            <Stack
                              direction="row"
                              spacing={"10px"}
                              justifyContent={"flex-end"}
                            >
                              {questionEditAction != "" &&
                              questionEditAction != undefined
                                ? item?.questionStatus && (
                                    <Tooltip
                                      title="Edit Question"
                                      arrow
                                      placement="top"
                                    >
                                      <EditOutlined
                                        fontSize="medium"
                                        className={classes.editIconButton}
                                        onClick={() =>
                                          item.questionStatus
                                            ? navigate(
                                                `edit_question/${item.qId}`
                                              )
                                            : null
                                        }
                                      />
                                    </Tooltip>
                                  )
                                : ""}
                              {/* <DeleteOutline
                            className={classes.deleteIconButton}
                            fontSize="medium"
                            style={{ marginLeft: 8 }}
                            //onClick={deleteClick}
                          /> */}
                              {questionDeleteAction != "" &&
                              questionDeleteAction != undefined ? (
                                <Tooltip
                                  title={
                                    item.questionStatus ? "Disable" : "Enable"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <IOSSwitchButton
                                    checked={item.questionStatus === true}
                                    // onClick={() => {
                                    //   updateMasterQuestionStatus(
                                    //     item.qId,
                                    //     !item.questionStatus
                                    //   );
                                    // }}
                                    onClick={
                                      item.questionStatus === false
                                        ? () => updateMasterQuestionStatus(item)
                                        : () => handleClickOpen(item)
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                        <>
                          {/*
                        <TableRows
                          key={item.qId}
                          slNo={page * rowsPerPage + index + 1}
                          question={
                            item?.Question !== "" ? item.Question : "N/A"
                          }
                          weight={item?.Weight !== "" ? item.Weight : "N/A"}
                          ack={item?.Ack !== "" ? item.Ack : "N/A"}
                          processVariable={
                            item?.["Process Variable"] === ""
                              ? "N/A"
                              : item?.["Process Variable"]
                          }
                          defaultValue={
                            item?.defaultValue !== ""
                              ? item.defaultValue
                              : "N/A"
                          }
                          botInterpreted={
                            item?.["Bot Interpreted"] !== ""
                              ? item?.["Bot Interpreted"]
                              : "N/A"
                          }
                          botName={
                            item?.["Bot Name"] !== ""
                              ? item?.["Bot Name"]
                              : "N/A"
                          }
                          path={item?.Path !== "" ? item.Path : "N/A"}
                          adaptive={
                            item?.Adaptive !== "" ? item.Adaptive : "N/A"
                          }
                          yPath={item?.yPath !== "" ? item.yPath : "N/A"}
                          nPath={item?.nPath !== "" ? item.nPath : "N/A"}
                          nextQuestionPrefix={
                            item?.["Next Question Prefix"] === ""
                              ? "N/A"
                              : item?.["Next Question Prefix"]
                          }
                          helpText={
                            item?.["Help Text"] !== ""
                              ? item?.["Help Text"]
                              : "N/A"
                          }
                          type={item?.Type !== "" ? item.Type : "N/A"}
                          option1={item?.Option1 === "" ? "N/A" : item?.Option1}
                          option2={item?.Option2 === "" ? "N/A" : item?.Option2}
                          option3={item?.Option3 === "" ? "N/A" : item?.Option3}
                          processingNeeded={item?.["Processing Needed"]}
                          templateName={
                            item?.["Template Name"] !== ""
                              ? item?.["Template Name"]
                              : "N/A"
                          }
                          templateVariable={
                            item?.template_variable !== ""
                              ? item?.template_variable
                              : "N/A"
                          }
                          deleteClick={() => handleClickOpen(item.qId)}
                          editClick={() =>
                            navigate(`edit_question/${item.qId}`)
                          }
                        />*/}
                        </>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/*This is TablePagination we are using for Table Pagination*/}
            <TablePagination
              // rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              onPageChange={handleChangePage}
              page={page}
              count={masterQuestionsLength}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <DeleteAlert
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            handleDelete={() => updateMasterQuestionStatus(switchInactive)}
            header={"Inactive"}
            message={"Are you sure you want to make  this item inactive?"}
            rightButton={"Inactive"}
          />
        </Box>
      </Box>
    </>
  );
};

export default QuestionTable;
