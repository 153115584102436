import { useEffect, useRef, useState } from "react";

const useDebounce = (value,delay) => {

  let delayVal;
  if(delay){
    delayVal=delay;
  }
  else{
    delayVal=1500
  }
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value),600);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return { debouncedValue };
};
export { useDebounce };
