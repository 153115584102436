import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Paper,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Menu,
} from "@mui/material";
import parse from "html-react-parser";
import { Close, InfoOutlined } from "@mui/icons-material";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import { getJDFromATS } from "../Redux/JD_ATS/action";
import { useDispatch } from "react-redux";

//column of ATS table
const columns = [
  { id: "SNo", label: "Sl. #", minWidth: 70, align: "left" },
  { id: "JobRole", label: "Job Role", minWidth: 150, align: "left" },
  { id: "Skills", label: "Required Skills", minWidth: 130, align: "left" },
  { id: "Country", label: "Country", minWidth: 100, align: "left" },
  { id: "City", label: "City", minWidth: 10, align: "left" },
  {
    id: "YearsOfExperience",
    label: "Years of Experience",
    minWidth: 100,
    align: "left",
  },
  {
    id: "JobDescription",
    label: "Job Description",
    minWidth: 180,
    align: "center",
  },
  {
    id: "JobOpeningId ",
    label: "Job Opening Id",
    minWidth: 110,
    align: "left",
  },
  { id: "Recruiter", label: "Recruiter", minWidth: 80, align: "left" },
];

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  maxHeight: "100vh",
  overflow: "auto",
};

const ATSJobDescripton = ({ atsData }) => {
  const [open, setOpen] = React.useState(false);
  const [searchRole, setSearchRole] = useState("");
  const [searchSkill, setSearchSkill] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [showDesc, setShowDesc] = useState("");
  const [isItemSelected, setIsItemSelected] = useState(-1);
  const [jd, setJD] = useState([]);
  const options = {
    replace(domNode) {
      domNode.text = domNode.text?.replace(/\n/g, "");
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const dispatch = useDispatch();
  const openJobDesc = Boolean(anchorEl);

  const handleOpen = () => {
    setOpen(true);
    dispatch(getJDFromATS()).then((res) => {
      if (res.type === "GET_JD_FROM_ATS_SUCCESS") {
        setJD(res.payload.response);
      }
    });
  };

  const handleClose = () => {
    setIsItemSelected(-1);
    setShowDesc("");
    setAnchorEl("");
    setSearchRole("");
    setSearchSkill("");
    setOpen(false);
  };

  // useEffect(() => {
  //   setOpen &&
  //     dispatch(getJDFromATS()).then((res) => {
  //       if (res.type === "GET_JD_FROM_ATS_SUCCESS") {
  //         setJD(res.payload.response);
  //       }
  //     });
  // }, []);

  //checkbox single selection
  const handleClick = (index) => {
    if (isItemSelected == index) {
      setIsItemSelected(-1);
    } else {
      setIsItemSelected(index);
    }
  };

  //on submit
  const handleSubmit = () => {
    if (isItemSelected >= 0) {
      atsData(jd[isItemSelected]);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip
        title={"Pull Job Description from ATS."}
        arrow
        placement="right-start"
      >
        <Box>
          <Button
            onClick={handleOpen}
            size="small"
            sx={{
              color: "#fff",
              backgroundColor: "primary.main",
              width: "25%",
              float: "right",
              // height: "20%",
              // marginLeft: "450px",
              textTransform: "none",
              "&:hover": {
                // color: "primary.main",
                // backgroundColor: "#fff",
                // border: `2px solid #041c44`,
                backgroundColor: "primary.main",
                boxShadow:
                  " 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            color="secondary"
            backgroundColor="primary"
          >
            Pull ATS JD
          </Button>
        </Box>
      </Tooltip>

      <Modal
        open={open}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: 600,
              textAlign: "center",
              color: "primary.darkText",
              marginBottom: "10px",
            }}
          >
            Pull Job Description from ATS
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "0", right: "0" }}
          >
            <CloseIcon />
          </IconButton>

          <Box style={{ width: "100%" }}>
            {/* search for job role  */}
            <TextField
              sx={{ width: "50%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon style={{ color: "#CAC2C2" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "12px",
                  height: "56px",
                  padding: 2,
                  width: "50%",
                },
              }}
              variant="outlined"
              type="text"
              placeholder="Search job role"
              onChange={(e) => setSearchRole(e.target.value)}
            />
            {/* search for skill */}
            <TextField
              sx={{ width: "50%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon style={{ color: "#CAC2C2" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "12px",
                  height: "56px",
                  padding: 2,
                  width: "50%",
                },
              }}
              variant="outlined"
              type="text"
              placeholder="Search skill"
              onChange={(e) => setSearchSkill(e.target.value)}
            />
          </Box>
          {/* Table */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "blue",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: "30vh" }}>
                <Table
                  sx={{ minWidth: 500 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead sx={{ height: "60px" }}>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        sx={{ backgroundColor: "#041c44" }}
                      ></TableCell>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            sx={{
                              backgroundColor: "primary.main",
                              color: "secondary.main",
                              minWidth: column.minWidth,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jd
                      .filter((e) => {
                        let arr_skill = [];
                        let s = "";
                        for (var i = 0; i < e.requiredSkills.length; i++) {
                          if (e.requiredSkills[i] == ",") {
                            arr_skill.push(s);
                            s = "";
                          } else s += e.requiredSkills[i];
                        }
                        arr_skill.push(s);
                        if (searchRole) {
                          return JSON.stringify(e.postingTitle)
                            .toLowerCase()
                            .includes(searchRole.toLowerCase());
                        }
                        if (searchSkill) {
                          return arr_skill.some((skill) =>
                            JSON.stringify(skill)
                              .toLowerCase()
                              .includes(searchSkill.toLowerCase())
                          );
                        }
                        if (!searchRole && !searchSkill) return e;
                      })
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected === index}
                              onChange={() => handleClick(index)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </TableCell>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">
                            {row?.postingTitle}
                          </TableCell>
                          <TableCell align="left">
                            {row?.requiredSkills || "test_skill"}
                          </TableCell>
                          <TableCell align="left">{row?.country}</TableCell>
                          <TableCell align="left">{row?.city}</TableCell>
                          <TableCell align="left">
                            {row?.workExperience}
                          </TableCell>
                          <TableCell align="left">
                            <Stack
                              direction={"row"}
                              sx={{ alignItems: "center" }}
                              spacing={"0"}
                            >
                              {row?.jobDescription === ""
                                ? "N/A"
                                : row?.jobDescription
                                    ?.replace(/<[^>]*>|[\r\n]/g, "")
                                    .slice(0, 15) + "..."}
                              {row?.jobDescription ? (
                                <Tooltip
                                  title={"Click to view full description."}
                                  arrow
                                >
                                  <IconButton
                                    onClick={(event) => {
                                      setAnchorEl(event.currentTarget);
                                      setShowDesc(
                                        parse(row?.jobDescription, options)
                                        // row.jobDescription.replace(
                                        //   /<[^>]*>|[\r\n]/g,
                                        //   ""
                                        // )
                                      );
                                    }}
                                  >
                                    <InfoOutlined sx={{ fontSize: "15px" }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              <Menu
                                //anchorEl={anchorEl}
                                id="account-menu"
                                open={openJobDesc}
                                onClose={() => {
                                  setAnchorEl(null);
                                }}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    width: "400px",
                                    height: "auto",
                                    maxHeight: "500px",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    padding: "25px",
                                    filter:
                                      "drop-shadow(0px 2px 3px rgba(0,0,0,0.32))",
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "center",
                                }}
                                anchorOrigin={{
                                  horizontal: "center",
                                  vertical: "center",
                                }}
                              >
                                <Box sx={{ position: "relative" }}>
                                  <IconButton
                                    size="small"
                                    onClick={() => setAnchorEl(null)}
                                    sx={{
                                      position: "absolute",
                                      top: "-50px",
                                      right: "-40px",
                                      backgroundColor: "primary.light",
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                  <Box
                                    sx={{
                                      width: "400px",
                                      height: "500px",
                                      overflowY: "auto",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Typography>{showDesc}</Typography>
                                  </Box>
                                </Box>
                              </Menu>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            {row?.jobOpeningId}
                          </TableCell>
                          <TableCell align="left">
                            {row?.assignedRecruiter}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <Box>
            <Stack
              direction={"row"}
              justifyContent={"right"}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Button
                sx={{
                  borderRadius: "6px",
                  backgroundColor: "primary.main",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
                color="secondary"
                backgroundColor="primary"
                type="submit"
                onClick={handleSubmit}
              >
                Select
              </Button>

              <Button
                onClick={handleClose}
                sx={{
                  borderRadius: "6px",
                  backgroundColor: "secondary.main",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.dark",
                  },
                }}
                color="secondary"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ATSJobDescripton;
