export const getLocalStorageData = (key) => {
  let localData = window.localStorage.getItem(key);
  return localData?JSON.parse(localData):null
};

export const saveDataToLocalStorage = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
  return { message: "Data saved", data: getLocalStorageData(key) };
};

export const removeDataFromLocalStorage = (key) => {
  let data = getLocalStorageData(key);
 window.localStorage.removeItem(key);
  return { message: "Data removed", data: data };
};

export const clearLocalStorage = () => {
 window.localStorage.clear();
};

export const getAccessToken = () => {
  let userData = JSON.parse(window.localStorage.getItem("userLogin")) || null;
 // console.log("localStorage access Token:", userData?.token);
  if (userData !== null) {
    return userData.token;
  }
  return null
};
export const getRefreshToken = () => {
  let userData = JSON.parse(window.localStorage.getItem("userLogin")) || null;
  //console.log("get access Token: ", userData);
  if (userData !== null) {
    return userData.refreshToken;
  }
  return null
};



export const updateAccessTokenToLocalData = (newToken) =>{
    try{
      let localData = getLocalStorageData("userLogin");
      let newLocalData = {...localData,token:newToken};
      saveDataToLocalStorage("userLogin",newLocalData);
      return true;
    }
    catch(err){
      return err;
    }
}
