import * as types from "./actionTypes";
const initialState = {
  isLoading: false,
  templateName: [],
  templateNameList: [],
  recruiterTemplateName: [],
  isError: false,
};

export const templateListReducer = (state = initialState, { type, templatePayload }) => {
  switch (type) {
    case types.GET_TEMPLATENAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_TEMPLATENAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templateNameList: templatePayload,
        isError: false,
      };
    case types.GET_TEMPLATENAME_FAILURE:
      return {
        ...state,
        isLoading: false,
        templateNameList: [],
        isError: true,
      };
    default:
      return state;
  }
};

export const templateDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TEMPLATENAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_TEMPLATENAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templateName: payload,
        isError: false,
      };
    case types.GET_TEMPLATENAME_FAILURE:
      return {
        ...state,
        isLoading: false,
        templateName: [],
        isError: true,
      };
    default:
      return state;
  }
};

const initialIndexState = {
  isLoading: false,
  templateIndex: [],
  isError: false,
};

export const templateIndexDataReducer = (state = initialIndexState, { type, payload }) => {
  switch (type) {
    case types.GET_TEMPLATEINDEX_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_TEMPLATEINDEX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templateIndex: payload,
        isError: false,
      };
    case types.GET_TEMPLATEINDEX_FAILURE:
      return {
        ...state,
        isLoading: false,
        templateIndex: [],
        isError: true,
      };
    default:
      return state;
  }
};
