//Get TEMPLATE Name Variables types
export const GET_TEMPLATENAME_REQUEST="GET_TEMPLATENAME_REQUEST";
export const GET_TEMPLATENAME_SUCCESS="GET_TEMPLATENAME_SUCCESS";
export const GET_TEMPLATENAME_FAILURE="GET_TEMPLATENAME_FAILURE";

//Get TEMPLATE Index types
export const GET_TEMPLATEINDEX_REQUEST="GET_TEMPLATEINDEX_REQUEST";
export const GET_TEMPLATEINDEX_SUCCESS="GET_TEMPLATEINDEX_SUCCESS";
export const GET_TEMPLATEINDEX_FAILURE="GET_TEMPLATEINDEX_FAILURE";

//Get Child Companies types
export const GET_CHILDCOMPANIES_REQUEST="GET_CHILDCOMPANIES_REQUEST";
export const GET_CHILDCOMPANIES_SUCCESS="GET_CHILDCOMPANIES_SUCCESS";
export const GET_CHILDCOMPANIES_FAILURE="GET_CHILDCOMPANIES_FAILURE";