import React, { useState, useEffect } from "react";
import "./App.css";
import { AppRoutes } from "./Routes/RoutesIndex";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileTopBar from "./Components/ProfileTopBar/ProfileTopBar";
import Sidebar from "./Components/Sidebar/SideBar";
import { Sidebar2 } from "./Components/Sidebar/Sidebar2";
import { Box, Paper, ThemeProvider } from "@mui/material";
import { isValidPath } from "./constants/menuItems";
import LoadingComponent from "./Components/Loading/LoadingComponent";
import { useIsLoading } from "./hooks/useIsLoading";
import { useThemeMode } from "./hooks/useThemeMode";
import {
  createDynamicTheme,
} from "./theme/theme";
import axios from "axios";
import { useFetchTheme } from "./hooks/useFetchTheme";
import { sessionExpired } from "./utils/sessionExpired";
import {userLogoutActionFn} from "./Redux/Auth/action"
import { roleModulePermissions } from "./utils/roleInstance";
import { roleModules } from "./utils/getRoleModules";

function App() {
  const [finalTheme, setFinalTheme] = useState({});
  const [isSessionExpired,setIsSessionExpired] = useState(false);
  const { pathname } = useLocation();
  const { isLoading } = useIsLoading();
  const { themeMode, toggleThemeMode } = useThemeMode();
  const newPath = pathname.split("/")[1] || null;
  const {themeData} = useFetchTheme();
  const dispatch = useDispatch();
  const [roleModule, setRoleModule] = useState("")
  
  
 
  useEffect(() => {
    const isSExpired = sessionExpired();
    if(isSExpired===true){
     dispatch(userLogoutActionFn());
     localStorage.setItem("sessionExpired",JSON.stringify(1));
    }
    //console.log("appp.js session expired-----> ",sessionExpired())
    const newTheme = createDynamicTheme(themeData, themeMode);
    setFinalTheme(newTheme);
  }, [themeData, themeMode]);

  useEffect(() => {
    async function setRoleModuleData(){
      const roles = await roleModules.getModules()
      window.localStorage.setItem('iflowRoleList',JSON.stringify(roles))
    }
    setRoleModuleData()    
  },[pathname])

  return (
    <>
      <ThemeProvider theme={finalTheme}>
        {isLoading && <LoadingComponent />}
        <Paper>
          <div className="App">
            {newPath && isValidPath(pathname) ? (
              // MAIN
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  height: "100%",
                  boxSizing: "border-box",
                  paddingY: "8px",
                  paddingX: "5px",
                  display: "flex",
                  gap: "15px",
                  // border: "1px solid red",
                  //  backgroundColor: "rgb(119,499,999,0.1)",
                }}
              >
                {/* <Stack direction={"row"} gap={4}> */}
                <Sidebar2 activePath={newPath} roleModule={roleModulePermissions} />
                {/* <Sidebar active={newPath} /> */}
                <Box
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    // backgroundColor: "rgb(119,499,999,0.3)",
                    // border: "1px solid teal",
                  }}
                >
                  <ProfileTopBar
                    toggleThemeMode={toggleThemeMode}
                    themeMode={themeMode}
                  />
                  <AppRoutes />
                </Box>
                {/* </Stack> */}
              </Box>
            ) : (
              <>
                <AppRoutes />
              </>
            )}
          </div>
        </Paper>
        {/* <SearchSection/> */}
      </ThemeProvider>
    </>
  );
}

export default App;
