//Get HIRINGCOMPANY types
export const GET_HIRINGCOMPANY_REQUEST="GET_HIRINGCOMPANY_REQUEST";
export const GET_HIRINGCOMPANY_SUCCESS="GET_HIRINGCOMPANY_SUCCESS";
export const GET_HIRINGCOMPANY_FAILURE="GET_HIRINGCOMPANY_FAILURE";

//Get Hiring Company data from Iflow

export const GET_IFLOWHIRINGCOMPANY_REQUEST="GET_IFLOWHIRINGCOMPANY_REQUEST";
export const GET_IFLOWHIRINGCOMPANY_SUCCESS="GET_IFLOWHIRINGCOMPANY_SUCCESS";
export const GET_IFLOWHIRINGCOMPANY_FAILURE="GET_IFLOWHIRINGCOMPANY_FAILURE";

//POST Hiring company in IFLOW
export const POST_IFLOWHIRINGCOMPANY_REQUEST="POST_IFLOWHIRINGCOMPANY_REQUEST";
export const POST_IFLOWHIRINGCOMPANY_SUCCESS="POST_IFLOWHIRINGCOMPANY_SUCCESS";
export const POST_IFLOWHIRINGCOMPANY_FAILURE="POST_IFLOWHIRINGCOMPANY_FAILURE";

export const UPDATE_IFLOWHIRINGCOMPANY_REQUEST="UPDATE_IFLOWHIRINGCOMPANY_REQUEST";
export const UPDATE_IFLOWHIRINGCOMPANY_SUCCESS="UPDATE_IFLOWHIRINGCOMPANY_SUCCESS";
export const UPDATE_IFLOWHIRINGCOMPANY_FAILURE="UPDATE_IFLOWHIRINGCOMPANY_FAILURE";



//GET Iflow hiring company through ID
export const GET_IFLOWHIRINGCOMPANYWITHID_REQUEST="GET_IFLOWHIRINGCOMPANYWITHID_REQUEST";
export const GET_IFLOWHIRINGCOMPANYWITHID_SUCCESS="GET_IFLOWHIRINGCOMPANYWITHID_SUCCESS";
export const GET_IFLOWHIRINGCOMPANYWITHID_FAILURE="GET_IFLOWHIRINGCOMPANYWITHID_FAILURE";


// POST HIRINGCOMPANY types
export const POST_HIRINGCOMPANY_REQUEST="POST_HIRINGCOMPANY_REQUEST";
export const POST_HIRINGCOMPANY_SUCCESS="POST_HIRINGCOMPANY_SUCCESS";
export const POST_HIRINGCOMPANY_FAILURE="POST_HIRINGCOMPANY_FAILURE";

// Edit HIRINGCOMPANY types
export const EDIT_HIRINGCOMPANY_REQUEST="EDIT_HIRINGCOMPANY_REQUEST";
export const EDIT_HIRINGCOMPANY_SUCCESS="EDIT_HIRINGCOMPANY_SUCCESS";
export const EDIT_HIRINGCOMPANY_FAILURE="EDIT_HIRINGCOMPANY_FAILURE";

// Edit HIRINGCOMPANY types
export const EDIT_HIRINGCOMPANYSTATUS_REQUEST="EDIT_HIRINGCOMPANYSTATUS_REQUEST";
export const EDIT_HIRINGCOMPANYSTATUS_SUCCESS="EDIT_HIRINGCOMPANYSTATUS_SUCCESS";
export const EDIT_HIRINGCOMPANYSTATUS_FAILURE="EDIT_HIRINGCOMPANYSTATUS_FAILURE";

//Delete HIRINGCOMPANY types
export const DELETE_HIRINGCOMPANY_REQUEST="DELETE_HIRINGCOMPANY_REQUEST";
export const DELETE_HIRINGCOMPANY_SUCCESS="DELETE_HIRINGCOMPANY_SUCCESS";
export const DELETE_HIRINGCOMPANY_FAILURE="DELETE_HIRINGCOMPANY_FAILURE";



