import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { userLoginActionFn } from "../../Redux/Auth/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { roleModules } from "../../utils/getRoleModules";

const loginInitialData = {
  userid: "",
  password: "",
};

// Reuse the Text Field
const textResponsiveStyle = {
  width: { xs: "80%", sm: "90%", md: "55%", lg: "50%" },
};
//Reuse the text feild container
const textFieldContainerStyle = {
  display: "flex",
  justifyContent: "center",
  mt: "20px",
};
// Reuse the Input feild ICON
const iconStyle = {
  color: "action.active",
  mr: 1,
  my: 2,
  fontSize: "35px",
};

const InputField = ({isSessionExpired,setIsSessionExpired}) => {
  const [signinError, setSigninError] = useState(false);
  const login = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getColorTheme = JSON.parse(localStorage.getItem("colors")) || [];
  var primaryColor;
  var secondaryColor;
  if (getColorTheme[0] !== undefined) {
   // console.log("aaa", getColorTheme[0].primaryColor);
    primaryColor = getColorTheme[0].primaryColor;
    secondaryColor = getColorTheme[0].secondaryColor;
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitialData,
  });
  // signin handler function.
  const onSubmit = (data) => {
    // console.log("login called");
    dispatch(userLoginActionFn(data)).then(async(res) => {
     // console.log("inputField response: ", res);
      let message = res.error
        ? "you are not authorized to login."
        : "Login Success";

      if (res.payload) {
        // navigate("/openings_table");
        const roles = await roleModules.getModules()
        window.localStorage.setItem('iflowRoleList',JSON.stringify(roles))
        setTimeout(() => {
          // window.location.href="/openings_table"
          navigate("/openings_table");
        },500)
      } else {
        setSigninError(true);
        reset(loginInitialData);
        setTimeout(() => {
          setSigninError(false);
        }, 2000);
      }
    });

    // .catch((err) => {
    //   console.log("login ff err", err);
    //   alert("");
    // });
  };

  return (
    // This component for USER login form.
    <Box
      sx={{
        width: "90%",
        margin: "auto",
        height: "400px",
        marginTop: { xs: "120px", md: "10px", lg: "0px" },
        textAlign: "center",
      }}
    >
      {/* For login text */}
      {signinError && (
        <Alert onClose={() => setSigninError(false)} severity="error">
          You are not authorized to login.!
        </Alert>
      )}
      {isSessionExpired && (
        <Alert onClose={() => setIsSessionExpired(false)} severity="info">
          Session expired Please Login again.
        </Alert>
      )}
      <Box sx={{ mt: "25px" }}>
        <Box>
          <h1>LOGIN</h1>
          <p>Existing User? Enter your login details below,</p>
        </Box>
        {/* For Input feilds */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={textFieldContainerStyle}>
            <AccountCircle sx={iconStyle} />
            <TextField
              id="userid"
              label="username"
              name="userid"
              variant="standard"
              sx={textResponsiveStyle}
              //onChange={handleChange}
              {...register("userid", { required: "Username required!" })}
              error={Boolean(errors.userid)}
              helperText={errors.userid?.message}
            />
          </Box>
          {/* {errors.username && (
            <FormHelperText error>{errors.username.message}</FormHelperText>
          )} */}
          <Box sx={textFieldContainerStyle}>
            <PasswordIcon sx={iconStyle} />
            <TextField
              id="password"
              label="password"
              type="password"
              name="password"
              variant="standard"
              sx={textResponsiveStyle}
              //onChange={handleChange}
              {...register("password", {
                required: "Password required!",
                // minLength: { value: 8, message: "Minimum 8 length required" },
              })}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
          </Box>
          {/* Buttons for login */}
          <Button
            sx={{
              width: "25%",
              height:"55px",
              fontWeight:600,
              color: secondaryColor || "#ff9900",
              mt: "50px",
              borderRadius: "16px",
              background: primaryColor || "#041c44",
              // boxShadow: "blue",
              
              
            }}
            type="submit"
          >
            Login
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default InputField;