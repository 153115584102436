import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ModuleCard from "./ModuleCard";
import { IOSSwitch } from "./ModuleCard";
import { flex } from "./ModuleCard";

const switchClick = (e) => {
  console.log(e);
};

const RoleMoule = () => {
  return (
    <Box sx={{ padding: "0px 0px 0px 10px" }}>
      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={3}>
          <ModuleCard
            header="Master Question"
            list="List"
            add="Add"
            edit="Edit"
            delete="Delete"
          />
        </Grid>
        <Grid item xs={3}>
          <ModuleCard
            header="Hiring Company"
            list="List"
            add="Add"
            edit="Edit"
            delete="Delete"
          />
        </Grid>
        <Grid item xs={3}>
          <ModuleCard
            header="Recruitment Company"
            list="List"
            add="Add"
            edit="Edit"
            delete="Delete"
          />
        </Grid>
        <Grid item xs={3}>
          <ModuleCard
            header="Job Role"
            list="List"
            add="Add"
            edit="Edit"
            delete="Delete"
          />
        </Grid>
        <Grid item xs={3}>
          <Card
            sx={{
              maxWidth: 397,
              height: "210px",
              padding: "10px 10px 10px 10px",
              backgroundColor: "rgba(17, 25, 40, 0.19)",
              borderRadius: "12px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px) saturate(200%)",
              WebkitBackdropFilter: "blur(5px) saturate(200%)",
              border: "1px solid rgba(255, 255, 255, 0.125)",
            }}
          >
            <Typography variant="h5" textAlign={"center"}>
              Selected Question
            </Typography>
            <Stack direction={"column"} gap={2} mt="30px">
              <Box style={flex}>
                <Typography>{"List"}</Typography>
                <FormControlLabel
                  control={<IOSSwitch onClick={(e) => switchClick(e)} />}
                />
              </Box>
              <Box style={flex}>
                <Typography>{"Edit"}</Typography>
                <FormControlLabel
                  control={<IOSSwitch onClick={(e) => switchClick(e)} />}
                />
              </Box>
              <Box style={flex}>
                <Typography>{"Delete"}</Typography>
                <FormControlLabel
                  control={<IOSSwitch onClick={(e) => switchClick(e)} />}
                />
              </Box>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            sx={{
              maxWidth: 397,
              height: "210px",
              padding: "10px 10px 10px 10px",
              backgroundColor: "rgba(17, 25, 40, 0.19)",
              borderRadius: "12px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px) saturate(200%)",
              WebkitBackdropFilter: "blur(5px) saturate(200%)",
              border: "1px solid rgba(255, 255, 255, 0.125)",
            }}
          >
            <Typography variant="h5" textAlign={"center"}>
              Select Question
            </Typography>
            <Stack direction={"column"} gap={2} mt="30px">
              <Box style={flex}>
                <Typography>{"List"}</Typography>
                <FormControlLabel
                  control={<IOSSwitch onClick={(e) => switchClick(e)} />}
                />
              </Box>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            sx={{
              maxWidth: 397,
              height: "210px",
              padding: "10px 10px 10px 10px",
              backgroundColor: "rgba(17, 25, 40, 0.19)",
              borderRadius: "12px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px) saturate(200%)",
              WebkitBackdropFilter: "blur(5px) saturate(200%)",
              border: "1px solid rgba(255, 255, 255, 0.125)",
            }}
          >
            <Typography variant="h5" textAlign={"center"}>
              Role Module
            </Typography>
            <Stack direction={"column"} gap={2} mt="30px">
              <Box style={flex}>
                <Typography>{"List"}</Typography>
                <FormControlLabel
                  control={<IOSSwitch onClick={(e) => switchClick(e)} />}
                />
              </Box>
              <Box style={flex}>
                <Typography>{"Edit"}</Typography>
                <FormControlLabel
                  control={<IOSSwitch onClick={(e) => switchClick(e)} />}
                />
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoleMoule;
