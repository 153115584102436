import {
  clearLocalStorage,
  getLocalStorageData,
  removeDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/localStorage";
import * as types from "./actionTypes";
const isUserLoggedIn = getLocalStorageData("userLogin");
const authInitial = {
  isLoading: false,
  isError: false,
  data: isUserLoggedIn ? isUserLoggedIn : null,
};

export const authReducer = (state = authInitial, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: null,
      };
    case types.LOGIN_SUCCESS:
      if(payload!==undefined)
      {
        saveDataToLocalStorage("userLogin", payload);
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: null,
      };
      
    case types.USER_LOGOUT_REQUEST:
      removeDataFromLocalStorage("userLogin");
      clearLocalStorage();
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: null,
      };
    default:
      return state;
  }
};
