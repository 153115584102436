import {
  ChangeCircleOutlined,
  FlipCameraIosRounded,
} from "@mui/icons-material";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { environmentDetails } from "../../environment/environment";
import { editCompanyLogo, userService } from "../../services/userService";
import { CloseRounded } from "@mui/icons-material";
import ApiAlert from "../AlertBox/ApiAlert";
import { useTheme } from "@mui/styles";

const { apiUrl } = environmentDetails;

const ProfileModal = ({ open, handleClose }) => {
  const [selectImg, setSelectImg] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // api alert states
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [alertStatus, setAlertStatus] = useState(false);
  const {palette}= useTheme();

  const handleOnFileChange = (e) => {
    setSelectImg(e.target.files[0]);
  };
  const handleOnClose = () => {
    handleClose();
    setSelectImg(null);
  };

  useEffect(() => {
    let userD = JSON.parse(window.localStorage.getItem("userLogin"));
    if (userD) {
      setUserData(userD.data);
    }
  }, [open]);
 
  const updateUserProfilePhoto = () => {
    let formData = new FormData();
    formData.append("fullname", userData.fullname);
    formData.append("email", userData.email);
    formData.append("userid", userData.userid);
    formData.append("companyLogo", selectImg);
    setIsLoading(true);
    setIsError(false);
    editCompanyLogo(formData)
      .then((res) => {
        // console.log("update company logo Res data", res.data);
        setAlert(true);
        setAlertContent("Update Successfully.");
        setAlertStatus(res?.data?.status);
        userService(res.data.data.companyLogo);
        handleClose();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("errr");
        setIsError(true);
      });
  };

  const handleAlertClose = () => {
    setAlert(false);
  };

  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={handleAlertClose}
          alertonClose={handleAlertClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400, border: "none" }}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "0px solid green",
              gap: "25px",
            }}
          >
            {/* COMPANY LOGO CONTAINER  */}
            <Box
              sx={{
                height: "130px",
                width: "130px",
                border: "1px solid gray",
                borderRadius: "50%",
              }}
            >
              <img
                src={
                  selectImg
                    ? URL.createObjectURL(selectImg)
                    : userData?.companyLogo
                    ? userData?.companyLogo
                    : "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                }
                alt="User"
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Box>
            {/* DETAILS CONTAINER  */}
            <Box
              sx={{
                backgroundColor: "#041C44",
                minWidth: "300px",
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                paddingY: "10px",
                position: "relative",
              }}
            >
              <form>
                <label
                  htmlFor="profilePhoto"
                  style={{
                    position: "absolute",
                    top: "-16px",
                    right: "50px",
                    cursor: "pointer",
                    height: "35px",
                    width: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    // backgroundColor: "#FE9A25",
                    backgroundColor: `${palette?.secondary?.main}`,
                  }}
                >
                  <FlipCameraIosRounded
                    sx={{ 
                      fontSize: "25px", 
                      // color: "#fff"
                      color: "primary.main"
                     }}
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="profilePhoto"
                  style={{ display: "none" }}
                  onChange={handleOnFileChange}
                />
              </form>
              <Typography
                variant="subtitle1"
                sx={{ color: "#C8CDD6", fontSize: "16px", fontWeight: "500" }}
              >
                {userData?.fullname}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#C8CDD6", fontSize: "16px", fontWeight: "500" }}
              >
                {userData?.email}
              </Typography>
              {/* ROLES PARENT CONTAINER  */}
              <Box sx={{ width: "70%" }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                    color: "#C8CDD6",
                    marginBottom: "8px",
                  }}
                >
                  Roles
                </Typography>
                {/* ROLES CHILD CONTAIENR  */}
                <Box
                  sx={{
                    minHeight: "fit-content",
                    maxHeight: "170px",
                    overflowY: "scroll",
                    width: "100%",
                    "&::-webkit-scrollbar": {
                      width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      backgroundColor: "rgba(32, 30, 32, 0.082)",
                      borderRadius: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#FE9A25",
                      borderRadius: "5px",
                    },
                  }}
                >
                  {userData?.roles.map((role, idx) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      sx={{
                        fontSize: "13px",
                        color: "#9397A0",
                      }}
                    >
                      {role}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
            {/* MODAL ACTIONS  */}
            <Stack direction="row" spacing={3} border={"none"}>
              <Button
                disabled={!selectImg ? true : false || isLoading}
                variant="contained"
                onClick={updateUserProfilePhoto}
                sx={{ backgroundColor: "secondary.main",color:"primary.main" }}
              >
                {isLoading ? "Updating" : "Update"}
              </Button>
            </Stack>
          </Box>
          <Button
            onClick={handleOnClose}
            disabled={isLoading}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              // color: "#1E0D61",
               color: "primary.main",
            }}
          >
            <CloseRounded  />
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileModal;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  border: "none",
};
