import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel";
import { getRecruitmentCompanyByEmail } from "../../Redux/RecruitmentCompany/action";
import { useDispatch, useSelector } from "react-redux";

let flag = 0;
const EmailRepeaters = ({
  emailFormValues = [],
  setEmailFormValues,
  emailValidate,
  setEmailValidate,
}) => {
  const dispatch = useDispatch();
  const [addClick, setAddClick] = useState('')
  const [listenerEmail, setListenerEmail] = useState('')
  const [listenerRes, setListenerRes] = useState('')
  const handleChange = (index, field, value) => {
    const updatedFormValues = [...emailFormValues];
    updatedFormValues[index][field] = value;

    const isDuplicate = updatedFormValues.some(
      (emailObj, i) => emailObj.email === value && i !== index
    );

    setListenerEmail(value)

    updatedFormValues[index].isDuplicate = isDuplicate;
    setEmailFormValues(updatedFormValues);
  };

  const checkDuplicateFromCompanies = (value) => {
    if(value){
      dispatch(getRecruitmentCompanyByEmail(value)).then((res)=>{
        if(res != undefined){
          console.log('found')
          setListenerRes(res.payload)
          setEmailFormValues(emailFormValues)
        }
        else{
          console.log('not found')
          setListenerRes(false)
        }
      })
    }
  }

  const addFormFields = (emailData) => {
    setAddClick(true)
    setEmailValidate(false)
    checkDuplicateFromCompanies(emailData.email)
    setEmailFormValues([
      ...emailFormValues,
      {
        email: "",
      },
    ]);    
  };

  useEffect(() => {
    let duplicateEmail = emailFormValues.filter((val) => val.isDuplicate == true)
    if(duplicateEmail.length > 0){
      emailFormValues = emailFormValues.map((val) => val.email != '' ? val : '')
      emailFormValues = emailFormValues.filter((val) => val != '')
      setEmailFormValues(emailFormValues)
      setEmailValidate(true)
    }
  },[emailFormValues])

  useEffect(() => {
    if(addClick == ''){
      const timeOutID = setTimeout(() => {
        checkDuplicateFromCompanies(listenerEmail)        
      },3000)
      return () => clearTimeout(timeOutID)
    }
  },[listenerEmail, addClick])

  useEffect(() => {
    emailFormValues.map((val, key) => {
      if(listenerRes != '' && listenerRes != false){
        if(val.email == listenerRes[0]['email'][0]['email']){
          val.isDuplicate = true
          setEmailValidate(true)
          return val
        }
      }
      else{
        if(val.email == listenerEmail){
          val.isDuplicate = false          
          setEmailValidate(false)
          return val
        }
      }      
    })
  }, [listenerRes])

  const removeFormFields = (i) => {
    //console.log("hello--");
    if(i == 1){
      setAddClick('')
    }
    setEmailValidate(false)
    const updatedFormValues = [...emailFormValues];
    updatedFormValues.splice(i, 1);
    setEmailFormValues(updatedFormValues);
  };

  return (
    <Stack>
      {emailFormValues &&
        emailFormValues.map((element, index) => (
          <Stack direction={"column"} key={index}>
            <Typography
              sx={{
                fontWeight: 600,
                // color: "black",
                color: "primary.darkText",
                fontFamily: "sans-serif",
                // mb: "10px",
                mb: "5px",
                mt: "10px",
              }}
            >
              Email Ids to Listen :
            </Typography>
            <TextField
              fullWidth
              label={"Email"}
              type="email"
              name="email"
              variant="outlined"
              value={element.email}
              onChange={(e) => handleChange(index, "email", e.target.value)}
              sx={{ marginBottom: "10px" }}
              error={emailValidate&&element.isDuplicate}
              helperText={emailValidate&&element.isDuplicate ? "Please fix the duplicate email issue" : ""}
            />
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              {emailFormValues.length === index + 1 && (
                <Button
                  sx={{
                    height: "40px",
                    borderRadius: "6px",
                    // marginTop: "10px",
                    // background: "#1E0D61",
                    // color: "#fff",
                    backgroundColor: "primary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "primary.dark" },
                  }}
                  type="button"
                  // disabled={element.cname===""?true:false}
                  onClick={() => addFormFields(element)}
                >
                  Add more
                </Button>
              )}
              {index ? (
                <Button
                  sx={{
                    height: "40px",
                    width: "20px",
                    borderRadius: "6px",
                    //  marginLeft:"15px",
                    // margin: "5px 15px 0 4px",
                    textTransform: "none",
                    // background: "#FE9A25",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                    "&:hover": {
                      //backgroundColor: "#e9983c"
                      backgroundColor: "secondary.dark",
                    },
                  }}
                  type="button"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </Button>
              ) : null}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default EmailRepeaters;
