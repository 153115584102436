

export const columns = [
    { id: "Sort", label: "", minWidth: 30, align: "center" },
    { id: "SNo", label: "Sl. #", minWidth: 50, align: "center" },
    { id: "Question", label: "Question", minWidth: 420, align: "left" },
    { id: "QuestionType", label: "Question Type", minWidth: 80, align: "center" },
    { id: "Weight", label: "Weightage", minWidth: 70, align: "center" },
    /*{
      id: "JobDescription",
      label: "Job Description",
      minWidth: 150,
      align: "center",
    },*/
    { id: "Ack", label: "Acknowledgement", minWidth: 70, align: "center" },
    { id: "MandatoryRequirement", label: "Mandatory Requirement?", minWidth: 70, align: "center" },
    {
      id: "Default Value",
      label: "Default Value",
      minWidth: 100,
      align: "center",
    },
    /*{ id: "Adaptive", label: "Adaptive", minWidth: 150, align: "center" },
    { id: "YPath", label: "YPath", minWidth: 80, align: "center" },
    { id: "NPath", label: "NPath", minWidth: 80, align: "center" },
    { id: "Helptext", label: "Helptext", minWidth: 250, align: "center" },
    {
      id: "Template Name",
      label: "Template Name",
      minWidth: 150,
      align: "center",
    },*/
    {
      id: "ExpectDocument",
      label: "Expect Document",
      minWidth: 150,
      align: "center",
    },
    {
      id: "Next Question Prefix",
      label: "Next Question Prefix",
      minWidth: 160,
      align: "center",
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 80,
      align: "center",
    },
  ];