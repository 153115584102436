import { commonWords } from "../constants/masterQuestion";
import jsonData from "../constants/masterQuestionCustomMessage.json"
export const isContainsDoubleDollar = (str) => {
  let spacesRemoved = removeSpaces(str);
  //console.log("isContainsDoubleDolla called",spacesRemoved);
  const regex = /\$\$\w/;
  return regex.test(spacesRemoved + "s");
};
function removeSpaces(str) {
  return str.replace(/\s/g, "");
}

export const extractUnCommonWords = (title) => {
  //const words = title.toLowerCase().split(/\W+/);
  const filteredTitle = title.toLowerCase().replace(/[^\w\s]/g, "");
  const words = filteredTitle.toLowerCase().match(/\b\w+\b/g);
  const uncommonWords = words.filter((word) => !commonWords.includes(word));
  return uncommonWords;
};

export const findSimilarQuestionsOld = (
  inputValue,
  masterQuestions,
  questionId
) => {
  // const inputKeywords = inputValue.toLowerCase().split(/\W+/);
  const filteredTitle = inputValue.toLowerCase().replace(/[^\w\s]/g, "");
  const inputKeywords = filteredTitle.toLowerCase().match(/\b\w+\b/g) || [
    "random",
  ];
  let matchedQus = masterQuestions.filter((question) => {
    const matchingKeywords = question?.keywords?.filter((keyword) => {
      //return inputKeywords.includes(keyword)
      const regex = new RegExp(`\\b${inputKeywords.join("|")}\\b`, "gi");
      console.log("dkkkkkkkk ",keyword);
      return regex.test(keyword);
    });
    console.log("matchingKeywords => ", matchingKeywords);
    const matchPercentage =
      (matchingKeywords.length / question.keywords.length) * 100;
    // return matchPercentage >= 50 && matchPercentage <= 75;
    console.log("matchig question with percentage ",matchPercentage)
    return matchPercentage > 0;
    //return matchingKeywords;
  });
  console.log("match question ", matchedQus);
  if (questionId) {
    matchedQus = matchedQus.filter((ques) => ques?.qId !== questionId);
  }
  return matchedQus;
};
export const findSimilarQuestions = (
  inputValue,
  masterQuestions,
  questionId
) => {
  const filteredTitle = inputValue.toLowerCase().replace(/[^\w\s]/g, "");
  const inputKeywords = filteredTitle.toLowerCase().match(/\b\w+\b/g) || [
    "random",
  ];
  
  let matchedQus = masterQuestions.filter((question) => {
    const matchingKeywords = question?.keywords?.filter((keyword) => {
      const regex = new RegExp(inputKeywords.join("|"), "gi");
      return regex.test(keyword);
    });

    const matchPercentage =
      (matchingKeywords.length / inputKeywords.length) * 100;

    return matchPercentage > 0;
  });

  if (questionId) {
    matchedQus = matchedQus.filter((ques) => ques?.qId !== questionId);
  }

  return matchedQus;
};
export const findSameQuestions = (inputValue, masterQuestions) => {
  console.log("find siilar fuc called ");
  const inputKeywords = inputValue.toLowerCase().split(/\W+/);
  let matchedQus = masterQuestions.filter((question) => {
    let qTitle = question.Question.toLowerCase().split(/\W+/);
    if (qTitle.length !== inputValue.length) {
      return [];
    }
    for (let i = 0; i < inputValue.length; i++) {
      if (inputValue[i] !== qTitle[i]) return [];
    }
  });
  return matchedQus;
};

export const findSameQuestion2 = (inputValue, masterQuestions) => {
  if (inputValue === "") {
    return sameQuestions;
  }
  const normalizedInput = inputValue.trim().toLowerCase();
  const sameQuestions = masterQuestions.filter((question) => {
    const normalizedQuestion = question.Question.trim().toLowerCase();
    return normalizedQuestion === normalizedInput;
  });
  return sameQuestions;
};


export const removePlusFromPhoneNumber=(phoneNumber) =>{
  if (phoneNumber.startsWith("+")) {
      return phoneNumber.slice(1);
  } else {
      return phoneNumber;
  }
}

export const  getMasterTooltipMessage =(objectName, key) => {
  if (jsonData[objectName] && jsonData[objectName][key]) {
      return jsonData[objectName][key];
  } else {
      return `Message not found for ${key} in ${objectName}`;
  }
}