import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export const CkEditorComponent = ({
  ckEditorData,
  handleCkEditorChange,
  disabled,
}) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={ckEditorData}
        onChange={(event, editor) => handleCkEditorChange(event, editor)}
        // onReady={(editor) => {
        //   editor.editing.view.change((writer) => {
        //     writer.setStyle(
        //       "height",
        //       "200px",
        //       editor.editing.view.document.getRoot()
        //     );
        //   });
        // }}
        disabled={disabled}
      />
    </>
  );
};
