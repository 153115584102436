import React from "react";
import { Menu, Box, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/styles";

const IButtonModal = ({ type, element, anchorEl, setAnchorEl }) => {
  const theme = useTheme();

  return (
    <>
      <Menu
        id="account-menu"
        open={anchorEl ? true : false}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            width: "400px",
            height: "auto",
            maxHeight: "500px",
            position: "absolute",
            top: "50%",
            left: "50%",
            padding: "25px",
            filter: theme.shadows.filterShadow,
          },
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <IconButton
            size="small"
            onClick={() => setAnchorEl(null)}
            sx={{
              position: "absolute",
              top: "-50px",
              right: "-40px",
              backgroundColor: "primary.light",
            }}
          >
            <Close />
          </IconButton>
          <Box
            sx={{
              width: "400px",
              height: "500px",
              overflowY: "auto",
              borderRadius: "5px",
            }}
          >
            {type == "img" ? (
              <Typography>
                <img width="400" src={element} />
              </Typography>
            ) : (
              <Typography>{element}</Typography>
            )}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default IButtonModal;
