import * as yup from "yup";
import { makeStyles } from "@mui/styles";

export const columns = [
  { id: "slNo", label: "Sl. #", minWidth: 70 },
  { id: "Question", label: "Question", minWidth: 420 },
  { id: "Weight", label: "Weight", minWidth: 100 },
  {
    id: "Ack",
    label: "Ack",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "ProcessVariable",
    label: "Process Variable",
    minWidth: 170,
    align: "right",
  },
  {
    id: "DefaultValue",
    label: "Default Value",
    minWidth: 170,
    align: "right",
  },
  {
    id: "BotInterpreted",
    label: "Bot Interpreted",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "BotName",
    label: "Bot Name",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Path",
    label: "Path",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Adaptive",
    label: "Adaptive",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "yPath",
    label: "yPath",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "nPath",
    label: "nPath",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "NextQuestionPrefix",
    label: "Next Question Prefix",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "HelpText",
    label: "Help Text",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Type",
    label: "Type",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Option1",
    label: "Option1",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Option2",
    label: "Option2",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Option3",
    label: "Option3",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "ProcessingNeeded",
    label: "Processing Needed",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "TemplateName",
    label: "Template Name",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Template Variable",
    label: "Template Variable",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Actions",
    label: "Actions",
    minWidth: 80,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];
export const columns2 = [
  { id: "slNo", label: "Sl. #", minWidth: 30 },
  { id: "Question", label: "Question", minWidth: 420 },
  { id: "questionType", label: "Question Type", minWidth: 150 },
  { id: "Weightage", label: "Weightage", minWidth: "auto" },
  {
    id: "Acknowledgement",
    label: "Acknowledgement",
    minWidth: 170,
  },
  {
    id: "DefaultValue",
    label: "Default Value",
    minWidth: 170,
  },
  {
    id: "NextQuestionPrefix",
    label: "Next Question Prefix",
    minWidth: 170,
  },
  {
    id: "HelpText",
    label: "Help Text",
    minWidth: 170,
  },
  {
    id: "Expect Document",
    label: "Expect Document",
    minWidth: 60,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 70,
    align: "right",
    //format: (value) => value.toFixed(2),
  },
];

export const useStyles = makeStyles((theme) => ({
  editIconButton: {
    "&:hover": {
      color: "rgb(254, 154, 37)", // Set EditIcon hover text color here
    },
  },
  deleteIconButton: {
    "&:hover": {
      color: "red", // Set deleteIconButton hover text color here
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      // borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid gray`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
}));

export const schema = yup.object().shape({
  Question: yup.string().required("Question is Required"),
  defaultValue: yup.string(),
  defaultValue: yup.string().test(
    "doubleDollarSign",
    "This field is required if Question contains '$$'",
    (value, context) => {
      // Access "Question" field value using context variable
      const questionValue = context.parent.Question;
      return !questionValue.includes("$$") || !!value;
    }
  ),
  "Bot Interpreted": yup.string().required("Please select an option"),
  // "Bot Name": yup.string(),
  // "Bot Name": yup.string().when("Bot Interpreted", {
  //   is: "c",
  //   then:()=> yup.string().required("Field is Required"),
  // }),
  "Bot Name": yup.string().when('Bot Interpreted', (botInt, schema) => {
    if(botInt[0]==="y"||botInt[0]==="c"||botInt[0]==="g"){
      return schema.required("Bot Name Required")
    }else{
     return schema
    }
    //return botInt[0]!=="n"||botInt[0]!=="" ? schema.required("Bot Name Required") : schema;
    }),
  Path: yup.string().required("Please select an option"),
  Adaptive: yup.string().required("Please select an option"),
  questionType: yup.string().required("Please select an option"),
  questionExpiry: yup.string().required("Please select an option"),
  Weight: yup.string().required("Weight is Required").default("0.1"),
  expectDocument: yup.string().oneOf(['yes', 'no']),
  documentCategory: yup.string().when("expectDocument", {
    is: "yes",
    then:()=> yup.string().required("Field is Required"),
  }),
  "Scoring Engine Field Name": yup
    .string()
    .test(
      "Scoring Engine Field Name",
      "Must contain only alphabets and underscores",
      (value) => {
        if (value !== "") {
          return /^[a-zA-Z_]+$/.test(value); // Use regular expression directly
        } else {
          return true; // No validation if empty
        }
      }
    ),
  Ack: yup.string(),
  "Process Variable": yup.string(),
  yPath: yup.string(),
  nPath: yup.string(),
  "Next Question Prefix": yup.string(),
  "Help Text": yup.string(),
  "Template Name": yup.string(),
  template_variable: yup.string(),
  //expectDocument: yup.string(),
  rangeStart: yup.string(),
  rangeEnd: yup.string(),
  mandatoryQuestion: yup.string(),
  documentType: yup.string(),
  "Attach Job Description": yup.string(),
  //"Job Description": yup.string(),
});

export const commonWords = [
  "a",
  "an",
  "the",
  "is",
  "of",
  "and",
  "to",
  "in",
  "for",
  "on",
  "with",
  "as",
  "you",
  "me",
  "my",
  "your",
  "it",
  "we",
  "our",
  "they",
  "their",
  "what",
  "who",
  "when",
  "where",
  "why",
  "how",
  "at",
  "by",
  "into",
  "off",
  "out",
  "through",
  "until",
  "above",
  "below",
  "near",
  "over",
  "under",
  "but",
  "or",
  "so",
  "yet",
  "although",
  "because",
  "if",
  "since",
  "than",
  "unless",
  "both",
  "each",
  "few",
  "several",
  "some",
  "all",
  "any",
  "many",
  "most",
  "I",
  "said",
  "have",
  "time",
  "tell",
  "told",
  "day",
  "year",
  "make",
  "go",
  "come",
  "people",
  "world",
  "thing",
  "think",
  "see",
  "life",
  "water",
  "way",
  "number",
  "part",
  "person",
  "man",
  "woman",
  "place",
  "state",
  "country",
  "city",
  "street",
  "mountain",
  "river",
  "lake",
  "house",
  "room",
  "car",
  "night",
  "light",
  "dark",
  "cold",
  "hot",
  "big",
  "small",
  "high",
  "low",
  "right",
  "wrong",
  "good",
  "bad",
  "nice",
  "mean",
  "old",
  "young",
  "new",
  "different",
  "he",
  "she",
  "him",
  "her",
  "his",
  "hers",
  "its",
  "them",
  "be",
  "was",
  "were",
  "are",
  "am",
  "will",
  "would",
  "shall",
  "should",
  "can",
  "could",
  "may",
  "might",
  "must",
  "years",
  "this",
  "that",
  "date",
  "includes",
  "include",
  "contain",
  "contains",
  "from",
  "which",
  "did",
  "do",
  "please",
  "lakhs",
  "crores",
  "crore",
  "month",
  "days",
  "week",
  "e",
  "g",
  "eg",
  "ex",
  "x",
  "considering",
  "myself",
  "yourself",
  "youre",
  "mine",
  "whose",
  "whome",
  "keep",
  "much",
  "more",
  "share",
  "word",
  "words",
  "i",
  "like",
];

