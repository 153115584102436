// import { jwtDecode } from "jwt-decode";
import { userLogoutActionFn } from "../Redux/Auth/action";
import { roleModules } from "./getRoleModules";
import { getLocalStorageData } from "./localStorage";

const roleModulePermissions = {}

roleModulePermissions.checkUserRoles = (menuItem, permission) => {
    const localStorage = getLocalStorageData('userLogin')
    const roleModuleData = getLocalStorageData('iflowRoleList')
    if(roleModuleData != '' && roleModuleData != undefined){
        let rolePermissions = roleModuleData.filter((val) => menuItem.roleModule === val.moduleName)
        if(rolePermissions[0] != undefined){
            rolePermissions = rolePermissions[0]['actions']
            let roleViewPermissions = rolePermissions.filter((val1) => val1.name === permission)
            let roles = roleViewPermissions[0]['visibleForRoles']            
            let localRoleData = localStorage?.data.roles
            if(localRoleData != undefined){
                var menuItemDetails = Object.values(localRoleData).map((obj) => {
                    var preRoleDetails = roles.map((role) => {
                        if(role == obj){
                            return true
                        }
                    })

                    let roleDetails = preRoleDetails.filter((roleVal) => roleVal !== undefined)
                    if(roleDetails != ''){
                        return roleDetails
                    }
                })
                
                menuItemDetails = menuItemDetails.filter((menuVal) => menuVal !== undefined)
                if(menuItemDetails[0] != undefined){
                    if(menuItemDetails[0] != undefined){
                        return menuItemDetails[0][0]
                    }
                }
            }
        }
    }
}

export { roleModulePermissions }