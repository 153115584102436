import { privateAxiosInstance } from "../../utils/axiosInstance";
import { accessTokenHeadersObject } from "../../utils/headers";
import * as types from "./actionType";

export const getAllDocumentCategoriesActionFn = () => (dispatch) => {
  dispatch({ type: types.GET_DOCUMENT_CATEGORIES_REQUEST });
  return privateAxiosInstance
    .get("/questionnaire/get-document-category", {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
       // console.log("D_C Action fn res ",res.data.data);
      return dispatch({
        type: types.GET_DOCUMENT_CATEGORIES_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
        //console.log("D_C action fn error ",err);
      return dispatch({
        type: types.GET_DOCUMENT_CATEGORIES_FAILURE,
        payload: err.message,
      });
    });
};

export const getDocumentSubCategoriesActionFn = (category) => (dispatch) => {
  dispatch({ type: types.GET_DOCUMENT_SUB_CATEGORIES_REQUEST });
  return privateAxiosInstance
    .get("/questionnaire/get-document-sub-category", {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.GET_DOCUMENT_SUB_CATEGORIES_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.GET_DOCUMENT_SUB_CATEGORIES_FAILURE,
        payload: err.message,
      });
    });
};
