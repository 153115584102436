import * as yup from "yup";

export const getMasterCompanyNameByMasterCompany = (
  hiringCompanies,
  masterCompanyId
) => {
  // hiring companies comming from hiring company table;
  // masterCompanyId is comming from recruitmentCompany table;
  let filteredData = hiringCompanies.filter(
    (item) => item.CompanyId === masterCompanyId
  );
  //console.log("filterdData: ", filteredData);
  return filteredData[0]?.name;
};


export const columns = [
  { id: "Sl #", label: "Sl #", minWidth: 30,align:"center" },
  { id: "RecruitmentCompany", label: "Recruitment Company", minWidth: 220,align:"left" },
  { id: "ComapanyWebsite", label: "Company Website", minWidth: 200,align:"left" },
  { id: "CompanyDesc", label: "Company Description", minWidth: 250,align:"left" },
  { id: "HiringCompany", label: "Hiring Company", minWidth: 190,align:"left" },
  { id: "WhatsAppCandidateConnect", label: "WhatsApp Candidate Connect #", minWidth: 230,align:"left" },
  { id: "WhatsAppRecruiterConnect", label: "WhatsApp Recruiter Connect #", minWidth: 230,align:"left" },
  { id: "Action", label: "Action", minWidth: 80,align:"center" },
];

export const initailState = {
  name: "",
  website: "",
  description: "",
  masterCompanyId: "",
  masterCompanyName: "",
  companyForHint: "",
  phone_number_id: "",
  phone_number_internal: "",
  phone_number: "",
  phone_number_id_internal: "",
};


export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Recruitment company name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Recruitment company name must contain only alphabetic characters"
    ),
  website: yup
    .string()
    .required("Website is required")
    .matches(/^(http|https|www)/, "invalid base url format"),
  companyForHint: yup.string().required("Company for hint is required"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must contain only numbers")
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be at least 10 characters"),
  phone_number_id: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must contain only numbers"),
  phone_number_internal: yup
    .string()
    .matches(/^\d+$/, "Phone number internal must contain only numbers"),
  phone_number_id_internal: yup
    .string()
    .matches(/^\d+$/, "Phone number id internal must contain only numbers"),
});


export const validatePeopleToBeNotified =(data) =>{
  if(!data[0].cname||!data[0].cphone||!data[0].email){
   // return "Atleast one people to be notified entry is required"
   return {status:false,message:"Atleast one people to be notified entry is required"}
  }
  let flag=false;

  for(let i = 0;i<data.length;i++){
    if(!data[i].cname||!data[i].cphone||!data[i].email){
      return {status:false,message:null}
    }
  }
  return {status:true,message:null}
}