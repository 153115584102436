import axios from "axios";
import { environmentDetails } from "../environment/environment";
import { privateAxiosInstance } from "../utils/axiosInstance";
import {accessTokenHeadersObject} from "../utils/headers"

export const userService = (imgLink) => {
  //console.log("imgLink userService imgLink: ", imgLink);
  let localData = JSON.parse(window.localStorage.getItem("userLogin"));
  if (localData) {
    let newData = localData.data;
    newData.companyLogo = imgLink;
    let restData = { ...localData, data: newData };
    //console.log("newData ", newData);
    window.localStorage.setItem("userLogin", JSON.stringify(restData));
    return JSON.parse(window.localStorage.getItem("userLogin"));
  } else {
    return "user not found";
  }
};

export const editCompanyLogo = (data) => {
  return privateAxiosInstance.put(
    `/questionnaire/update-user`,
    data,{headers:{...accessTokenHeadersObject()}}
  );
};
