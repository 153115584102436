import * as types from "./actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  iflowChildCompany:[],
  length: 0,
};

export const recruitmentReducer = (
  state = initialState,
  { type, payload,ilfowChildCompanyData, dataLength }
) => {
  switch (type) {
    case types.GET_RECRUITMENT_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
      };
    case types.GET_RECRUITMENT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        length: dataLength,
        data: payload,
      };
    case types.GET_RECRUITMENT_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
      };
    case types.GET_IFLOW_RECRUITMENT_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        iflowChildCompany: [],
      };
    case types.GET_IFLOW_RECRUITMENT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        iflowChildCompany: ilfowChildCompanyData,
      };
    case types.GET_IFLOW_RECRUITMENT_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        iflowChildCompany: [],
      };
    case types.POST_RECRUITMENT_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
      };
    case types.POST_RECRUITMENT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [],
      };
    case types.POST_RECRUITMENT_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
      };
    case types.EDIT_RECRUITMENT_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: true,
        data: [],
      };
    case types.EDIT_RECRUITMENT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [],
      };
    case types.EDIT_RECRUITMENT_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
      };
    case types.DELETE_RECRUITMENT_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
      };
    case types.DELETE_RECRUITMENT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [],
      };
    case types.DELETE_RECRUITMENT_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
      };
    default:
      return state;
  }
};
