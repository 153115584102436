import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Form from "../Components/Add_Edit_QuestionForm/Form";
import { Box } from "@mui/material";

const AddQuestion = () => {
  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {/* <Navbar/> */}
      <Form />
    </Box>
  );
};

export default AddQuestion;
