import { useTheme } from "@mui/styles";

const useColorMode = () => {
    const theme = useTheme();
    const getThemeColor = (lightColor,darkColor)=>{
        return theme?.palette?.mode === "light" ? lightColor : darkColor;
    }
    return {getThemeColor}
  };
  
  export { useColorMode };