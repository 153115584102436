import React, { useEffect, useState } from "react";
import OpenningsTable from "../Components/Table/OpenningsTable";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { TextField, InputAdornment,IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { roleModulePermissions } from "../utils/roleInstance";

const OpeningsTabDashboard = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  // Check Role Permission
  const [openingAction, setOpeningAction] = useState("")

  // const checkOpeningAction = async() => { 
  //   const roleData = {
  //     roleModule : 'Questions'
  //   }
  //   let rolePermission = await roleModulePermissions.checkUserRoles(roleData,'add')
  //   // return rolePermission
  //   setOpeningAction(rolePermission)
  // }

  // checkOpeningAction()
  // Check Role Permission
  useEffect(() => {
    const checkOpeningAction = async () => {
      const roleData = {
        roleModule: "Questions",
      };
      const rolePermission = await roleModulePermissions.checkUserRoles(roleData, "add");
      setOpeningAction(rolePermission);
    };
  
    checkOpeningAction();
  }, []);

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Box style={{ width: "50%", float: "left" }}>
          <TextField
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    {/* This Search Icon we used for Search Input Box */}
                    <SearchIcon style={{ color: "#CAC2C2" }} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                borderRadius: "12px",
                //backgroundColor: "#FFFFFF",
                height: "56px",
                padding: 2,
                width: "60%"
              },
            }}
            variant="outlined"
            type="text"
            placeholder="Search Job Role or Recruitment Company"
            onChange={(e) => setSearch(e.target.value)} //We set the SetSearch Onchange function.
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {openingAction != '' && openingAction != undefined ?
            <Button
              sx={{
                width: "auto",
                height: "55px",
                padding: "10px 10px 10px 10px",
                textAlign: "center",
                borderRadius: "8px",
                textTransform: "none",
                fontSize: "18px",
                fontWeight: "500",
                marginBottom: "15px",
                // backgroundColor: "#fe9a25",
                // color: "white",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
              onClick={() => navigate("/selected_question")}
            >
              <AddIcon fontSize="large" /> Create Campaign
            </Button>
          : 
            <Button
              sx={{
                width: "auto",
                height: "55px",
                padding: "10px 10px 10px 10px",
                textAlign: "center",
                borderRadius: "8px",
                textTransform: "none",
                fontSize: "18px",
                fontWeight: "500",
                marginBottom: "15px",
                // backgroundColor: "#fe9a25",
                // color: "white",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
              disabled={true}
              onClick={() => navigate("/selected_question")}
            >
              <AddIcon fontSize="large" /> Create Campaign
            </Button>
          }
        </Box>
        <OpenningsTable search={search}/>
      </Box>
    </>
  );
};

export default OpeningsTabDashboard;
