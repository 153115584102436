// import { jwtDecode } from "jwt-decode";
import { refreshTokenHeadersObject, accessTokenHeadersObject } from "./headers";
import { environmentDetails } from "../environment/environment";
import axios from "axios";
const apiUrl = environmentDetails.processDefinationUrl;

const roleModules = {}

roleModules.getModules = async () => {
    return new Promise((resolve, reject) => {
        axios
        .get(`${apiUrl}`, {
            headers: { ...refreshTokenHeadersObject() },
        })
        .then((res) => {
            let resData = res?.data?.data.jrmsSettings
            resolve(resData)
        })
        .catch((err) => {
            console.log(err);
            reject(err)
        });
    })    
}

export { roleModules }