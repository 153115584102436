import * as types from "./actionTypes";

const initialState = {
  isLoading: false,
  questions: [],
  length:0,
  isError: false,
};

export const questionReducer = (state = initialState, { type, payload,datalength }) => {
  switch (type) {
    case types.GET_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: payload,
        length:datalength,  
        isError: false,
      };
    case types.GET_QUESTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        questions: [],
        isError: true,
      };
    case types.GET_QUESTIONID_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_QUESTIONID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: payload,
        isError: false,
      };
    case types.GET_QUESTIONID_FAILURE:
      return {
        ...state,
        isLoading: false,
        questions: [],
        isError: true,
      };
    case types.EDIT_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: true,
        questions: [],
      };
    case types.EDIT_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: [],
        isError: false,
      };
    case types.EDIT_QUESTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        questions: [],
        isError: true,
      };
    case types.DELETE_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        questions: [],
        isError: false,
      };
    case types.DELETE_QUESTIONS_SUCCESS:
    //console.log("payloadReducer",payload)
      return {
        ...state,
        isLoading: false,
        questions: [],
        isError: false,
      };
    case types.DELETE_QUESTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        questions: [],
        isError: true,
      };
      default:
        return state
  }
};
