import { jwtDecode } from "jwt-decode";
import { getRefreshToken,getAccessToken } from "./localStorage";

export const sessionExpired = () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    const decodedToken = jwtDecode(refreshToken);
    if (!decodedToken.exp !== undefined) {
      const expiryDate = new Date(decodedToken.exp * 1000);
      const expiryTimeString = expiryDate.toLocaleString();
      //console.log("refreshTokenExpiryTime--> ",expiryTimeString);
      var date = new Date();
      let isSessionTokenExpired = decodedToken.exp < date.getTime() / 1000;
      return isSessionTokenExpired;
    } else {
      return true;
    }
  } 
};
