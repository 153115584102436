//Get Jobrole types
export const GET_JOBROLES_REQUEST="GET_JOBROLES_REQUEST";
export const GET_JOBROLES_SUCCESS="GET_JOBROLES_SUCCESS";
export const GET_JOBROLES_FAILURE="GET_JOBROLES_FAILURE";

//Get Jobrole By Id types
export const GET_JOBROLE_BY_ID_REQUEST="GET_JOBROLE_BY_ID_REQUEST";
export const GET_JOBROLE_BY_ID_SUCCESS="GET_JOBROLE_BY_ID_SUCCESS";
export const GET_JOBROLE_BY_ID_FAILURE="GET_JOBROLE_BY_ID_FAILURE";

// POST Jobrole types
export const POST_JOBROLES_REQUEST="POST_JOBROLES_REQUEST";
export const POST_JOBROLES_SUCCESS="POST_JOBROLES_SUCCESS";
export const POST_JOBROLES_FAILURE="POST_JOBROLES_FAILURE";

// Edit Jobrole types
export const EDIT_JOBROLES_REQUEST="EDIT_JOBROLES_REQUEST";
export const EDIT_JOBROLES_SUCCESS="EDIT_JOBROLES_SUCCESS";
export const EDIT_JOBROLES_FAILURE="EDIT_JOBROLES_FAILURE";

// Edit Jobrole types
export const EDIT_JOBROLE_STATUS_REQUEST="EDIT_JOBROLE_STATUS_REQUEST";
export const EDIT_JOBROLE_STATUS_SUCCESS="EDIT_JOBROLE_STATUS_SUCCESS";
export const EDIT_JOBROLE_STATUS_FAILURE="EDIT_JOBROLE_STATUS_FAILURE";

//Delete Jobrole types
export const DELETE_JOBROLES_REQUEST="DELETE_JOBROLES_REQUEST";
export const DELETE_JOBROLES_SUCCESS="DELETE_JOBROLES_SUCCESS";
export const DELETE_JOBROLES_FAILURE="DELETE_JOBROLES_FAILURE";



