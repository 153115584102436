import { createTheme } from "@mui/material";
import axios from "axios";
import { environmentDetails } from "../environment/environment";
import {
  accessTokenHeadersObject,
  refreshTokenHeadersObject,
} from "../utils/headers";
import { updateAccessTokenToLocalData } from "../utils/localStorage";
import { jwtDecode } from "jwt-decode";
import { privateAxiosInstance } from "../utils/axiosInstance";

const themeApi = environmentDetails.themeApiUrl;
const apiUrl = environmentDetails.apiUrl;

export const defaultThemeData = {
  primaryColor: "#114040",
  primaryColor1: "#cdeeeb",
  secondaryColor: "#09dee1",
  tertiarycolor: "#2058ac",
  quaternaryColor: "#e6e6e6",
  dangerColor: "#d11515",
  reportLogoUrl: null,
  documentTitle: "DDaT",
  name: null,
  workflowOverlayTitle: "Choose Process",
  filterShadow:"drop-shadow(0px 2px 3px rgba(170, 170, 170, 0.22))"
};
const processThemeData = {
  dangerColor: "#736868",
  documentTitle: "DS | iFlow",
  name: null,
  primaryColor: "#3a3b5f",
  primaryColor1: "#cdeeeb",
  quaternaryColor: "#46589b",
  reportLogoUrl: null,
  secondaryColor: "#ec6f94",
  tertiarycolor: "#8a8c8f",
  workflowOverlayTitle: "Choose Process",
};
export const getLocalThemeData = () => {
  let data = window.localStorage.getItem("themeData");
  if (data) {
    data = JSON.parse(data);
    return data;
  } else {
    return defaultThemeData;
  }
};

export const fetchThemeData = (endPoint) => {
  // return iflowAxiosInstance.get(`/${endPoint}`, {
  //   headers: { ...accessTokenHeadersObject() },
  // });
  return privateAxiosInstance.get(`/questionnaire/get-theme-defination-details`, {
    headers: { ...refreshTokenHeadersObject() },
  });
};

export const createDynamicTheme = (themeData, themeMode) => {
  if (!themeData) {
    themeData = { ...getLocalThemeData() };
  }
  // let tempThemeData = {...defaultThemeData};
  // let tempTheme = createTheme({
  //   palette: {
  //     mode:'light',
  //     primary: {
  //       main: tempThemeData.primaryColor,
  //       primaryColor1:tempThemeData.primaryColor1,
  //       background:"#FFFFFF",
  //       darkText:"#1E1E1E",
  //       lightText:"#212121",

  //      },
  //     secondary: { main:tempThemeData.secondaryColor },
  //     tertiary:{main:tempThemeData.tertiarycolor},
  //     quaternary:{main:tempThemeData.quaternaryColor},
  //   },
  //   typography: {
  //     fontFamily: tempThemeData.fontFamily,
  //     fontSize: tempThemeData.fontSize,
  //   },
  // })
  // return tempTheme;
  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: themeData.primaryColor,
        primaryColor1: themeData.primaryColor1,
        background: "#FFFFFF",
        darkText: "#1E1E1E",
        lightText: "#212121",
        disabledBackground:"#dddddd"
      },
      secondary: { main: themeData.secondaryColor },
      tertiary: { main: themeData.tertiarycolor },
      quaternary: { main: themeData.quaternaryColor },
    },
    typography: {
      fontFamily: themeData.fontFamily,
      fontSize: themeData.fontSize,
    },
    shadows:{
      filterShadow:"drop-shadow(0px 2px 3px rgba(170, 170, 170, 0.22))"
    }
  });
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: themeData.primaryColor,
        primaryColor1: themeData.primaryColor1,
        background: "#1E1E1E",
        darkText: "#FFFFFF",
        lightText: "#707070",
        disabledBackground:"#3D3D3D"
      },
      secondary: { main: themeData.secondaryColor },
      tertiary: { main: themeData.tertiarycolor },
      quaternary: { main: themeData.quaternaryColor },
    },
    typography: {
      fontFamily: themeData.fontFamily,
      fontSize: themeData.fontSize,
    },
    shadows:{
      filterShadow:"drop-shadow(0px 2px 3px rgba(0,0,0,0.32))"
    }
  });
  return themeMode === "dark" ? darkTheme : lightTheme;
};

const iflowAxiosInstance = axios.create({
  baseURL: themeApi,
});
  
iflowAxiosInstance.interceptors.request.use(async (req) => {
  if (req.headers?.Authorization === undefined) {
    return req;
  }
  let decodedToken = jwtDecode(req.headers?.Authorization);
  var date = new Date();
  const isTokenExpired = decodedToken.exp < date.getTime() / 1000;
  if (isTokenExpired) {
    try {
      let res = await axios.post(
        `${apiUrl}/questionnaire/refreshToken`,
        {},
        { headers: refreshTokenHeadersObject() }
      );
      // console.log("refres token response token: ",res.data)
      updateAccessTokenToLocalData(res.data?.token);
      req.headers.Authorization = `Bearer ${res.data?.token}`;
      return req;
    } catch (err) {
      return err;
    }
  } else {
    return req;
  }
});

export { iflowAxiosInstance };
