//Get Question types
export const GET_RECRUITMENT_COMPANY_REQUEST =
  "GET_RECRUITMENT_COMPANY_REQUEST";
export const GET_RECRUITMENT_COMPANY_SUCCESS =
  "GET_RECRUITMENT_COMPANY_SUCCESS";
export const GET_RECRUITMENT_COMPANY_FAILURE =
  "GET_RECRUITMENT_COMPANY_FAILURE";

export const GET_IFLOW_RECRUITMENT_COMPANY_REQUEST =
  "GET_IFLOW_RECRUITMENT_COMPANY_REQUEST";
export const GET_IFLOW_RECRUITMENT_COMPANY_SUCCESS =
  "GET_IFLOW_RECRUITMENT_COMPANY_SUCCESS";
export const GET_IFLOW_RECRUITMENT_COMPANY_FAILURE =
  "GET_IFLOW_RECRUITMENT_COMPANY_FAILURE";

// POST Question types
export const POST_RECRUITMENT_COMPANY_REQUEST =
  "POST_RECRUITMENT_COMPANY_REQUEST";
export const POST_IFLOWRECRUITMENT_COMPANY_REQUEST =
  "POST_RECRUITMENT_COMPANY_REQUEST";
export const POST_RECRUITMENT_COMPANY_SUCCESS =
  "POST_RECRUITMENT_COMPANY_SUCCESS";
export const POST_RECRUITMENT_COMPANY_FAILURE =
  "POST_RECRUITMENT_COMPANY_FAILURE";
export const POST_IFLOWRECRUITMENTCOMPANY_SUCCESS =
  "POST_RECRUITMENT_COMPANY_SUCCESS";
export const POST_IFLOWRECRUITMENTCOMPANY_FAILURE =
  "POST_RECRUITMENT_COMPANY_FAILURE";

// Edit Question types
export const EDIT_RECRUITMENT_COMPANY_REQUEST =
  "EDIT_RECRUITMENT_COMPANY_REQUEST";
export const EDIT_RECRUITMENT_COMPANY_SUCCESS =
  "EDIT_RECRUITMENT_COMPANY_SUCCESS";
export const EDIT_RECRUITMENT_COMPANY_FAILURE =
  "EDIT_RECRUITMENT_COMPANY_FAILURE";

//Delete Question types
export const DELETE_RECRUITMENT_COMPANY_REQUEST =
  "DELETE_RECRUITMENT_COMPANY_REQUEST";
export const DELETE_RECRUITMENT_COMPANY_SUCCESS =
  "DELETE_RECRUITMENT_COMPANY_SUCCESS";
export const DELETE_RECRUITMENT_COMPANY_FAILURE =
  "DELETE_RECRUITMENT_COMPANY_FAILURE";
