import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import InputField from "../Components/Login/InputField";
import LeftWindowtext from "../Components/Login/LeftWindowtext";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { environmentDetails } from "../environment/environment";

const style = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  mt: "150px",
};
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLoggedIn = useSelector((state) => state.authReducer.data);
  const [isSessionExpired,setIsSessionExpired] = useState(false);

  const getColorTheme = JSON.parse(localStorage.getItem("colors")) || [];
  var primaryColor;
  var secondaryColor;
  if (getColorTheme[0] !== undefined) {
   // console.log("aaa", getColorTheme[0].primaryColor);
    primaryColor = getColorTheme[0].primaryColor;
    secondaryColor = getColorTheme[0].secondaryColor;
  }

  useEffect(() => {
     const sessionExpired = window.localStorage.getItem("sessionExpired");
     if(sessionExpired){
      setIsSessionExpired(true);
      window.localStorage.removeItem("sessionExpired");
      setTimeout(() => {
        setIsSessionExpired(false);
        window.localStorage.clear()
      }, 2000);
     }
    if (isUserLoggedIn && location.pathname === "/") {
      let prevPath = sessionStorage.getItem("previousPath");
     // console.log("LOgin:PrevePath ", prevPath);
      if (prevPath) {
        navigate(prevPath);
      } else {
        navigate("/openings_table");
      }
      //navigate(-1);
      //return null;
    }
  }, []);
 
  return (
    // This is container of Login page
    <Grid container spacing={0} columns={{ xs: 2, md: 12, sm: 2 }}>
      {/* This is left hand side window "LeftWindowtext" component comming from "./Login/LeftWindowtext" */}
      <Grid
        item
        xs={6}
        sx={{
          width: "100%",
          objectFit: "cover",
          height: "100vh",
          backgroundRepeat: "no-repeat",
          background: primaryColor || "#041c44",
          opacity: "0.7",
          display: { xs: "none", md: "block", lg: "block" },
        }}
      >
        <Box sx={style}>
          {/* This LeftWindowtext component imported from LeftWindowtext from "../Components/Login/LeftWindowtext"; */}
          <LeftWindowtext
            image={
              `${environmentDetails.logoUrl}`
            }
          />
        </Box>
      </Grid>
      {/* This is right hand side window "InputField" component comming from "./Login/InputField" */}
      <Grid item xs={6} sx={{ height: "100vh" }}>
        <Box
          sx={{
            style,
            mt: { xs: "220px", sm: "350px", md: "500px", lg: "170px" },
          }}
        >
          <InputField isSessionExpired={isSessionExpired} setIsSessionExpired={setIsSessionExpired} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
