import * as types from "./actionTypes";

const initailState = {
  isLoading: false,
  questionsPackage: [],
  length:0,
  isError: false,
  openingsData:[]
};

export const questionPackageReducer = (
  state = initailState,
  { type, payload,dataLength}
) => {
  switch (type) {
    case types.GET_QUESTIONPACKAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        questionsPackage: [],
        isError: false,
      };
    case types.GET_QUESTIONPACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionsPackage: payload,
        length:dataLength,
        isError: false,
      };
    case types.GET_QUESTIONPACKAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        questionsPackage: [],
        isError: true,
      };
      case types.EDIT_QUESTIONPACKAGESTATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        questionsPackage: [],
        isError: false,
      };
    case types.EDIT_QUESTIONPACKAGESTATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionsPackage: [],
        length:dataLength,
        isError: false,
      };
    case types.EDIT_QUESTIONPACKAGESTATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        questionsPackage: [],
        isError: true,
      };
    case types.DELETE_QUESTIONPACKAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        questionsPackage: [],
        isError: false,
      };
    case types.DELETE_QUESTIONPACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionsPackage: [],
        isError: false,
      };
    case types.DELETE_QUESTIONPACKAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        questionsPackage: [],
        isError: true,
      };
    case types.GET_OPENING_BY_FILTER_REQUEST:
      return {
        ...state,
        isLoading:true,
        isError:false,
        openingsData:[]
      }
    case types.GET_OPENING_BY_FILTER_SUCCESS:
      return {
        ...state,
        isLoading:false,
        isError:false,
        openingsData:payload,
      }
    case types.GET_OPENING_BY_FILTER_FAILURE:
      return {
        ...state,
        isLoading:false,
        isError:true,
        openingsData:[]
      }
    case types.CREATE_NEW_OPENING_REQUEST:
      return{
        ...state,
        isLoading:true,
        isError:false,
        openingsData:[]
      }
    case types.CREATE_NEW_OPENING_SUCCESS:
      return{
        ...state,
        isLoading:false,
        isError:false,
        openingsData:[payload]
      }
    
    case types.CREATE_NEW_OPENING_FAILURE:
      return{
        ...state,
        isLoading:false,
        isError:true,
        openingsData:[]
      }
    case types.EDIT_OPENING_QUESTION_REQUEST:
      return{
        ...state,
        isLoading:true,
        isError:false,
        openingsData:[]
      }
    case types.EDIT_OPENING_QUESTION_SUCCESS:
      return{
        ...state,
        isLoading:false,
        isError:false,
        openingsData:[payload]
      }
    case types.EDIT_OPENING_QUESTION_FAILURE:
      return{
        ...state,
        isLoading:false,
        isError:true,
        openingsData:[]
      }
    case types.DELETE_OPENING_QUESTION_REQUEST:
      return{
        ...state,
        isLoading:true,
        isError:false,
        openingsData:[]
      }
    case types.DELETE_OPENING_QUESTION_SUCCESS:
      return{
        ...state,
        isLoading:false,
        isError:false,
        openingsData:[payload]
      }
    case types.DELETE_OPENING_QUESTION_FAILURE:
      return{
        ...state,
        isLoading:false,
        isError:true,
        openingsData:[]
      }
    case types.SHARE_JOB_IN_CAREER_REQUEST:
      return{
        ...state,
        isLoading:true,
        isError:false,
      }
    case types.SHARE_JOB_IN_CAREER_SUCCESS:
      return{
        ...state,
        isLoading:false,
        isError:false,
      }
    case types.SHARE_JOB_IN_CAREER_FAILURE:
      return{
        ...state,
        isLoading:false,
        isError:true,
      }
    default:
        return state;
  }
};
