import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore,
} from "redux";
import thunk from "redux-thunk";
import { jobRoleReducer,getJobRoleByIdReducer } from "./JobRole/jobRoleReducer";
import { questionPackageReducer } from "./QuestionPacakge/questionPackageReducer";
import { recruitmentReducer } from "./RecruitmentCompany/recruitmentReducer";
import { questionReducer } from "./Questions/questionReducer";
import { hiringCompanyReducer } from "./HiringCompany/hiringCompanyReducer";
import { authReducer } from "./Auth/authReducer";
import {iflowDataReducer} from "./IFlowData/iflowDataReducer"
import { themeReducer } from "./Theme/themeReducer";
import { countryReducer } from "./Country/countryReducer";
import {documentCategoryReducer} from "./documentCategory/documentCategoryReducer"
import { templateDataReducer, templateIndexDataReducer, templateListReducer } from "./Templates/templateDataReducer";

const rootReducer = combineReducers({
  themeReducer,
  questionReducer,
  questionPackageReducer,
  jobRoleReducer,
  recruitmentReducer,
  hiringCompanyReducer,
  authReducer,
  iflowDataReducer,
  countryReducer,
  templateDataReducer,
  templateIndexDataReducer,
  getJobRoleByIdReducer,
  documentCategoryReducer,
  templateListReducer  
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = legacy_createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
/////////////////