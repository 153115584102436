import { routes } from "../Routes/Routes";

export const menuItems = [
  {
    name: "Digital Sherpa",
    iconName: "menu",
    color: "white",
    path: "",
    muiIcon:"Menu"
  },
  {
    name: "Questions",
    roleModule: "Questions",
    iconName: "pie-chart-alt-2",
    type: "solid",
    color: "white",
    path: "/questions",
    muiIcon:"PieChart"
  },
  {
    name: "Home",
    roleModule: "Openings",
    iconName: "home",
    type: "solid",
    color: "white",
    path: "/openings_table",
    muiIcon:"Home"
  },
  {
    name: "Hiring Company",
    roleModule: "Hiring Company",
    iconName: "business",
    type: "solid",
    color: "white",
    path: "/hiring_company",
    muiIcon:"Business"
  },
  {
    name: "Recruitment Company",
    roleModule: "Recruitment Company",
    iconName: "buildings",
    type: "solid",
    color: "white",
    path: "/recruiter_tab_dashboard",
    muiIcon:"Apartment"
  },
  {
    name: "Job Role",
    roleModule: "Job Role",
    iconName: "briefcase",
    type: "solid",
    color: "white",
    path: "/job_role_table",
    muiIcon:"Work"
  },
  {
    name: "Selected Questions",
    roleModule: "Selected Questions",
    iconName: "select-multiple",
    type: "solid",
    color: "white",
    path: "/selected_question",
    muiIcon:"LibraryAddCheck"
  },
  /*{
    name: "Role Modules",
    iconName: "accessibility",
    type: "solid",
    color: "white",
    path: "/role_module",
    muiIcon:"Accessibility"
  },*/
  {
    name: "LogOut",
    iconName: "log-out-circle",
    color: "red",
    rotate: "180",
    path: "",
    muiIcon:"Logout"
  },
];
export const getActiveIndex = (active) => {
  let idx;
  for (let i = 0; i < menuItems.length; i++) {
    if (menuItems[i].path === `/${active}`) {
      idx = i;
      break;
    }
  }
  return idx;
};
export const isValidPath = (path) => {
  path=path!==null?path.slice(1):path
  //console.log("isValied->path: ",path);
  const locationPathArr = path.split("/");
 // console.log("isValied->pathArr: ",locationPathArr);
  
  for (let i = 0; i < routes.length; i++) {
    const routePathArr = routes[i].path.split("/");
    if (routePathArr[0]!=="" && routePathArr.length === locationPathArr.length) {
     // console.log("matched-> route patharr: ",routePathArr, " locatin path arr: ",locationPathArr)
      if (routePathArr[0]===locationPathArr[0]) {
        return true;
      }
    }
    else{
     // console.warn("unmatched-> route patharr: ",routePathArr, " locatin path arr: ",locationPathArr)
    }
  }
  return false;
};
