import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Modal,
  Stack,
  TextField,
  Typography,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { style, initailState } from "../../Pages/JobTabDashboard";
import { useForm } from "react-hook-form";
import { createFilterOptions } from "@mui/material/Autocomplete";
import FileUpload from "react-material-file-upload";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import img_not_found from "../../assets/img_not_found.jpg";
import {
  getCountryData,
  getCountryName,
  getCityName,
} from "../../Redux/Country/action";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel";
import { CkEditorComponent } from "../ReusableComponents/CkEditor";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import { accessTokenHeadersObject } from "../../utils/headers";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const filter = createFilterOptions();

const locationOption = [
  {
    id: "1",
    location: "Kolkata",
  },
  {
    id: "2",
    location: "Pune",
  },
  {
    id: "3",
    location: "Delhi",
  },
  {
    id: "4",
    location: "Odisha",
  },
  {
    id: "5",
    location: "Bangalore",
  },
  {
    id: "6",
    location: "Hyderabad",
  },
];

const EditJobRoleModal = ({
  open,
  setOpen,
  data,
  jobRoleEdit,
  image,
  setImage,
}) => {
  // console.log("aaaaaaaaaaaaaa",data)
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [isDuplicateSkill, setIsDuplicateSkill] = useState(false);
  const [isEmptySkill, setIsEmptySkill] = useState(false);
  const [isEmptyLocation, setIsEmptyLocation] = useState(false);
  const [isEmptyCountry, setIsEmptyCountry] = useState(false);
  const [isEmptyCity, setIsEmptyCity] = useState(false);
  const [isEmptyRole, setIsEmptyRole] = useState(false);
  const [isEmptyDesc, setIsEmptyDesc] = useState(false);
  const [isEmptyImageFile, setIsEmptyImageFile] = useState(false);
  const [isEmptyCurrency, setIsEmptyCurrency] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const [role, setRole] = useState([]);
  const [desc, setDesc] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [jobOpeningId, setJobOpeningId] = useState("");

  const [updateData, setUpdateData] = useState(null);
  const [generateJobDescriptionError, setGenerateJobDescriptionError] =
    useState(false);
  const [generateJDImgError, setGenerateJDImgError] = useState(false);
  const [jdWords, setJdWords] = useState(100);

  const [validImgtype, setValidImgtype] = useState(-1);
  const [validImgsize, setValidImgsize] = useState(-1);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsEmptyLocation(false);
    setIsEmptyCountry(false);
    setIsEmptyCity(false);
    setIsEmptySkill(false);
    setIsEmptyRole(false);
    setIsEmptyDesc(false);
    setIsEmptyImageFile(false);
    setValues([]);
    setRole("");
    setImage([]);
    setJobOpeningId("");
    setDesc("");
    setCurrValue("");
    setCurrencyId("");
    setValidImgtype(-1);
    setValidImgsize(-1);
    setGenerateJDImgError(false);
  };

  //console.log("edit: open /", open, "DAta: ", data);
  // use form hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initailState,
  });

  ///country names
  const [countries, setCountries] = useState([]);
  // city names
  const [cities, setCities] = useState([]);

  useEffect(() => {
    dispatch(getCountryName()).then((res) => {
      let data = Array.from(res.payload);
      let cc = data?.map((i) => i.country);
      setCountries(cc);
    });
  }, []);

  useEffect(() => {
    if (selectedCountry != "") {
      dispatch(getCityName(selectedCountry)).then((res) => {
        if (res.payload) {
          setCities([...res?.payload?.data]);
        } else {
          setCities([data.cities]);
        }
      });
    }
  }, [selectedCountry]);

  const countryData = useSelector((state) => state.countryReducer.country);

  let countryObject = countryData.find((x) => x.id === currencyId);

  useEffect(() => {
    dispatch(getCountryData());
  }, []);

  useEffect(() => {
    // setCurrencyId(data.currency.id);
    // console.log("my data", data);
    reset(data);
    const values = data.skill;
    setValues(values);
    const locations = data?.location;
    setSelected(locations);
    const country = data.country;
    const cities = data?.cities;
    if (country) {
      setSelectedCity(cities);
      setSelectedCountry({ country: data.country });
    }
    setRole(data.role);
    setJobOpeningId(data?.jobOpeningId);
    console.log("jdddsds", data.desc);
    let jds = JSON.stringify(data?.desc)
      .slice(1, -1)
      // .replace(/<[^>]*>|[\r\n]|\\/g, "");
      .replace(/<[^>]*>|&nbsp;|&amp;|[\r\n]|\\/g, "");
    console.log("jd", jds);
    setDesc(jds);
    setJdWords(data?.words ? data?.words : 100);
    setYearsOfExperience(data.yearsOfExperience ? data.yearsOfExperience : "");
    if (data?.currency != "") {
      setIsEmptyCurrency(false);
      let currencyDetails = data.currency ? data.currency.id : "";
      setCurrencyId(currencyDetails);
    }

    // const awsImage=data
  }, [open]);

  var locationData = [];
  if (data.location != undefined) {
    data.location.forEach((locval, index) => {
      locationData.push({
        id: parseInt(index + 1),
        location: locval,
      });
    });
  }
  // console.log("selectedCountry", selectedCountry);
  // console.log(selectedCity);
  var selectedLocation;
  if (selected !== undefined) {
    selectedLocation = JSON.parse(JSON.stringify(selected));
  }
  // LOCATION ONCHANGE HANDLER
  const locationHandleChange = (event, value, reason) => {
    setSelected(value);
    //setSelected(value.map((val) => val.location));
    if (value?.length === 0) {
      setIsEmptyLocation(true);
    } else {
      setIsEmptyLocation(false);
    }
  };

  //country onchange handler
  const countryhandleChange = (event, value) => {
    if (value == null) {
      setIsEmptyCountry(true);
      setSelectedCountry("");
      setIsEmptyCity(true);
      setSelectedCity([]);
    } else {
      setIsEmptyCountry(false);
      setIsEmptyCity(true);
      setSelectedCity([]);
      if (typeof value === "string") setSelectedCountry({ country: value });
      else if (value && value?.inputValue)
        setSelectedCountry({ country: value.inputValue });
      else setSelectedCountry(value);
    }
  };
  //city onchange handler
  const cityhandleChange = (event, value) => {
    if (value == null) {
      setIsEmptyCity(true);
    } else {
      setSelectedCity(value?.map((val) => val));
      setIsEmptyCity(false);
    }
  };
  //image validation
  const handleFileUploaderOnChange = (data) => {
    if (data?.length === 0) {
      setIsEmptyImageFile(true);
      setImage(data);
    } else {
      const fileName = data[0].name;
      const extension = fileName.split(".").pop();
      const isSupported = ["png", "jpeg", "jpg"].includes(extension);
      const maxsize = 2 * 1024 * 1024;
      const filelimit = data[0].size < maxsize;
      if (isSupported && filelimit) {
        setValidImgtype(1);
        setValidImgsize(1);
        setIsEmptyImageFile(false);
        setImage(data);
      } else {
        if (!isSupported) setValidImgtype(0);
        if (!filelimit) setValidImgsize(0);
      }
    }
    setImage(data);
  };
  // on Submit
  const onSubmit = (editData) => {
    if (selected?.length === 0) {
      setIsEmptyLocation(true);
    }
    if (selectedCountry?.length === 0) {
      setIsEmptyCountry(true);
    }
    if (selectedCity?.length === 0) {
      setIsEmptyCity(true);
    }
    if (role?.length === 0) {
      setIsEmptyRole(true);
    }
    // if (desc.length === 0) {
    //   setIsEmptyDesc(true);
    // }
    if (values?.length === 0) {
      setIsEmptySkill(true);
    }
    if (currencyId === "") {
      setIsEmptyCurrency(true);
    }
    // if (image.length === 0) {
    //   setIsEmptyImageFile(true);
    // }
    //console.log("called edit submit");
    if (
      role?.length === 0 ||
      desc?.length === 0 ||
      values?.length === 0 ||
      //selected.length === 0 ||
      selectedCity?.length === 0 ||
      selectedCountry?.length === 0 ||
      !currencyId
    ) {
      return;
    }
    let imageFile = image[0];
    editData.image = imageFile;
    editData.desc = desc;
    editData.jobOpeningId = jobOpeningId;
    editData.words = jdWords;

    const formData = new FormData();
    let newEditData = {
      ...editData,
      skill: values,
      // location: selected,
      country: selectedCountry.country,
      cities: selectedCity,
      currency: countryObject || data?.currency,
      jobOpeningId: jobOpeningId,
      words: jdWords,
    };

    const jobId = newEditData.jobId
      ? newEditData.jobId
      : imageFile?.lastModified;
    // console.log("meowoww", newEditData);
    // console.log("new edit data", newEditData);
    // return;

    Object.entries(newEditData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    jobRoleEdit(jobId, newEditData);
    // console.log("neweditData", newEditData);
  };

  const handleKeyUp = (e) => {
    let val = e.target.value.trim();
    let newValArr = values.map((i) => i.toLowerCase());
    if (
      !isDuplicateSkill &&
      e.keyCode == 188 &&
      val !== "" &&
      !newValArr.includes(val.toLowerCase())
    ) {
      setValues((oldState) => [...oldState, val]);
      setCurrValue("");
    } else {
      setIsDuplicateSkill(true);
    }
  };
  const handleChange = (e) => {
    let val = e.target.value.trim().toLowerCase();
    const lowerCaseArr = values.map((str) => str.trim().toLowerCase());
    setIsDuplicateSkill(lowerCaseArr.includes(val));
    if (values?.length === 0) {
      setIsEmptySkill(true);
    } else {
      setIsEmptySkill(false);
    }
    setCurrValue(e.target.value.replace(/,/g, ""));
  };
  // remove skills chip function
  const handleDelete = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    if (arr.length === 0) {
      setIsEmptySkill(true);
    } else {
      setIsEmptySkill(false);
    }
    setValues(arr);
  };

  const rolehandler = (event) => {
    let val = event.target.value.trim();
    setRole(val);
    if (val === "") {
      setIsEmptyRole(true);
    } else {
      setIsEmptyRole(false);
    }
  };
  const deschandler = (event) => {
    let val = event.target.value.trim();
    setDesc(val);
    // if (val === "") {
    //   setIsEmptyDesc(true);
    // } else {
    //   setIsEmptyDesc(false);
    // }
  };
  const ckEditorDescriptionChange = (event, editor) => {
    let val = editor?.getData();
    setDesc(val);
    if (!val) {
      setIsEmptyDesc(true);
    } else {
      setIsEmptyDesc(false);
    }
  };

  const currencyOnChange = (event, value) => {
    if (value === null) {
      setCurrencyId("");
      setIsEmptyCurrency(true);
    } else {
      setCurrencyId(value?.id);
      setIsEmptyCurrency(false);
    }
  };

  const handleGenerateJobDescription = () => {
    if (
      role?.length === 0 ||
      values?.length === 0 ||
      !yearsOfExperience ||
      selectedLocation?.length === 0
    ) {
      setGenerateJobDescriptionError(true);
      let timerId = setTimeout(() => {
        setGenerateJobDescriptionError(false);
      }, 4000);
      // if(timerId){
      //   clearTimeout(timerId);
      // }
      return;
    }
    const bodyData = {
      jobRole: role,
      skills: values,
      yearsOfExperience: yearsOfExperience,
      words: jdWords,
      // location: selectedLocation,
      country: selectedCountry.country,
      cities: selectedCity,
      currency: JSON.stringify(countryObject),
    };
    setRole(role);
    privateAxiosInstance
      .post(`/questionnaire/generateJobDescription`, bodyData, {
        headers: { ...accessTokenHeadersObject() },
      })
      .then((res) => {
        setDesc(res.data.content);
        setIsEmptyDesc(false);
        let newData = {
          ...data,
          role: role,
          yearsOfExperience: yearsOfExperience,
          skill: values.join(","),
          // location: selectedLocation,
          country: selectedCountry.country,
          cities: selectedCity,
          currency: JSON.stringify(countryObject),
          desc: res.data.content,
        };
        //reset(newData);
        setUpdateData(newData);
        //setDesc(res.data)
      })
      .catch((err) => {
        console.log("generate jd Err ", err);
      });
  };
  // console.log("uiiiiiiiiiiiii", data);

  function removeQuotes(inputString) {
    const regex = /^"(.*)"$/;
    const match = regex.exec(inputString);
    if (match) {
      const contentWithinQuotes = match[1];
      return contentWithinQuotes;
    }
    return inputString;
  }

  useEffect(() => {
    if (updateData) {
      reset(updateData);
    }
  }, [updateData]);

  const generateJDImg = () => {
    if (desc?.length <= 0) {
      setGenerateJDImgError(true);
      return;
    }
    const data = {
      desc: removeQuotes(desc.replace(/<[^>]*>|[\r\n]|\\/g, "")),
      jobRole: role,
      yearsOfExperience: yearsOfExperience,
      skills: values,
    };
    privateAxiosInstance
      .post(
        "/questionnaire/generate-jdImage",
        { ...data },
        {
          responseType: "arraybuffer", // Important for binary data
          headers: { ...accessTokenHeadersObject() },
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "image/png" });
        const imageFile = new File([blob], `jdImage${Date.now()}.png`, {
          type: "image/png",
        });
        setImage([imageFile]);
        setIsEmptyImageFile(false);
        setValidImgtype(1);
        setValidImgsize(1);
      })
      .catch((err) => {
        console.log("Error:", err);
        setValidImgtype(0);
        setValidImgsize(0);
      });
  };
  // console.log("image       ", image);

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                  // color: "#2B2A2C",
                  color: "primary.darkText",
                }}
              >
                Edit Job Role
              </Typography>
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: "0", right: "0" }}
              >
                <CloseIcon />
              </IconButton>
              <Stack>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Stack direction={"row"} gap={2} mt={"3px"}>
                    <TextField
                      sx={{ width: "100%" }}
                      label={<ReuseableLabel label={"Job Role"} />}
                      variant="outlined"
                      onKeyUp={rolehandler}
                      inputProps={{ maxLength: 30 }}
                      {...register("role")}
                      error={Boolean(isEmptyRole)}
                      helperText={isEmptyRole && "Job Role Required"}
                    />
                    {/* Populated only if jd is pulled from ATS */}
                    <TextField
                      key="jobOpeningId"
                      sx={{ width: "100%" }}
                      label="Job Opening Id"
                      variant="outlined"
                      defaultValue={jobOpeningId}
                      inputProps={{
                        maxLength: 30,
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...register("jobOpeningId", {})}
                    />
                  </Stack>

                  <TextField
                    sx={{ width: "100%" }}
                    label="Years of Experience"
                    variant="outlined"
                    {...register("yearsOfExperience")}
                    onChange={(e) => setYearsOfExperience(e.target.value)}
                  />
                  {/* Skills parent box */}
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      minHeight: "70px",
                      border: "1px solid lightgray",
                      width: "auto",
                      borderRadius: "3px",
                      border: `1px solid ${isEmptySkill ? "red" : "lightgray"}`,
                    }}
                  >
                    {/* Skills Label  */}
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        left: 10,
                        color: `#828282`,
                        fontSize: "12px",
                        marginX: "10px",
                        color: `${isEmptySkill ? "red" : "#828282"}`,
                        backgroundColor: "primary.background",
                      }}
                    >
                      <ReuseableLabel label={"Skills"} />
                    </Typography>
                    {/* CHIP BOX  */}
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginY: "10px",
                      }}
                      className={"container"}
                    >
                      {values?.map((item, index) => (
                        <Chip
                          size="small"
                          onDelete={() => handleDelete(item, index)}
                          label={item}
                          key={index}
                        />
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "5px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        placeholder="Press comma(,) for skill"
                        value={currValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyUp}
                        sx={{
                          width: "50%",
                          paddingLeft: "20px",
                          "& input::placeholder": {
                            fontSize: "11px",
                          },
                        }}
                        error={isEmptySkill}
                      />
                      {isDuplicateSkill && (
                        <span
                          style={{
                            fontSize: "11px",
                            color: "red",
                          }}
                        >
                          {currValue} already exist
                        </span>
                      )}
                      {isEmptySkill && (
                        <span
                          style={{
                            fontSize: "11px",
                            color: "red",
                            fontWeight: "semi-bold",
                          }}
                        >
                          Skill Required
                        </span>
                      )}
                    </Box>
                  </Box>

                  {/* DESCRIPTION  */}
                  {/*
                  <TextField
                    multiline
                    maxRows={8}
                    sx={{ width: "100%" }}
                    label="Job Description"
                    variant="outlined"
                    // Text
                    {...register("desc")}
                    error={Boolean(isEmptyDesc)}
                    helperText={isEmptyDesc && "Job Desc Required"}
                    onKeyUp={deschandler}
                  />*/}
                  {/* //Location// */}
                  {/* <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={locationOption.map((item) => item.location)}
                    disableCloseOnSelect
                    // getOptionLabel={(option) => option.location}
                    // defaultValue={locationData || []}
                    defaultValue={data?.location || []}
                    onChange={locationHandleChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={
                            <CheckBoxIcon fontSize="small" color="blue" />
                          }
                          style={{ marginRight: 8 }}
                          checked={selected}
                          // checked={
                          //   selectedLocation.indexOf(option) > -1 ? true : false
                          // }
                        />
                        {option}
                      </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<ReuseableLabel label={"Location"} />}
                        placeholder="Location"
                        error={Boolean(isEmptyLocation)}
                        helperText={isEmptyLocation && "Location is Required"}
                      />
                    )}
                  /> */}

                  <Stack direction={"row"} gap={2} mt={"3px"}>
                    <Autocomplete
                      id="free-solo-with-text-demo"
                      options={
                        countries ? countries : ["chdfiovhe", "cndf", "cerfre"]
                      }
                      value={selectedCountry}
                      onChange={countryhandleChange}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options?.some(
                          (option) => inputValue === option.country
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered?.push({
                            inputValue,
                            country: `${inputValue}`,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.country;
                      }}
                      renderOption={(props, option, idx) =>
                        option.inputValue ? (
                          <li {...props} key={option.iso3}>
                            {option.country}
                          </li>
                        ) : (
                          <li {...props} key={idx.index}>
                            {option}
                          </li>
                        )
                      }
                      style={{ width: "100%" }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<ReuseableLabel label={"Country"} />}
                          placeholder="Country"
                          error={isEmptyCountry}
                          helperText={isEmptyCountry && `Country is required`}
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      id="free-solo-with-text-demo"
                      options={cities}
                      value={selectedCity}
                      onChange={cityhandleChange}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<ReuseableLabel label={"City"} />}
                          placeholder="City"
                          error={isEmptyCity}
                          helperText={isEmptyCity && `City is required`}
                        />
                      )}
                    />
                  </Stack>

                  {/* /**job description */}
                  <Box>
                    {/* <TextField
                      multiline
                      maxRows={8}
                      sx={{ width: "100%" }}
                      label="Job Description"
                      variant="outlined"
                      {...register("desc", {})}
                      error={isEmptyDesc}
                      helperText={errors.desc?.message}
                      onKeyUp={deschandler}
                    /> */}
                    <Stack
                      direction={"row"}
                      gap={"5px"}
                      sx={{ mt: "10px", float: "right", mb: "-15px" }}
                    >
                      <FormControl sx={{ width: "80px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Words
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={jdWords}
                          defaultValue={jdWords}
                          label="Words"
                          onChange={(e) => setJdWords(e.target.value)}
                          size="small"
                          sx={{ height: "30px" }}
                        >
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={150}>150</MenuItem>
                          <MenuItem value={200}>200</MenuItem>
                          <MenuItem value={250}>250</MenuItem>
                          <MenuItem value={300}>300</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        onClick={handleGenerateJobDescription}
                        size="small"
                        sx={{
                          float: "right",
                          color: "#fff",
                          backgroundColor: "primary.main",
                          textTransform: "none",
                        }}
                      >
                        Generate Job Description
                      </Button>
                      {generateJobDescriptionError && (
                        <span style={{ color: "red" }}>
                          To generate Job description fill Role, Skills, Years
                          of Experience, and Location input.{" "}
                        </span>
                      )}
                    </Stack>
                    <Box sx={{ width: "100%", overflow: "auto", mt: "10px" }}>
                      <FormControl>
                        <FormLabel sx={{ fontSize: "14px" }}>
                          Job Description
                        </FormLabel>
                      </FormControl>
                      <CkEditorComponent
                        ckEditorData={desc}
                        handleCkEditorChange={ckEditorDescriptionChange}
                      />
                      {isEmptyDesc && (
                        <span style={{ color: "red" }}>Job Desc Required</span>
                      )}
                    </Box>

                    {/* <Button
                      onClick={handleGenerateJobDescription}
                      size="small"
                      sx={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "primary.main",
                        mt: "5px",
                        marginTop:"20px",
                        
                      }}
                    >
                      Generate Job Description
                    </Button> */}
                  </Box>

                  {/* file upload and job image */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "auto",
                      
                    }}
                  > 
                  <Box
                    sx={{
                      flex:1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      boxSizing:"border-box",
                      padding:"25px"
                    }}
                  >
                    <FileUpload
                      sx={{ height: "80%", color: "red", flex: "1" }}
                      title="Drag 'n' drop image, or click to select image"
                      value={image}
                      accept={"image/*"}
                      onChange={setImage}
                    />
                  </Box> 
                    <Box
                      style={{
                        //  display: "flex",
                        flex:1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        boxSizing:"border-box",
                        padding:"25px"
                        // marginTop: "43px",
                        // width:"120%",
                        // height:"120%"
                      }}
                    >
                      <img
                        style={{
                          width:"100%",
                          height: "100%",
                          objectFit: "scale",
                          border: "2px solid #fe9a25",
                          borderRadius: "8px",
                          //marginRight: "15%",
                        }}
                        src={
                          image.length !== 0
                            ? URL.createObjectURL(image[0])
                            : data.image
                        }
                      ></img>
                    </Box>
                  </Box> */}

                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "250px",
                        marginBottom: "13px",
                        display: "flex",
                        gap: "10px",
                        // border: "2px solid green",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "fitContent",
                          // border: `${isEmptyImageFile ? "1px solid red" : ""}`,
                        }}
                      >
                        <Button
                          size="small"
                          sx={{
                            float: "right",
                            color: "#fff",
                            backgroundColor: "primary.main",
                            mt: "5px",
                            "&:hover": {
                              backgroundColor: "primary.dark",
                            },
                            textTransform: "none",
                          }}
                          color="secondary"
                          backgroundColor="primary"
                          onClick={generateJDImg}
                        >
                          Generate Image
                        </Button>
                        <Box
                          sx={{
                            // border: isEmptyImageFile ? "1px solid red" : "",
                            border: `${
                              isEmptyImageFile ||
                              validImgsize == 0 ||
                              validImgtype == 0
                                ? "1px solid red"
                                : ""
                            }`,
                            borderRadius: "4px",
                            mt: "40px",
                          }}
                        >
                          <FileUpload
                            sx={{
                              height: "160px",
                              color: "red",
                              // border: `${
                              //   isEmptyImageFile ? "1px solid red" : "1px solid gray"
                              // }`,
                              spacing: "2px",
                            }}
                            // title="Drag 'n' drop image, or click to select image"
                            title={
                              validImgtype == -1 && validImgsize == -1 ? (
                                <span>
                                  "Drag 'n' drop image, or click to select
                                  image.
                                  <br></br>
                                  Image size should not exceed 2MB and must be
                                  in .jpg or .jpeg or .png format."
                                </span>
                              ) : validImgtype === 0 ? (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textDecoration: "blink",
                                  }}
                                >
                                  Please upload image in .jpg, .jpeg, or .png
                                  format.
                                </span>
                              ) : validImgsize == 0 ? (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textDecoration: "blink",
                                  }}
                                >
                                  File size exceeded.
                                </span>
                              ) : image?.length == 0 ? (
                                <span>
                                  "Drag 'n' drop image, or click to select
                                  image.
                                  <br></br>
                                  Image size should not exceed 2MB and must be
                                  in .jpg or .jpeg or .png format."
                                </span>
                              ) : (
                                " "
                              )
                            }
                            value={image}
                            accept={"image/*,.jpg,.jpeg,.png"}
                            onChange={handleFileUploaderOnChange}
                            // onChange={setImage}
                          />
                        </Box>

                        {isEmptyImageFile && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "13px",
                              float: "left",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            Image is required
                          </p>
                        )}
                        {generateJDImgError && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            To generate Image job description is mandatory.
                          </span>
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "205px",
                          borderRadius: "5px",
                          overflow: "hidden",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: "primary.main",
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          // src={URL.createObjectURL(image[0])}
                          src={
                            image?.length !== 0
                              ? URL.createObjectURL(image[0])
                              : data.image
                          }
                          onError={(event) => {
                            event.target.onerror = null;
                            event.target.src = img_not_found;
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* CURRENCY  */}
                  <Autocomplete
                    id="combo-box-demo"
                    options={countryData}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    sx={{
                      width: "100%",
                    }}
                    // defaultValue={
                    //   data.currency?.currency_symbol +
                    //   " " +
                    //   "  :  " +
                    //   data.currency?.currency_name +
                    //   " " +
                    //   "[" +
                    //   data.currency?.name +
                    //   "]"
                    // }
                    defaultValue={data.currency}
                    getOptionLabel={(option) =>
                      option.currency_symbol +
                      " " +
                      ":" +
                      " " +
                      option.currency_name +
                      " " +
                      "[" +
                      option.currency +
                      "]" +
                      " " +
                      "[" +
                      option.name +
                      "]"
                    }
                    onChange={currencyOnChange}
                    renderInput={(params, idx) => {
                      // console.log("Params: ", params);
                      return (
                        <TextField
                          {...params}
                          label={<ReuseableLabel label={"Currency"} />}
                          error={isEmptyCurrency}
                          helperText={isEmptyCurrency && `Currency is required`}
                        />
                      );
                    }}
                  />
                </Box>
                {/* ------------------------------------------------ */}
                {/* <Box></Box> */}
              </Stack>

              {/* <Box sx={{ width: "100%", overflow: "auto", mt: "10px" }}>
                <FormControl>
                  <FormLabel sx={{ fontSize: "14px" }}>
                    Job Description
                  </FormLabel>
                </FormControl>
                <CkEditorComponent
                  ckEditorData={desc}
                  handleCkEditorChange={ckEditorDescriptionChange}
                />
                {isEmptyDesc && (
                  <span style={{ color: "red" }}>Job Desc Required</span>
                )}
              </Box> */}

              <Stack
                direction={"row"}
                justifyContent={"right"}
                spacing={2}
                sx={{ width: "100%", mt: "55px" }}
              >
                <Button
                  sx={{
                    borderRadius: "6px",
                    // background: "#1E0D61",
                    backgroundColor: "primary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      //background: "#220986",
                      backgroundColor: "primary.dark",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  sx={{
                    borderRadius: "6px",
                    // background: "#FE9A25",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      // background: "#e9983c",
                      backgroundColor: "secondary.dark",
                    },
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default EditJobRoleModal;
