import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ReuseableLabel from "../Components/ReusableComponents/ReuseableLabel";
import { removePlusFromPhoneNumber } from "../utils/helperFunctions";

var flag = 0;
const PeopleToNotifyRepeater = ({
  formValues = [],
  setFormValues,
  disable = false,
  newSubmit,
  countryDetails,
  countrySelected,
  // initialState,
  repeaterError,
  setRepeaterError,
}) => {
  const initialState = [
    {
      cname: "",
      cphone: "",
      email: "",
      cphonecode: "",
    },
  ];
  const { register } = useForm({
    defaultValues: initialState,
  });

  // const initialState = {
  //   name: "",
  //   website: "",
  //   description: "",
  //   masterCompanyId: "",
  //   masterCompanyName: "",
  //   companyForHint: "",
  //   phone_number_id: "",
  //   phone_number_internal: "",
  //   phone_number: "",
  //   phone_number_id_internal: "",
  //   linkedinPage:"",
  //   instagramPage:"",
  //   careerPage:"",
  //   xHandle:"",
  //   peopleToBeNotified:notify,
  // };

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   control,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: initialState,
  //   // resolver: yupResolver(schema),
  // });

  //console.log("formValues", formValues[0].cname);
  //console.log("country details ",countryDetails)
  useEffect(() => {
    if (formValues[0].cphonecode === "") {
      //console.log("phonecode empty therefore useeffect runned inside repeater--->")
      let newOjb = formValues[0];
      newOjb.cphonecode = removePlusFromPhoneNumber(countrySelected);
      let newFormValue = [newOjb];
      setFormValues(newFormValue);
    }
  }, []);

  const handleChange = (i, field, value) => {
    const updatedFormValues = [...formValues];
    updatedFormValues[i][field] = value;
    setFormValues(updatedFormValues);
  };

  const addFormFields = () => {
    setRepeaterError(false);
    setFormValues([
      ...formValues,
      {
        cname: "",
        cphone: "",
        email: "",
        cphonecode: removePlusFromPhoneNumber(countrySelected),
      },
    ]);
  };

  const removeFormFields = (i) => {
    const updatedFormValues = [...formValues];
    updatedFormValues.splice(i, 1);
    setFormValues(updatedFormValues);
  };

  let menustr = "";
  return (
    <Stack sx={{ width: "100%" }}>
      {formValues &&
        formValues.map((element, index) => (
          <Stack direction={"column"} key={index}>
            <Typography
              sx={{
                fontWeight: 600,
                // color: "black",
                color: "primary.darkText",
                fontFamily: "sans-serif",
                // mb: "10px",
                mb: "5px",
                mt: "10px",
              }}
            >
              People to be notified :
            </Typography>
            {/* NAME  */}
            <TextField
              fullWidth
              // label="Name"
              label={<ReuseableLabel label={"Name"} />}
              name="cname"
              variant="outlined"
              value={element.cname}
              onChange={(e) => handleChange(index, "cname", e.target.value)}
              sx={{ marginBottom: "10px" }}
              // {...register("cname", {
              //   required: "Company Website is Required",
              // })}
              error={repeaterError && element.cname === ""}
              helperText={
                repeaterError && element.cname === "" ? "Name required" : null
              }
            />
            <Stack direction={"row"} sx={{ width: "100%" }} gap={1}>
              {/* PHONE  */}
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Select
                  id="phone_flag"
                  labelId="phone_flag-label"
                  name="cphonecode"
                  sx={{ width: "53px", height: "fit-content" }}
                  defaultValue={
                    removePlusFromPhoneNumber(countrySelected) ||
                    element.cphonecode
                  }
                  value={element.cphonecode}
                  onChange={(e) =>
                    handleChange(index, "cphonecode", e.target.value)
                  }
                >
                  {countryDetails
                    ? countryDetails.map((res) => {
                        let flagstr = res.iso2;
                        if (flagstr == "UM") {
                          flagstr = "US";
                        }
                        let code =
                          "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                          flagstr +
                          ".svg";
                        let phone_code = "";
                        if (!res.phone_code.includes("+")) {
                          phone_code = "+" + res.phone_code;
                        } else {
                          phone_code = res.phone_code;
                        }

                        if (flag == 1) {
                          menustr = (
                            <MenuItem
                              key={res.iso2}
                              value={res.phone_code}
                              sx={{ borderBottom: "2px solid #ccc" }}
                            >
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        } else {
                          menustr = (
                            <MenuItem value={res.phone_code}>
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        }

                        if (flag == countryDetails.length - 1) {
                          flag = 0;
                        } else {
                          flag++;
                        }
                        return menustr;
                      })
                    : ""}
                </Select>
                <TextField
                  sx={{ width: "80%" }}
                  // label="Phone"
                  label={<ReuseableLabel label="Phone #" />}
                  name="cphone"
                  // type="number"
                  variant="outlined"
                  value={element.cphone}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) =>
                    handleChange(index, "cphone", e.target.value)
                  }
                  // {...register("cphone", {
                  //   required: "Company Website is Required",
                  // })}
                  error={repeaterError && element.cphone === ""}
                  helperText={
                    repeaterError && element.cphone === ""
                      ? "Phone required"
                      : null
                  }
                />
              </Stack>
              {/* EMAIL  */}
              <TextField
                fullWidth
                // label="Email"
                label={<ReuseableLabel label="Email" />}
                name="email"
                type="email"
                inputProps={{
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
                  title: "Enter a valid email address",
                }}
                variant="outlined"
                value={element.email}
                onChange={(e) => handleChange(index, "email", e.target.value)}
                // {...register("email", {
                //   required: "Company Website is Required",
                // })}
                error={repeaterError && element.email === ""}
                helperText={
                  repeaterError && element.email === ""
                    ? "Email required"
                    : null
                }
              />
            </Stack>
            {/* ADD REMOVE ACTION  */}
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              {formValues.length === index + 1 && (
                <Button
                  sx={{
                    height: "40px",
                    borderRadius: "6px",
                    marginTop: "10px",
                    // margin: "10px 15px 10px 4px",
                    // background: "#1E0D61",
                    // color: "#fff",
                    backgroundColor: "primary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "primary.dark" },
                  }}
                  type="button"
                  // disabled={element.cname===""?true:false}
                  onClick={() => addFormFields()}
                >
                  Add more
                </Button>
              )}
              {index ? (
                <Button
                  sx={{
                    height: "40px",
                    width: "20px",
                    borderRadius: "6px",
                    marginTop: "10px",
                    // margin: "5px 15px 0 4px",
                    textTransform: "none",
                    // background: "#FE9A25",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                    "&:hover": {
                      //backgroundColor: "#e9983c"
                      backgroundColor: "secondary.dark",
                    },
                  }}
                  type="button"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </Button>
              ) : null}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default PeopleToNotifyRepeater;
