import { useState, useEffect } from "react";

export const useThemeMode = () => {
  const [themeMode, setThemeMode] = useState(
    JSON.parse(window.localStorage.getItem("themeMode")) || "light"
  );

  useEffect(() => {
    window.localStorage.setItem("themeMode", JSON.stringify(themeMode));
  }, [themeMode]);

  const toggleThemeMode = () => {
    const newMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);
  };

  return { themeMode, toggleThemeMode };
};

