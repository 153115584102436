import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  textarea,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  getTemplateNameList,
  getTemplateIndex,
} from "../Redux/Templates/action";
import { getProcessName } from "../Redux/IFlowData/action";
import { useDispatch, useSelector } from "react-redux";
import { useColorMode } from "../hooks/useColorMode";

const TemplateRepeater = ({
  templateValues = [],
  setTemplateValues,
  page = null,
  templateNameInputSize,
  templateID,
}) => {
  const dispatch = useDispatch();
  const { getThemeColor } = useColorMode();

  const initialState = [
    {
      templateIndex: "",
      templateName: "",
      paramNumber: "",
      params: [],
      verbiage: "",
    },
  ];

  if (templateValues == "") {
    setTemplateValues(initialState);
  }

  const { register } = useForm({
    defaultValues: initialState,
  });

  const handleChange = (i, field, value, index) => {
    // console.log("indexp",index)
    // console.log("paramsValue",value)
    const updatedTemplateValues = [...templateValues];
    // console.log("hello",updatedTemplateValues)
    // console.log(field)
    if (field == "params") {
      updatedTemplateValues[i][field][index] = value;
    } else {
      updatedTemplateValues[i][field] = value;
    }
    setTemplateValues(updatedTemplateValues);
  };

  const addFormFields = () => {
    setTemplateValues([
      ...templateValues,
      {
        templateIndex: "",
        templateName: "",
        paramNumber: "",
        params: [""],
        verbiage: "",
      },
    ]);
  };

  const removeFormFields = (i) => {
    const updatedTemplateValues = [...templateValues];
    updatedTemplateValues.splice(i, 1);
    setTemplateValues(updatedTemplateValues);
  };

  const templateList = useSelector(
    (state) => state.templateListReducer.templateNameList
  );

  function filterDuplicateNames(array) {
    let encounteredNames = {}; // Object to store encountered names

    return array.filter(obj => {
        if (!encounteredNames[obj.name]) {
            encounteredNames[obj.name] = true;
            return true; // Include the object if the name is encountered for the first time
        }
        return false; // Exclude the object if the name is encountered again
    });
}

const filterDuplicateTemplateName= filterDuplicateNames(templateList);
// console.log("filteredArray",filteredArray);

  const templateIndexList = useSelector(
    (state) => state.templateIndexDataReducer.templateIndex
  );

  const processNameData = useSelector(
    (state) => state.iflowDataReducer.processName
  );

  // const alreadyChooseTemplate = templateList.filter((apiObject) => {
  //   return !templateValues.some(
  //     (existingObject) => existingObject.templateName == apiObject?.name
  //   );
  // }); This filter not integrated yet. This is for Duplicacy function

  const alreadyChooseTemplateIndex = templateIndexList.filter((apiObject) => {
    return !templateValues.some(
      (existingObject) => existingObject.templateIndex == apiObject
    );
  });

  useEffect(() => {
    dispatch(getTemplateNameList(templateID));
    dispatch(getTemplateIndex());
    dispatch(getProcessName());
  }, [templateID]);

  var processVariables = [];
  processNameData.map((val) => {
    if (val.processkey == "Initiation_preScreeningProcess") {
      processVariables = val.processDef.variables;
    }
  });

  var finalProcessVariables = [];
  for (var i = 0; i < processVariables.length; i++) {
    finalProcessVariables.push({
      name: processVariables[i],
    });
  }

  processVariables = finalProcessVariables;

  const getTemplateDetails = (tName, index) => {
    if (tName == undefined) {
      // setVerbiage("")
      // setParamNumber("")
      templateValues[index]["paramNumber"] = "";
      templateValues[index]["verbiage"] = "";
    } else {
      filterDuplicateTemplateName.map((val) => {
        if (val?.name == tName) {
          // console.log(templateValues)
          templateValues[index]["verbiage"] = val["components"][0]["text"];
          let templateComponents = val["components"];
          // console.log(templateComponents)
          templateComponents.forEach((val, key) => {
            if (val["type"] == "BODY") {
              if (val["example"] != undefined) {
                // setParamNumber(val['example']['body_text'][0].length)
                templateValues[index]["paramNumber"] =
                  val["example"]["body_text"][0].length;
                templateValues[index]["params"] = [];
              } else {
                templateValues[index]["paramNumber"] = "";
                templateValues[index]["params"] = [];
                // setParamNumber("")
              }
            }
            /*else {
              templateValues[index]["paramNumber"] = "";
              templateValues[index]["params"] = [];
              // setParamNumber("")
            }*/
          });
        }
      });
    }
  };

  return (
    <Stack sx={{ width: "100%" }}>
      {templateValues &&
        templateValues.map((element, index) => (
          <Stack direction={"column"}>
            <Stack direction={"row"} sx={{ width: "100%" }} gap={2}>
              {page != "question" ? (
                <Autocomplete
                  id="combo-box-demo"
                  options={alreadyChooseTemplateIndex}
                  sx={{
                    width: "100%",
                  }}
                  key={index}
                  size={templateNameInputSize}
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : option
                  }
                  defaultValue={element.templateIndex}
                  onChange={(event, value) => {
                    handleChange(index, "templateIndex", value);
                  }}
                  renderInput={(params, idx) => {
                    return (
                      <TextField
                        {...params}
                        label="Template Index"
                        value={index}
                      />
                    );
                  }}
                />
              ) : (
                ""
              )}
              <Autocomplete
                id="combo-box-demo"
                options={filterDuplicateTemplateName}
                sx={{
                  width: "100%",
                }}
                key={index}
                size={templateNameInputSize}
                getOptionLabel={(option) =>
                  option?.name ? option?.name : option
                }
                defaultValue={element.templateName}
                onChange={(event, value) => {
                  getTemplateDetails(value?.name, index);
                  handleChange(index, "templateName", value?.name);
                }}
                renderInput={(params, idx) => {
                  return (
                    <TextField
                      {...params}
                      label="Template Name"
                      value={element.templateName}
                    />
                  );
                }}
              />
            </Stack>
            {page != "question" ? (
              <Box>
                {element.paramNumber ? (
                  <Typography
                    sx={{
                      fontWeight: 600,
                      // color: "black",
                      color: getThemeColor("#000", "#fff"),
                      fontFamily: "sans-serif",
                      mb: "10px",
                      mt:"10px"
                    }}
                  >
                    Template Params :
                  </Typography>
                ) : (
                  ""
                )}
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {Array.from({ length: element.paramNumber }, (_, i) => (
                    <Grid item xs={12} sm={4} md={6} key={i}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={processVariables}
                        key={i}
                        sx={{
                          width: "100%",
                        }}
                        getOptionLabel={(option) =>
                          option?.name ? option?.name : option
                        }
                        defaultValue={element.params[i]}
                        onChange={(event, value) => {
                          // setParams(value?.name ? value.name : "");
                          handleChange(index, "params", value?.name, i);
                        }}
                        renderInput={(params, idx) => {
                          // console.log("Params: ", params);
                          return (
                            <TextField
                              {...params}
                              label={`Params ${i + 1}`}
                              value={element?.name}
                            />
                          );
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              ""
            )}
            {page != "question" ? (
              <Stack
                direction={"column"}
                sx={{ width: "100%", marginTop: "20px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    // color: "black",
                    color: getThemeColor("#000", "#fff"),
                    // color: "primary.darktext",
                    fontFamily: "sans-serif",
                    mb: "10px",
                  }}
                >
                  Template Verbiage :
                </Typography>
                <TextField
                  multiline
                  rows={5}
                  label="Template Verbiage"
                  variant="outlined"
                  key={index}
                  placeholder="Template Verbiage goes here..."
                  value={element.verbiage}
                  onChange={(e) =>
                    handleChange(index, "verbiage", e.target.value)
                  }
                  inputProps={{ readOnly: true }}
                />
              </Stack>
            ) : (
              ""
            )}
            <Stack direction={"row"} alignItems={"center"}>
              {page != "question" ? (
                <Button
                  sx={{
                    height: "40px",
                    borderRadius: "6px",
                    // background: "#1E0D61",
                    // color: "#fff",
                    margin: "10px 15px 10px 4px",
                    backgroundColor: "primary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "primary.dark" },
                  }}
                  type="button"
                  onClick={() => addFormFields()}
                  disabled={alreadyChooseTemplateIndex.length === 0}
                >
                  Add more
                </Button>
              ) : (
                ""
              )}
              {index ? (
                <Button
                  sx={{
                    height: "40px",
                    width: "20px",
                    borderRadius: "6px",
                    // background: "#FE9A25",
                    margin: "5px 15px 0 4px",
                    color: "#fff",
                    textTransform: "none",
                    backgroundColor: "secondary.main",
                    "&:hover": {
                      // background: "#e9983c"
                      backgroundColor: "secondary.dark",
                    },
                  }}
                  type="button"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </Button>
              ) : null}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default TemplateRepeater;
