import * as types from "./actionTypes";

const initailState = {
  isLoading: false,
  hiringCompany: [],
  iflowHiringCompany: [],
  idFlowHiringCompany: [],
  dataIflowHiringCompany: [],
  length: 0,
  isError: false,
};

export const hiringCompanyReducer = (state = initailState, action) => {
  const { payload, datalength, iflowHiringCompanydata, type } = action;
  switch (type) {
    case types.GET_HIRINGCOMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GET_HIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: payload,
        length: datalength,
        isError: false,
      };
    }
    case types.GET_HIRINGCOMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: true,
      };
    }
    case types.GET_IFLOWHIRINGCOMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        iflowHiringCompany: [],
        isError: false,
      };
    }
    case types.GET_IFLOWHIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        iflowHiringCompany: iflowHiringCompanydata,
        isError: false,
      };
    }
    case types.GET_IFLOWHIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        iflowHiringCompany: [],
        isError: true,
      };
    }
    case types.POST_IFLOWHIRINGCOMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.POST_IFLOWHIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataIflowHiringCompany: [],
        isError: false,
      };
    }
    case types.POST_IFLOWHIRINGCOMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        dataIflowHiringCompany: [],
        isError: true,
      };
    }
    case types.GET_IFLOWHIRINGCOMPANYWITHID_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GET_IFLOWHIRINGCOMPANYWITHID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        idFlowHiringCompay: [],
        isError: false,
      };
    }
    case types.GET_IFLOWHIRINGCOMPANYWITHID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        idFlowHiringCompay: [],
        isError: true,
      };
    }
    //////////////////////
    case types.UPDATE_IFLOWHIRINGCOMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.UPDATE_IFLOWHIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        // hiringCompany: [],
        isError: false,
      };
    }
    case types.UPDATE_IFLOWHIRINGCOMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        // hiringCompany: [],
        isError: true,
      };
    }

    //////////////////////
    case types.EDIT_HIRINGCOMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EDIT_HIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: false,
      };
    }
    case types.EDIT_HIRINGCOMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: true,
      };
    }
    case types.EDIT_HIRINGCOMPANYSTATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EDIT_HIRINGCOMPANYSTATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: false,
      };
    }
    case types.EDIT_HIRINGCOMPANYSTATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: true,
      };
    }
    case types.DELETE_HIRINGCOMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.DELETE_HIRINGCOMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: false,
      };
    }
    case types.DELETE_HIRINGCOMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hiringCompany: [],
        isError: true,
      };
    }
    default:
      return state;
  }
};
