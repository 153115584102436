import * as types from "./actionTypes";
import axios from "axios";
import { environmentDetails } from "../../environment/environment";

const signinApiUrl = environmentDetails.signinApiUrl;

export const userLoginActionFn = (credentials) => (dispatch) => {
  dispatch({ type: types.LOGIN_REQUEST });
  return axios
    .post(`${signinApiUrl}`, credentials)
    .then((res) => {
     //console.log("Login res: ", res);
      return dispatch({ type: types.LOGIN_SUCCESS, payload: res.data });
    })
    .catch((err) => {
     // console.log("login Err: ", err);
      return dispatch({ type: types.LOGIN_FAILURE, error: err.response });
    });
};

export const userLogoutActionFn = () => (dispatch) => {
  return dispatch({
    type: types.USER_LOGOUT_REQUEST,
    message: "Logout success",
  });
};
