import React from 'react'
import Box from "@mui/material/Box"

const LeftWindowtext = (props) => {
  return (
    // This is left hand side logo and text 
    <Box sx={{width:"45%",height:"420px",border:"0.50px rgba(255, 255, 255, 0.52) solid", borderRadius:"46px",backdropFilter:"blur(11px)",backgroundImage:"linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0))"}}>
        {/* This code for left hand side text and company image */}
        <Box sx={{padding:"60px"}}>
        {/* Text  */}
        <Box>
        <h1 style={{fontFamily:"Poppins",fontSize:"32px",fontWeight:"700",lineHeight:"46px",color:"white"}}>Job Role Management System By</h1>
        </Box>
        {/* Company image */}
        <Box sx={{mt:"50px"}}>
            <img src={props.image} style={{width:"100%",backgroundRepeat:"no-repeat"}}/>
        </Box>
    </Box>
</Box>
  )
}

export default LeftWindowtext