//Get Question types
export const GET_QUESTIONS_REQUEST="GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_SUCCESS="GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE="GET_QUESTIONS_FAILURE";

export const GET_QUESTIONID_REQUEST="GET_QUESTIONID_REQUEST";
export const GET_QUESTIONID_SUCCESS="GET_QUESTIONID_SUCCESS";
export const GET_QUESTIONID_FAILURE="GET_QUESTIONID_FAILURE";


//Post Question types
export const POST_QUESTIONS_REQUEST="POST_QUESTIONS_REQUEST";
export const POST_QUESTIONS_SUCCESS="POST_QUESTIONS_SUCCESS";
export const POST_QUESTIONS_FAILURE="POST_QUESTIONS_FAILURE";

// Edit Question types
export const EDIT_QUESTION_REQUEST="EDIT_QUESTION_REQUEST";
export const EDIT_QUESTIONS_SUCCESS="EDIT_QUESTIONS_SUCCESS";
export const EDIT_QUESTIONS_FAILURE="EDIT_QUESTIONS_FAILURE";

//Delete Question types
export const DELETE_QUESTION_REQUEST="DELETE_QUESTION_REQUEST";
export const DELETE_QUESTIONS_SUCCESS="DELETE_QUESTIONS_SUCCESS";
export const DELETE_QUESTIONS_FAILURE="DELETE_QUESTIONS_FAILURE";



