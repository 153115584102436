import { Tooltip } from "@mui/material";
import React from "react";
import { infoImg } from "../../assets";
import { InfoOutlined } from "@mui/icons-material";

const InfoBox = (props) => {
  const { message, sx={} } = props;
  const style = {
    height: "20px",
    width: "20px",
    borderRadius: "50px",
    marginLeft: "-25px",
  };
  let length = message?message.length:0;
  return (
    <Tooltip 
    title={message} 
    placement="left" 
    arrow
    componentsProps={{
      tooltip: {
          sx: {
              width: `12rem`,
              fontSize:"12px"
          },
      },
  }}
    >
      <img
        style={{
          ...style,
          ...sx,
        }}
        src={infoImg}
        alt={<InfoOutlined />}
      />
    </Tooltip>
  );
};

export default InfoBox;
