const Dashboard = () => {
    return (
        <>
        <div>
            <h3>Welcome to JRMS Dashboard!</h3>
        </div>
        </>
    )
}

export default Dashboard;