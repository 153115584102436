import {
  Autocomplete,
  Box,
  Checkbox,
  Stack,
  Tab,
  Tabs,
  Typography,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PropTypes from "prop-types";
import React from "react";
import RoleMoule from "../Components/RoleModule/RoleMoule";
import { getLocalStorageData } from "../utils/localStorage";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const RoleModuleDashBoard = () => {
  const [value, setValue] = React.useState(0);
  console.log("value", value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const role = getLocalStorageData("userLogin");
  const roles = role.data.roles;
  console.log("role", roles[value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction={"row"}
        mb={"30px"}
        padding={"0px 10px 0px 10px"}
      >
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={roles}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={handleChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={<CheckBoxIcon fontSize="small" color="blue" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          style={{ width: "30%" }}
          renderInput={(params) => (
            <TextField {...params} label="Roles" placeholder="Roles" />
          )}
        />
      </Stack>
      <RoleMoule />
    </Box>
  );
};

export default RoleModuleDashBoard;
