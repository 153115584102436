import React, { useRef } from "react";

//DON'T ACCEPT ARGUEMENTS
export const useThrottle1 = () => {
  const throttleSeed = useRef(null);

  const throttleFunction = useRef((func, delay=500) => {
    if (!throttleSeed.current) {
      // Call the callback immediately for the first time
      func();
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null;
      }, delay);
    }
  });

  return throttleFunction.current;
};

// ACCEPTING ARGUEMENTS
export const useThrottl2e = () => {
  const throttleSeed = useRef(null);
  const throttleFunction = useRef((func, delay = 500) => (...args) => {
    if (!throttleSeed.current) {
      // Call the callback immediately for the first time
      func(...args); // Pass arguments to the original function
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null;
      }, delay);
    }
  });
  return throttleFunction.current;
};

// CURRENTLY IN USE
export const useThrottle = () => {
  const throttleSeed = useRef(null);
  const debounceSeed = useRef(null);
  const throttleFunction = useRef((func, delay = 500) => (...args) => {
    if (!throttleSeed.current) {
      // Throttling logic
      func(...args);
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null;
      }, delay);
    }

    if (debounceSeed.current) {
      clearTimeout(debounceSeed.current);
    }
    debounceSeed.current = setTimeout(() => {
      func(...args); // Debouncing call
      debounceSeed.current = null;
    }, delay);
  });
  return throttleFunction.current;
};
