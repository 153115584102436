import * as types from "./actionTypes";

const initialState = {
  isLoading: false,
  jobRoles: [],
  isError: false,
};


export const jobRoleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_JOBROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_JOBROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobRoles: payload,
        isError: false,
      };
    case types.GET_JOBROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobRoles: [],
        isError: true,
      };
    case types.EDIT_JOBROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: true,
        jobRoles: [],
      };
    case types.EDIT_JOBROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobRoles: [],
        isError: false,
      };
    case types.EDIT_JOBROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobRoles: [],
        isError: true,
      };
    case types.EDIT_JOBROLE_STATUS_REQUEST:{
        return{
          ...state,
          isLoading:true,
          isError:false
        }
    }
    case types.EDIT_JOBROLE_STATUS_SUCCESS:{
      return{
        ...state,
        isLoading:false,
        jobRoles:[],
        isError:true
      }
    }
    case types.EDIT_JOBROLE_STATUS_FAILURE:{
      return{
        ...state,
        isLoading:false,
        jobRoles:[],
        isError:true
      }
    }
    case types.DELETE_JOBROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        jobRoles: [],
        isError: false,
      };
    case types.DELETE_JOBROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobRoles: [],
        isError: false,
      };
    case types.DELETE_JOBROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobRoles: [],
        isError: true,
      };
    default:
      return state;
  }
};


const jobRoldByIdInitialState={
  isLoading: false,
  jobRole: [],
  isError: false,
}

export const getJobRoleByIdReducer = (state=jobRoldByIdInitialState,action) =>{
  const {type,payload} = action;
  switch(type){
    case types.GET_JOBROLE_BY_ID_REQUEST:
      return{
        ...state,
        isLoading:true,
        jobRole:[],
        isError:false
      }
    case types.GET_JOBROLE_BY_ID_SUCCESS:
      return{
        ...state,
        isLoading:false,
        jobRole:payload,
        isError:false
      }
    case types.GET_JOBROLE_BY_ID_FAILURE:
      return{
        ...state,
        isLoading:false,
        jobRole:[],
        isError:true,
        error:payload
      }
    default:
      return state
  }
}