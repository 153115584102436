import axios from "axios";
import { useState } from "react";
import { privateAxiosInstance } from "../utils/axiosInstance";

export const useIsLoading = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoad,setFirstLoad] = useState(true);
    axios.interceptors.request.use((req)=>{
        if(!isLoading){
          setIsLoading(true);
        }
       // console.log("appjs axios intercepter called-------------------->",req)
        return req;
      })
      axios.interceptors.response.use(res=>{
        //console.log("axios intercepter response ",res)
        if(firstLoad){
          setIsLoading(false);
          setFirstLoad(false)
        }
        if(isLoading){
          setIsLoading(false)
        }
        return res;
      },err=>{
       // console.log("axios intercepter error: ",err)
        if(firstLoad){
          setIsLoading(false);
          setFirstLoad(false)
        }
        if(isLoading){
          setIsLoading(false)
        }
        return err;
      })
      privateAxiosInstance.interceptors.request.use((req)=>{
        if(!isLoading){
          setIsLoading(true);
        }
     //  console.log("private interceper called from----->",req);
        return req;
      })
      privateAxiosInstance.interceptors.response.use((res)=>{
       // console.log("private intercepter response: ",res);
        if(firstLoad){
          setIsLoading(false);
          setFirstLoad(false)
        }
        if(isLoading){
          setIsLoading(false)
        }
        return res;
      },err=>{
       // console.log("private intercepter err: ",err);
        if(firstLoad||isLoading){
          setIsLoading(false);
          setFirstLoad(false)
        }
        if(isLoading){
          setIsLoading(false)
        }
        return err
      })
   // console.log("isLoading from hook: ---->",isLoading)
    return {isLoading}
}