import React from "react";
import {
  MenuItem,
  Slider,
  Stack,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "blue",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "blue",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const GeneralSection = () => {
  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          label="ack"
          rows={1}
          type="text"
          placeholder="ack"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          placeholder="Default Value"
          label="Default Value"
          defaultValue=""
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <Box
          style={{
            marginTop: 10,
            minWidth: 150,
            border: "1px solid #908E8E",
            borderRadius: "7px",
            height: "43px",
          }}
        >
          <Stack direction={"row"} spacing={2} padding={1.2}>
            <Typography
              style={{ paddingTop: "1px", paddingLeft: "5px", opacity: "0.6" }}
            >
              Weight
            </Typography>
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              sx={{ color: "blue", arialabelbgColor: "blue" }}
              min={0.1}
              step={0.1}
              max={1}
              style={{ width: "80%", marginLeft: "8%" }}
            />
          </Stack>
        </Box>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          placeholder="Process Variable"
          label="Process Variable"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          placeholder="Next Question Prefix"
          label="Next Question Prefix"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          placeholder="Help Text"
          label="Help Text"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          defaultValue={"plain"}
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          type="text"
          placeholder="Option1"
          label="Option1"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          placeholder="Option2"
          label="Option2"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={1}
          type="text"
          placeholder="Option3"
          label="Option3"
          inputProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          size="small"
        />
        <Stack direction="column" spacing={1}>
          <FormControl
            variant="outlined"
            style={{ marginTop: 1, minWidth: 150 }}
            size="small"
          >
            {/* Bot Interpreted Select Input */}
            <InputLabel id="demo-simple-select-required-label">
              Processing Needed*
            </InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              label="Processing Needed"
              //   style={style}
              defaultValue=""
            >
              <MenuItem value={"y"}>Yes</MenuItem>
              <MenuItem value={"n"}>No</MenuItem>
            </Select>
            {/*  */}
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GeneralSection;
