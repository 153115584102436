import * as types from "./actionTypes";
import { getLocalStorageData,saveDataToLocalStorage } from "../../utils/localStorage";

const getThemeData=getLocalStorageData("themeColors");

const initialThemeData = {
  isLoading: false,
  themeData: getThemeData,
  isError: false,
};

export const themeReducer = (state = initialThemeData, { type, payload }) => {
  switch (type) {
    case types.GET_THEME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_THEME_SUCCESS:
      saveDataToLocalStorage("themeColors",payload)
      return {
        ...state,
        isLoading: false,
        themeData: payload,
        isError: false,
      };
    case types.GET_THEME_FAILURE:
      return {
        ...state,
        isLoading: false,
        themeData: [],
        isError: true,
      };
    default:
      return state;
  }
};
