import { dsShortLogo, indeedLogo, linkedInLogo, naukriLogo } from "../assets"
export const columns = [
    { id: "SNo", label: "Sl. #", minWidth: 30, align: "center" },
    { id: "RecruitmentCompany", label: "Recruitment Company", minWidth: 160, align: "left" },
    { id: "JobRole", label: "Job Role", minWidth: 140, align: "left" },
    { id: "Image", label: "Job Description(Image)", minWidth: 170, align: "center" },
    { id: "SelectedQuestions", label: "Selected Questions", minWidth: 180, align: "left" },
    { id: "Action", label: "Action", minWidth: 80, align: "center" },
  ];

  export const jobSharingMenu = [
    {
      title:"Career Portal",
      logo:dsShortLogo,
      disabled:false
    },
    {
      title:"LinkedIn",
      logo:linkedInLogo,
      disabled:true
    },
    {
      title:"Naukri.com",
      logo:naukriLogo,
      disabled:true
    },
    {
      title:"Indeed",
      logo:indeedLogo,
      disabled:true
    },
  ]