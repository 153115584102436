export const GET_QUESTIONPACKAGE_REQUEST="GET_QUESTIONPACKAGE_REQUEST";
export const GET_QUESTIONPACKAGE_SUCCESS="GET_QUESTIONPACKAGE_SUCCESS";
export const GET_QUESTIONPACKAGE_FAILURE="GET_QUESTIONPACKAGE_FAILURE";

export const DELETE_QUESTIONPACKAGE_REQUEST="DELETE_QUESTIONPACKAGE_REQUEST";
export const DELETE_QUESTIONPACKAGE_SUCCESS="DELETE_QUESTIONPACKAGE_SUCCESS";
export const DELETE_QUESTIONPACKAGE_FAILURE="DELETE_QUESTIONPACKAGE_FAILURE";

export const EDIT_QUESTIONPACKAGESTATUS_REQUEST="EDIT_QUESTIONPACKAGESTATUS_REQUEST";
export const EDIT_QUESTIONPACKAGESTATUS_SUCCESS="EDIT_QUESTIONPACKAGESTATUS_SUCCESS";
export const EDIT_QUESTIONPACKAGESTATUS_FAILURE="EDIT_QUESTIONPACKAGESTATUS_FAILURE";

export const GET_OPENING_BY_FILTER_REQUEST="GET_OPENING_BY_FILTER_REQUEST";
export const GET_OPENING_BY_FILTER_SUCCESS="GET_OPENING_BY_FILTER_SUCCESS";
export const GET_OPENING_BY_FILTER_FAILURE="GET_OPENING_BY_FILTER_FAILURE";

export const CREATE_NEW_OPENING_REQUEST="CREATE_NEW_OPENING_REQUEST";
export const CREATE_NEW_OPENING_SUCCESS="CREATE_NEW_OPENING_SUCCESS";
export const CREATE_NEW_OPENING_FAILURE="CREATE_NEW_OPENING_FAILURE";

export const EDIT_OPENING_QUESTION_REQUEST="EDIT_OPENING_QUESTION_REQUEST";
export const EDIT_OPENING_QUESTION_SUCCESS="EDIT_OPENING_QUESTION_SUCCESS";
export const EDIT_OPENING_QUESTION_FAILURE="EDIT_OPENING_QUESTION_FAILURE";

export const DELETE_OPENING_QUESTION_REQUEST="DELETE_OPENING_QUESTION_REQUEST";
export const DELETE_OPENING_QUESTION_SUCCESS="DELETE_OPENING_QUESTION_SUCCESS";
export const DELETE_OPENING_QUESTION_FAILURE="DELETE_OPENING_QUESTION_FAILURE";

export const SHARE_JOB_IN_CAREER_REQUEST="SHARE_JOB_IN_CAREER_REQUEST";
export const SHARE_JOB_IN_CAREER_SUCCESS="SHARE_JOB_IN_CAREER_SUCCESS";
export const SHARE_JOB_IN_CAREER_FAILURE="SHARE_JOB_IN_CAREER_FAILURE";