import { useEffect, useState } from "react";
import { fetchThemeData, getLocalThemeData } from "../theme/theme";
import { environmentDetails } from "../environment/environment";
import { useSelector } from "react-redux";
import axios from "axios";
import { refreshTokenHeadersObject } from "../utils/headers";
import { privateAxiosInstance } from "../utils/axiosInstance";
const dsIflowApi = environmentDetails.themeApiUrl;

export const useFetchTheme = () => {
  const [themeData, setThemeData] = useState(null);
  const userData = useSelector((state) => state.authReducer);
  const getThemeData = () => {
    return privateAxiosInstance
      .get(`/questionnaire/get-theme-defination-details`, {
        headers: { ...refreshTokenHeadersObject() },
      })
      .then((res) => {
        let data = res.data.data.themes[0];
        window.localStorage.setItem("themeData", JSON.stringify(data));
        setThemeData(data);
      })
      .catch((err) => {
        //fetchThemeData("bpmnapi/metadata/variables/project/themes");
        privateAxiosInstance
          .get(`/questionnaire/get-theme-details`, {
            headers: {
              ...refreshTokenHeadersObject(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json;charset=utf-8",
            },
          })
          .then((res) => {
            let data = res.data.data.themes[0];
            window.localStorage.setItem("themeData", JSON.stringify(data));
            setThemeData(data);
          })
          .catch((err) => {
            setThemeData(getLocalThemeData());
          });
      });
  };

  useEffect(() => {
    getThemeData();
  }, [userData.data]);
  return { themeData };
};
