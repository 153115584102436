//Get HIRINGCOMPANY types
export const GET_COUNTRY_REQUEST="GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS="GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAILURE="GET_COUNTRY_FAILURE";

export const GET_COUNTRY_NAME_REQUEST="GET_COUNTRY_NAME_REQUEST";
export const GET_COUNTRY_NAME_SUCCESS="GET_COUNTRY_NAME_SUCCESS";
export const GET_COUNTRY_NAME_FAILURE="GET_COUNTRY_NAME_FAILURE";

export const GET_CITY_NAME_REQUEST="GET_CITY_NAME_REQUEST";
export const GET_CITY_NAME_SUCCESS="GET_CITY_NAME_SUCCESS";
export const GET_CITY_NAME_FAILURE="GET_CITY_NAME_FAILURE";



