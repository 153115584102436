import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useThemeApi } from "../../environment/useThemeApi";
import { Box, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
// import { useDispatch, useSelector } from "react-redux";
// import { getThemeData } from "../../Redux/Theme/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteAlert = (props) => {
  // const getColorTheme = JSON.parse(localStorage.getItem("colors")) || [];
  // var primaryColor;
  // var secondaryColor;
  // if (getColorTheme[0] !== undefined) {
  //   primaryColor = getColorTheme[0].primaryColor;
  //   secondaryColor = getColorTheme[0].secondaryColor;
  // }

  // console.log("p",primaryColor);
  // console.log("s",secondaryColor)

  // console.log("hello",getColorTheme[0].primaryColor)
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // const dispatch=useDispatch();
  // const themeData=useSelector((state)=>state.themeReducer.themeData);
  // console.log("..",themeData);

  // React.useEffect(()=>{
  //   dispatch(getThemeData())
  // },[])

  // const {apiTheme}=useThemeApi();
  // console.log('aa',apiTheme)
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px",
              borderRadius: "10px", // Set your width here
            },
          },
          zIndex: 1200,
        }}
      >
        <Box
          sx={{
            height: "140px",
            backgroundColor: "primary.main",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "28%",
              margin: "auto",
              height: "100px",
              backgroundColor: "secondary.main",
              border: "1px soild black",
              borderRadius: "100px",
              position: "relative",
              top: "62%",
            }}
          >
            <Box
              sx={{
                width: "70%",
                position: "relative",
                left: "17%",
                top: "16%",
              }}
            >
              <WarningAmberIcon sx={{ fontSize: "65px" }} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "65px",
            textAlign: "center",
            boxSizing: "border-box",
            padding: "15px",
          }}
        >
          <Box>
            <Typography variant="h5">
              {props.header} {/*Delete*/}
            </Typography>
            <Typography>
              {props.message}
              {/* Are you sure you want to delete? */}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
            padding: "10px 10px 20px 10px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              marginRight: "20px",
              height: "40px",
              width: "40%",
              borderRadius: "20px",
            }}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "secondary.main",
              height: "40px",
              width: "40%",
              borderRadius: "20px",
            }}
            onClick={props.handleDelete}
          >
            {props.rightButton}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteAlert;
