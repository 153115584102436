import { useEffect, useState } from "react";
import {
  Menu,
  PieChart,
  Home,
  Business,
  Apartment,
  Work,
  LibraryAddCheck,
  Logout,
  Accessibility
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogoutActionFn } from "../../Redux/Auth/action";
import { menuItems, /* getActiveIndex */ } from "../../constants/menuItems";
import { Box, Stack, Tooltip, Typography, styled } from "@mui/material";
import longLogo from "../../assets/digital-sherpa-logo.svg";
import shortLogo from "../../assets/shortLogoDS.svg";
import { sessionExpired } from "../../utils/sessionExpired";

export const Sidebar2 = ({ activePath, roleModule }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [expandedImg, setExpandedImg] = useState(false);
  const [newMenuItem, setNewMenuItem] = useState([])

  const logoutUser = () => {
    dispatch(userLogoutActionFn());
  };

  const handleExpandation = () => {
    setExpanded((pre) => !pre);
    let timerId = setTimeout(() => {
      return setExpandedImg((pre) => !pre);
    }, 250);
  };

  useEffect(() => {
    // Check Active Login
    const isSExpired = sessionExpired();
    if(isSExpired===true){
     dispatch(userLogoutActionFn());
    }
    // Check Active Login
    let newMenuDetails = []
    menuItems.forEach(async (menu) => {
      if(menu.name != "LogOut" && menu.name != "Digital Sherpa"){
        let roleAccess = await roleModule.checkUserRoles(menu,'view')
        if(roleAccess == true){
          newMenuDetails.push(menu)
        }
      }      
    })
    setTimeout(() => {
      if(newMenuDetails.length > 0){
        setNewMenuItem(newMenuDetails)
      }
    },500)
  },[roleModule, menuItems, activePath])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "100%",
        width: `${expanded ? "300px" : "70px"}`,
        backgroundColor: "primary.main",
        position: "relative",
        borderRadius: "10px",
        transition: "400ms",
      }}
    >
      <Stack direction="column" spacing="5px" sx={{ width: "100%" }}>
        <Box sx={{ margin: "0", padding: "0" }}>
          <Link to="/openings_table">
            <img
              style={{
                transition: "0.3s ease-in-out",
                width: expanded ? "200px" : "55px",
                height: "50px",
                backgroundColor: "white",
                borderRadius: "10px",
                marginLeft: expanded ? "20px" : "5px",
                marginTop: "10px",
              }}
              src={expandedImg ? longLogo : shortLogo}
            />
          </Link>
        </Box>
        <Tooltip title={expanded ? "" : "Expand"} arrow placement="right">
          <ItemBox
            onClick={handleExpandation}
            sx={
              {
                // border: "2px solid green",
              }
            }
          >
            <Menu sx={{ color: "#fff", }} />
            {expanded && (
              <Typography
                sx={{ fontSize: "15px", color: "#fff", fontWeight: "bold" }}
              >
                Digital Sherpa
              </Typography>
            )}
          </ItemBox>
        </Tooltip>
      </Stack>
      <Stack
        direction="column"
        spacing="5px"
        sx={{ width: "100%", marginTop: "-20px" }}
      >
        {newMenuItem.map((menu, idx) => {
          return menu.name === "LogOut" ||
            menu.name === "Digital Sherpa" ? null : (
            <Link
              key={idx}
              style={{ color: "inherit", textDecoration: "none" }}
              to={`${menu.path}`}
            >
              <Tooltip
                title={`${expanded ? "" : menu.name}`}
                placement="right"
                arrow
              >
                <ItemBox
                  sx={{
                    backgroundColor:
                      // getActiveIndex(activePath) === idx
                      menu.path === '/'+activePath
                        ? "secondary.main"
                        : "transparent",
                    ":hover": {
                      backgroundColor: "secondary.main",
                    },
                  }}
                >
                  <>
                    <ReusableIcon iconName={menu.muiIcon} className="reusable-icon" />
                    {expanded && (
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "15px",
                          color: "#fff",
                          fontWeight: "semibold",
                        }}
                      >
                        {menu.name}
                      </Typography>
                    )}
                  </>
                </ItemBox>
              </Tooltip>
            </Link>
          );
        })}
      </Stack>
      <Tooltip title={`${expanded ? "" : "Logout"}`} arrow placement="right">
        <ItemBox
          onClick={logoutUser}
          sx={{
            position: "absolute",
            bottom: "10px",
          }}
        >
          <Logout sx={{ color: "red",}} />
          {expanded && (
            <Typography sx={{ fontSize: "15px", color: "#fff" }}>
              Logout
            </Typography>
          )}
        </ItemBox>
      </Tooltip>
    </Box>
  );
};

const ItemBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    width: "97%",
    height: "50px",
    display: "flex",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    cursor: "pointer",
    gap: "20px",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: "20px",
    color: "#fff",
    transition:"0.35s ease-in-out",
    //justifyContent:"center"
  },
}));
const ReusableIcon = ({ iconName,iconColor,iconSize }) => {
  const IconComponent = {
    Menu,
    PieChart,
    Home,
    Business,
    Apartment,
    Work,
    LibraryAddCheck,
    Logout,
    // Accessibility
  }[iconName];

  if (!IconComponent) {
    return null;
  }
  return <IconComponent iconsize={"100"} size={String(iconSize)} color={iconColor} />;
};
