import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { roleModulePermissions } from "../../utils/roleInstance";

// This Variable holds Button style
const ButtonStyle = {
  width: "100%",
  height: "50px",
  padding: "10px 10px 10px 10px",
  boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  // backgroundColor: "#FE9A25",
  backgroundColor: "#FE9A25",
  color: "#1E0D61",
  // fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: "500",
  textTransform: "none",
  // fontFamily:"san-sarif"
};

// This SearchFeild function we are using In Question table and Job Role Table
const SearchFeild = (props) => {
  // Check Role Permission
  const [questionAction, setQuestionAction] = useState("")

  const checkQuestionAction = async() => { 
    const roleData = {
      roleModule: props.roleModule
    }
    let rolePermission = await roleModulePermissions.checkUserRoles(roleData,'add')
    // return rolePermission
    setQuestionAction(rolePermission)
  }

  useEffect(() => {
    checkQuestionAction()
  },[props.roleModule])
  
  // Check Role Permission

  return (
    <>
      <Stack
        sx={{
          width: props.width,
          height: props.height,
          margin: "auto",
          mt: {
            lg: props.large,
            md: props.medium,
            sm: props.small,
            xs: props.xtraSmall,
          },
          mb: props.marginBottom || "10px",
        }}
        direction={{
          lg: "row",
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
        }}
        spacing={{ lg: 1, md: 2, sm: 1.5, xs: 5 }}
        justifyContent="space-between"
        // alignItems="center"
      >
        <Box style={{ width: props.TextFieldWidth }}>
          <TextField
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    {/* This Search Icon we used for Search Input Box */}
                    <SearchIcon style={{ color: "#CAC2C2" }} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                borderRadius: "12px",
                //backgroundColor: "#FFFFFF",
                height: "50px",
                padding: 2,
                width: props.searchWidth
              },
            }}
            variant="outlined"
            type="text"
            placeholder={props.placeholder}
            onChange={props.onChange} //We set the SetSearch Onchange function.
          />
        </Box>
        {/* From this block we are using for Add Question Button*/}
        <Box>
          {questionAction != '' && questionAction != undefined ?
            <Button
              sx={{
                ...ButtonStyle,
                backgroundColor: "secondary.main",
                color: "primary.main",
                "&:hover":{
                  backgroundColor:"secondary.dark",
                }
              }}
              onClick={props.Navigate}
            >
              <AddIcon fontSize="large" /> {props.buttonName}
            </Button>
          : 
            <Button
              sx={{
                ...ButtonStyle,
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
              disabled={true}
            >
              <AddIcon fontSize="large" /> {props.buttonName}
            </Button>
          }
        </Box>
      </Stack>
    </>
  );
};

export default SearchFeild;
