import React from "react";
import {
  MenuItem,
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customTextField: {
    "& input::placeholder": {
      fontSize: "1000px",
      marginBottom: "10px",
    },
  },
  customSelect: {
    "#demo-simple-select-required": {
      height: "100px",
    },
  },
}));

const BotSection = () => {
  const classes = useStyles();
  return (
    <Box
      style={{
        width: "auto",
        // height: "126px",
        padding: "12px 10px 11px 9px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "7px",
        border: "1px solid #000",
        backgroundColor: "white",
      }}
    >
      <Stack direction="column">
        <FormControl
          variant="outlined"
          style={{ marginTop: 1, minWidth: 150 }}
          size="small"
        >
          {/* Bot Interpreted Select Input */}
          <InputLabel id="demo-simple-select-required-label">
            Bot Interpreted*
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            defaultValue=""
            label="Bot Interpreted"
          >
            {/* <MenuItem value="">None</MenuItem> */}
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
            <MenuItem value={"c"}>Custom</MenuItem>
            <MenuItem value={"v"}>Score</MenuItem>
          </Select>
          {/*  */}
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ marginTop: 11, minWidth: 150 }}
          size="small"
        >
          {/* Bot Name Select Input */}
          <InputLabel id="demo-simple-select-required-label">
            Bot Name*
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Bot Name"
            defaultValue=""
            style={{ height: "43px" }}
            size="small"
            //   style={style}
          >
            <MenuItem value={"yesOrNo"}>YesOrNo</MenuItem>
            <MenuItem value={"checkNumberic#year"}>Numberic Year</MenuItem>
            <MenuItem value={"yearCheck"}>Year Check</MenuItem>
            <MenuItem value={"voiceScore"}>Communication Score</MenuItem>
          </Select>
          {/*  */}
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ marginTop: 11, minWidth: 150 }}
          size="small"
        >
          {/* Path Select Input */}
          <InputLabel id="demo-simple-select-required-label">Path*</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Path"
            defaultValue=""
            style={{ height: "43px" }}

            //   style={style}
          >
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
            <MenuItem value={"b"}>Both</MenuItem>
          </Select>
          {/*  */}
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ marginTop: 11, minWidth: 150 }}
          size="small"
        >
          {/* Adaptive Select Input */}
          <InputLabel id="demo-simple-select-required-label">
            Adaptive *
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Adaptive"
            defaultValue=""
            style={{ height: "43px" }}
            //   style={style}
          >
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
            <MenuItem value={"b"}>Both</MenuItem>
          </Select>
          {/*  */}
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ marginTop: 11, minWidth: 150 }}
          size="small"
        >
          {/* YPath Select Input */}
          <InputLabel id="demo-simple-select-required-label">YPath*</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Bot Name"
            defaultValue=""
            style={{ height: "43px" }}
            //   style={style}
          >
            {/* <MenuItem value={"yesOrNo"}>YesOrNo</MenuItem> */}
            <MenuItem value={"0"}>0</MenuItem>
            <MenuItem value={"1"}>1</MenuItem>
          </Select>
          {/*  */}
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ marginTop: 11, minWidth: 150 }}
          size="small"
        >
          {/* NPath Select Input */}
          <InputLabel id="demo-simple-select-required-label">NPath*</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Bot Name"
            defaultValue=""
            style={{ height: "43px" }}
            //   style={style}
          >
            <MenuItem value={"0"}>0</MenuItem>
            <MenuItem value={"1"}>1</MenuItem>
          </Select>
          {/*  */}
        </FormControl>
      </Stack>
    </Box>
  );
};

export default BotSection;
