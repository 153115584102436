import * as types from "./actionType";
const initialDocCategoryState = {
  isLoading: false,
  isError: false,
  categories: [],
  subCategories: [],
};

export const documentCategoryReducer = (
  state = initialDocCategoryState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_DOCUMENT_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        categories: payload,
        isError: false,
      };
    case types.GET_DOCUMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        categories: payload,
      };
    case types.GET_DOCUMENT_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        categories: [],
      };
    case types.GET_DOCUMENT_SUB_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        subCategories: payload,
        isError: false,
      };
    case types.GET_DOCUMENT_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        subCategories: payload,
      };
    case types.GET_DOCUMENT_SUB_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        subCategories: [],
      };
    default:
      return state;
  }
};
