import React, { useState } from "react";
import loadingImg from "../../assets/loading.svg";
import "./loading.css";
import { ScaleLoader } from "react-spinners";

const LoadingComponent = () => {
  return (
    <div className="loadingCont">
      <ScaleLoader 
      color="#36d7b7"
      width={4.5} 
      height={"40px"}
      speedMultiplier={1.3}
       />
    </div>
  );
};

export default LoadingComponent;
