import * as types from "./actionTypes";
const initialState = {
  isLoading: false,
  processName: [],
  isError: false,
};

export const iflowDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PROCESSNAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_PROCESSNAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        processName: payload,
        isError: false,
      };
    case types.GET_PROCESSNAME_FAILURE:
      return {
        ...state,
        isLoading: false,
        processName: [],
        isError: true,
      };
    default:
      return state;
  }
};
