import {Box, Snackbar} from '@mui/material'
import MuiAlert from "@mui/material/Alert";
import React from 'react'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const ApiAlert = (props) => {
  return (
    <Box>
      <Snackbar
          open={props.open}
          autoHideDuration={3000}
          onClose={props.snackbaronClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={props.alertonClose}
            severity={props.alertType}
            sx={{ width: "100%" }}
          >
           {props.Content}
          </Alert>
        </Snackbar>
    </Box>
  )
}

export default ApiAlert
