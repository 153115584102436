import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  removeDataFromLocalStorage,
  updateAccessTokenToLocalData,
} from "./localStorage";
import { refreshTokenHeadersObject } from "./headers";
import historyObject from "../Routes/HistoryRouterObject";
import { environmentDetails } from "../environment/environment";
const apiUrl = environmentDetails.apiUrl;
//const apiUrl = "http://localhost:4000";

const privateAxiosInstance = axios.create({
  baseURL: apiUrl,
  //headers: { Authorization: `Breareeeee ${JSON.parse(window.localStorage.getItem("userLogin"))?.token}` },
});

privateAxiosInstance.interceptors.request.use(async (req) => {
  // console.log("instance REQ called : ",req.headers)
  if (req.headers?.Authorization === undefined) {
    // removeDataFromLocalStorage("userLogin");
    // window.location.href = "/";
    console.log("token undefined going to network therefore user need to login again")
    return req;
  }
  let reqToken = req.headers?.Authorization.split(" ")[1];
  let decodedToken = jwtDecode(reqToken);
  var date = new Date();
  const isTokenExpired = decodedToken.exp < date.getTime() / 1000;
  if (isTokenExpired) {
    //console.log("The token has expired");
    try {
      let res = await axios.post(
        `${apiUrl}/questionnaire/refreshToken`,
        {},
        { headers: refreshTokenHeadersObject() }
      );
      if (res?.data?.status) {
        updateAccessTokenToLocalData(res.data?.token);
        req.headers.Authorization = `Bearer ${res.data?.token}`;
        return req;
      } else {
        removeDataFromLocalStorage("userLogin");
        window.location.href = "/";
      }
    } catch (err) {
      // alert("refresh token logout logout");
      //console.log("refresh token err: ", err);
      removeDataFromLocalStorage("userLogin");
      window.location.href = "/";
      // historyObject.push("/")
    }
  } else {
    return req;
  }
});

privateAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error
  }
);

export { privateAxiosInstance };
