import * as types from "./actionTypes";
import axios from "axios";

import { environmentDetails } from "../../../src/environment/environment";
import { accessTokenHeadersObject } from "../../utils/headers";
import { generateNewAccessToken } from "../../utils/requests";
import { privateAxiosInstance } from "../../utils/axiosInstance";

const apiUrl = environmentDetails.apiUrl;

//This Function Calling Question GET API
const getJobRoleData =
  (page = 1, pageSize = 10, searchQuery = "",jobRoleStatus) =>
  (dispatch) => {
    dispatch({ type: types.GET_JOBROLES_REQUEST });
    ///return axios
    //.get(`${apiUrl}questionnaire/get-jd?page=${page}&pageSize=${pageSize}`)
    return privateAxiosInstance
      .get(
        `/questionnaire/get-jd?page=${page}&pageSize=${pageSize}&jobRole=${searchQuery}&jobRoleStatus=${jobRoleStatus}`,
        { headers: { ...accessTokenHeadersObject() } }
      )
      .then((res) => {
        /// console.log("get job role res: ",res)
        return dispatch({
          type: types.GET_JOBROLES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        return dispatch({ type: types.GET_JOBROLES_FAILURE, payload: err });
      });
  };
//get job role by id
const getJobRoleByIdData = (jobRoleId) => (dispatch) => {
  dispatch({ type: types.GET_JOBROLE_BY_ID_REQUEST });
  ///return axios
  //.get(`${apiUrl}questionnaire/get-jd?page=${page}&pageSize=${pageSize}`)
  return privateAxiosInstance
    .get(`/questionnaire/get-jd/${jobRoleId}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      // console.log("get job role res action: ",res)
      return dispatch({
        type: types.GET_JOBROLE_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_JOBROLE_BY_ID_FAILURE, payload: err });
    });
};

//This Function Calling JobRoles POST API
const postJobRoles = (data) => (dispatch) => {
  // console.log("action post jd called: ",data);
  dispatch({ type: types.POST_JOBROLES_REQUEST });
  // return axios
  //   .post(`${apiUrl}/questionnaire/save-jd`, data, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   })
  return privateAxiosInstance
    .post(`/questionnaire/save-jd`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...accessTokenHeadersObject(),
      },
    })
    .then((res) => {
      if (res.code == "ERR_BAD_REQUEST" || res.code == "ERR_BAD_RESPONSE") {
        return dispatch({
          type: types.POST_JOBROLES_FAILURE,
          payload: res.response.data,
        });
      }
      return dispatch({ type: types.POST_JOBROLES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log("Error response for JD =>", err);
      return dispatch({ type: types.POST_JOBROLES_FAILURE, payload: err });
    });
};

//This Function Calling Question EDIT/UPDATE API
const editJobRoleData = (id, updateData) => (dispatch) => {
  dispatch({ type: types.EDIT_JOBROLES_REQUEST });
  return privateAxiosInstance
    .put(`/questionnaire/update-jd/${id}`, updateData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...accessTokenHeadersObject(),
      },
    })
    .then((res) => {
      return dispatch({
        type: types.EDIT_JOBROLES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.EDIT_JOBROLES_FAILURE, payload: err });
    });
};

const editJobroleStatus = (id, status, updateData) => (dispatch) => {
  dispatch({ type: types.EDIT_JOBROLE_STATUS_REQUEST });
  return privateAxiosInstance
    .put(
      `/questionnaire/update-jobRoleStatus?jobRoleId=${id}&status=${status}`,
      {},
      {
        headers: { ...accessTokenHeadersObject() },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return dispatch({
          type: types.EDIT_JOBROLE_STATUS_SUCCESS,
          payload: res.data,
        });
      } else {
        return dispatch({
          type: types.EDIT_JOBROLES_FAILURE,
          payload: res.response.data,
        });
      }
    })
    .catch((err) => {
      console.log("eeeeeeeeerrr==> ", err);
      return dispatch({
        type: types.EDIT_JOBROLES_FAILURE,
        payload: err,
      });
    });
};

//This Function Calling Question DELETE API
const deleteJobRoles = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_JOBROLES_REQUEST });
  //return privateAxiosInstance.delete(`${apiUrl}/questionnaire/delete-jd/${id}`,{headers:{...accessTokenHeadersObject()}})
  return privateAxiosInstance
    .delete(`/questionnaire/delete-jd/${id}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.DELETE_JOBROLES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.DELETE_JOBROLES_FAILURE, payload: err });
    });
};

export {
  getJobRoleData,
  postJobRoles,
  editJobRoleData,
  editJobroleStatus,
  deleteJobRoles,
  getJobRoleByIdData,
};
