import React, { useEffect, useState } from "react";
import {
  TextField,
  Stack,
  MenuItem,
  Autocomplete,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import DeleteAlert from "../Components/AlertBox/DeleteAlert";

import { environmentDetails } from "../environment/environment";
import EditSelectedQuestionModal from "../Components/Modals/EditSelectedQuestionModal";
import ApiAlert from "../Components/AlertBox/ApiAlert";
import { useDispatch, useSelector } from "react-redux";
import { getRecruitmentCompanyActionFn } from "../Redux/RecruitmentCompany/action";
import { getJobRoleData } from "../Redux/JobRole/action";
import {
  deleteOpeningQuestionActionFn,
  editOpeningQuestionActionFn,
  editQuestionByOpeningActionFn,
  getOpeningByFilterActionFn,
} from "../Redux/QuestionPacakge/action";

import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd"; // new
import DragHandleIcon from "@mui/icons-material/DragHandle"; // new
import { columns } from "../constants/selectedQuestionTab";
import { roleModulePermissions } from "../utils/roleInstance";
const apiUrl = environmentDetails.apiUrl;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  // commented by Sahnawaz
  // formControl: {
  //   margin: theme.spacing(1),
  //   width: 300,
  // },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  editIconButton: {
    "&:hover": {
      color: "rgb(254, 154, 37)",
      cursor: "pointer",
      // Set EditIcon hover text color here
    },
  },
  deleteIconButton: {
    "&:hover": {
      color: "red",
      cursor: "pointer",
      // Set deleteIconButton hover text color here
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      //borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid gray`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
  sticky: {
    position: "sticky",
    right: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

///  const options = ["tcs", "digitalsherpa", "amazon"];

const SelectedQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [jobRoleMenu, setJobRoleMenu] = React.useState([]);
  const [jobRole, setJobRole] = React.useState("");
  const [reqCompanyMenu, setReqCompanyMenu] = useState([]);
  const [data, setData] = useState([]);
  const [newQuestion, setNewQuestion] = useState([]);
  const [openingId, setOpeningId] = useState(null);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [alert, setAlert] = useState(false);

  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [alertContent, setAlertContent] = useState("");
  const [jobRoleDefaultValue, setJobRoleDefaultValue] = useState({});
  const [rCompanyDefaultValue, setRCompanyDefaultValue] = useState([]);
  const jobRoleData = useSelector((state) => state.jobRoleReducer.jobRoles);
  const recruitmentData = useSelector((state) => state.recruitmentReducer.data);
  const selectedPageParams = useParams();

  // Check Role Permission
  const [questionAddAction, setQuestionAddAction] = useState("");
  const [questionEditAction, setQuestionEditAction] = useState("");
  const [questionDeleteAction, setQuestionDeleteAction] = useState("");

  const checkQuestionAddAction = async () => {
    const roleData = {
      roleModule: "Selected Questions",
    };
    let rolePermission = await roleModulePermissions.checkUserRoles(
      roleData,
      "add"
    );
    setQuestionAddAction(rolePermission);
  };

  const checkQuestionEditAction = async () => {
    const roleData = {
      roleModule: "Selected Questions",
    };
    let rolePermission = await roleModulePermissions.checkUserRoles(
      roleData,
      "edit"
    );
    setQuestionEditAction(rolePermission);
  };

  const checkQuestionDeleteAction = async () => {
    const roleData = {
      roleModule: "Selected Questions",
    };
    let rolePermission = await roleModulePermissions.checkUserRoles(
      roleData,
      "delete"
    );
    setQuestionDeleteAction(rolePermission);
  };
  // Check Role Permission

  const { jobRoleId, recCompanyIds } = selectedPageParams;

  const recruitmentCompanySelect = selected.join();

  var urlParams = window.location.href;
  urlParams = urlParams.split("/");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (qId) => {
    setOpen(true);
    setIdToDelete(qId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = [];
  const companyMap = recruitmentData.map((el) => {
    return options.push(el.name);
  });

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChangeRecCompany = (event, value, reason) => {
    setRCompanyDefaultValue(value);
    setSelected(value.map((val) => val.CompanyId));
  };

  const handleSelectQuestionsOnClick = () => {
    const selectedData = { jobRoleId: jobRole, rCompaniesId: selected };
    window.localStorage.setItem(
      "selectedPageData",
      JSON.stringify(selectedData)
    );
    navigate(
      `/selected_question/select_question/${openingId}/${jobRole}/${recruitmentCompanySelect}`
    );
  };

  const onDragEnd = (result, provided) => {
    //const { source, destination } = result;
    // Shahnawaz Code
    if (result.destination.index === result.source.index) {
      return;
    }
    reorder(data, result.source.index, result.destination.index);
    setTimeout(() => {
      var i = 0;
      data.forEach((val) => {
        val["sort"] = i + 1;
        i++;
      });
      // console.log(data)
      let questionData = {
        selectedQuestionList: data,
      };
      editSelectedQuestions(questionData);
    }, 1500);

    // Shahnawaz Code
  };

  // edit selected question;
  const editSelectedQuestionByID = (data) => {
    dispatch(editOpeningQuestionActionFn({ openingId, data })).then((res) => {
      if (res.type === "EDIT_OPENING_QUESTION_SUCCESS") {
        getSelectedQuestion();
        setEditOpen(false);
        setAlert(true);
        setAlertStatus("success");
        setAlertContent("Question Updated Successfully");
      } else {
        getSelectedQuestion();
        setEditOpen(false);
        setAlert(true);
        setAlertStatus("error");
        setAlertContent("Internal server error please try again!");
      }
      // console.log("edit res: ",res)
    });
    return;
  };

  const editSelectedQuestions = (data) => {
    dispatch(editQuestionByOpeningActionFn({ openingId, data })).then((res) => {
      if (res.type === "EDIT_OPENING_QUESTION_SUCCESS") {
        getSelectedQuestion();
        setEditOpen(false);
        setAlert(true);
        setAlertStatus("success");
        setAlertContent("Question Updated Successfully");
      } else {
        getSelectedQuestion();
        setEditOpen(false);
        setAlert(true);
        setAlertStatus("error");
        setAlertContent("Internal server error please try again!");
      }
    });
    return;
  };
  const AlerthandleClose = () => {
    setAlert(false);
  };

  const reorder = async (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setData(maintainOrder(result));

    // return result;
  };

  const packageQuestionDelete = () => {
    dispatch(deleteOpeningQuestionActionFn({ openingId, idToDelete })).then(
      (res) => {
        if (res.type === `DELETE_OPENING_QUESTION_SUCCESS`) {
          handleClose();
          getSelectedQuestion();
        } else {
          console.log("err: ", res);
        }
      }
    );
  };

  const maintainOrder = (list) => {
    const newList = list.map((item, index) => {
      return { ...item, sort: index + 1 };
    });
    return newList;
  };

  const getSelectedQuestion = async () => {
    if (jobRole && selected.join()) {
      dispatch(
        getOpeningByFilterActionFn(jobRole, recruitmentCompanySelect)
      ).then((res) => {
        if (res.type === "GET_OPENING_BY_FILTER_SUCCESS") {
          // Sort Campaign Questions List
          let questionDetails = res.payload.selectedQuestionList;
          // console.log(questionDetails)
          questionDetails.sort((a, b) => {
            return a.sort - b.sort;
          });
          // console.log(questionDetails)
          setNewQuestion(questionDetails);
          // Sort Campaign Questions List
          setOpeningId(res.payload.openingId);
          setData(res.payload.selectedQuestionList);
          // console.log("dispatch--------> ",res.payload)
        } else {
          setData([]);
          setOpeningId(null);
        }
      });
    }
  };

  // get selected question for on page load,and selecting job role and recruitment company.
  useEffect(() => {
    getSelectedQuestion();
    if (!selected?.length || !jobRole) {
      setData([]);
      setOpeningId(null);
    }
    //console.log("cccccccccccccccccccccccccccccccc")
  }, [jobRole, selected]);

  useEffect(() => {
    if (jobRoleId && !jobRoleDefaultValue?.role) {
      setJobRole(jobRoleId);
      let filteredJobRole = jobRoleData?.data?.filter(
        (jobRole) => jobRole.jobId === jobRoleId
      );
      if (filteredJobRole) setJobRoleDefaultValue(filteredJobRole[0]);
    }
    if (recCompanyIds) {
      setSelected([recCompanyIds]);
      let filteredRecCompanies = recruitmentData.filter(
        (recC) => recC.CompanyId === recCompanyIds
      );
      setRCompanyDefaultValue(filteredRecCompanies);
    } else {
    }

    console.log("called");

    checkQuestionAddAction();
    checkQuestionEditAction();
    checkQuestionDeleteAction();
  }, [jobRoleData, recruitmentData]);

  useEffect(() => {
    dispatch(getRecruitmentCompanyActionFn(1, 5000, "", true));
    dispatch(getJobRoleData(1, 5000, "", true));
  }, []);

  const handleOnClickEditSelectedQuestion = (row) => {
    console.clear();
    console.log("before IF block",row)
    if (typeof row.documentCategory != "string") {
      row.documentCategory = ""
    }
    console.log("after IF block",row)
    setEditOpen(true);
    setDataToEdit(row);
  }
  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Box style={{ width: "100%" }}>
        <Stack
          direction={"row"}
          justifyContent={"right"}
          gap={2}
          sx={{ width: "100%", marginBottom: "15px" }}
        >
          <Box>
            <Autocomplete
              id="job-role-data"
              options={
                jobRoleData?.data
                  ? jobRoleData?.data.filter(
                    (role) => role.jobRoleStatus == true
                  )
                  : []
              }
              // defaultValue={selectedJobRoleData}
              value={jobRoleDefaultValue?.role ? jobRoleDefaultValue : null}
              sx={{ width: 300 }}
              getOptionLabel={(option) =>
                option?.role + " - " + "(" + option?.cities?.join(", ") + ")"
              }
              renderOption={(props, option) => (
                <span {...props} key={option.jobId}>
                  {option?.role +
                    " - " +
                    "(" +
                    option?.cities?.join(", ") +
                    ")"}
                </span>
              )}
              onChange={(event, value) => {
                setJobRoleDefaultValue(value);
                setJobRole(value?.jobId);
                checkQuestionAddAction();
                checkQuestionEditAction();
                checkQuestionDeleteAction();
              }}
              renderInput={(params, idx) => {
                // console.log("Params: ", params);
                return <TextField {...params} label="Job Role" />;
              }}
            />
          </Box>
          {/* R-Company .................................................................*/}
          <Box>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={
                recruitmentData
                  ? recruitmentData.filter(
                    (company) => company.companyStatus == true
                  )
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option, index) => `${option.name}`}
              //defaultValue={selectedCompanyData?.CompanyId}
              value={rCompanyDefaultValue}
              onChange={handleChangeRecCompany}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.CompanyId}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={<CheckBoxIcon fontSize="small" color="blue" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Recruitment Company"
                  placeholder="Recruitment Company"
                />
              )}
            />
          </Box>
          {/* R-Company End .................................................................*/}
          <Button
            variant="contained"
            sx={{
              height: "50px",
              borderRadius: "8px",
              // backgroundColor: "#FE9A25",
              backgroundColor: "secondary.main",
              color: "primary.main",
              "&:disabled": {
                backgroundColor: "secondary.dark",
                color: "primary.dark",
              },
              "&.MuiButton-root:hover": {
                backgroundColor: "secondary.light",
                color: "primary.light",
              },
            }}
            disabled={
              !jobRole || !recruitmentCompanySelect || !questionAddAction
            }
            onClick={handleSelectQuestionsOnClick}
          >
            Select Questions
          </Button>
        </Stack>
        <Box
          style={{
            // width: "90vw",
            width: "100%",
            marginTop: "0px",
            // border: "5px solid yellow",
          }}
        >
          <Paper sx={{ width: "100%" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <TableContainer sx={{ maxHeight: "calc(100vh - 173px)" }}>
                <Table
                  // sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            className={
                              // column.id === "Action" ? classes.sticky : ""
                              (questionEditAction != undefined &&
                                questionEditAction != "") ||
                                (questionDeleteAction != undefined &&
                                  questionDeleteAction != "")
                                ? column.id === "Action"
                                  ? classes.sticky
                                  : null
                                : ""
                            }
                            sx={{
                              minWidth: column.minWidth,
                              // backgroundColor: "rgb(30, 13, 97)",
                              // color: "rgb(254, 154, 37)",
                              backgroundColor: "primary.main",
                              color: "secondary.main",
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.id == "Action"
                              ? (questionEditAction != undefined &&
                                questionEditAction != "") ||
                                (questionDeleteAction != undefined &&
                                  questionDeleteAction != "")
                                ? column.label
                                : ""
                              : column.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <Droppable droppableId="droppable-questions" type="QUESTION">
                    {(
                      provided,
                      snapshot // new
                    ) => (
                      <TableBody
                        ref={provided.innerRef} // new
                        {...provided.droppableProps} // new
                      >
                        {data
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => (
                            <Draggable
                              key={String(row?.qId)}
                              draggableId={String(row?.qId)}
                              index={index}
                            >
                              {(
                                provided,
                                snapshot // new
                              ) => (
                                <TableRow
                                  key={row?.qId}
                                  ref={provided.innerRef} // new
                                  {...provided.draggableProps} // new
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {questionEditAction != "" &&
                                    questionEditAction != undefined ? (
                                    <TableCell
                                      {...provided.dragHandleProps} // new
                                    >
                                      <DragHandleIcon />
                                    </TableCell>
                                  ) : (
                                    <TableCell></TableCell>
                                  )}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row?.Question === ""
                                      ? "N/A"
                                      : row?.Question}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.questionType === ""
                                      ? "N/A"
                                      : row?.questionType}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.Weight === "" ? "N/A" : row?.Weight}
                                  </TableCell>
                                  {/*<TableCell align="center">{"N/A"}</TableCell>*/}
                                  <TableCell align="center">
                                    {row?.Ack === "" ? "NA" : row?.Ack}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.mandatoryQuestion === ""
                                      ? "N/A"
                                      : row?.mandatoryQuestion}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.defaultValue === ""
                                      ? "N/A"
                                      : row?.defaultValue}
                                  </TableCell>
                                  {/*<TableCell align="center">
                                    {row?.Adaptive === ""
                                      ? "N/A"
                                      : row?.Adaptive}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.yPath === "" ? "NA" : row?.yPath}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.nPath === "" ? "NA" : row?.nPath}
                                  </TableCell>*/}
                                  <TableCell align="center">
                                    {row?.expectDocument !== ""
                                      ? row?.expectDocument === "yes"
                                        ? "Yes"
                                        : "No"
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.["Next Question Prefix"] === ""
                                      ? "N/A"
                                      : row?.["Next Question Prefix"]}
                                  </TableCell>
                                  {/*<TableCell align="center">
                                    {row?.["Help Text"] === ""
                                      ? "N/A"
                                      : row?.["Help Text"]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.["Template Name"] === ""
                                      ? "N/A"
                                      : row?.["Template Name"]}
                                  </TableCell>
                                  <TableCell align="center">{"N/A"}</TableCell>*/}
                                  <TableCell
                                    align="center"
                                    className={
                                      (questionEditAction != undefined &&
                                        questionEditAction != "") ||
                                        (questionDeleteAction != undefined &&
                                          questionDeleteAction != "")
                                        ? classes.sticky
                                        : ""
                                    }
                                    sx={{
                                      cursor: "pointer",
                                      //  zIndex:"9999 !important",
                                      backgroundColor: "primary.background",
                                      boxShadow: "5px 2px 5px grey",
                                    }}
                                  >
                                    {questionEditAction != "" &&
                                      questionEditAction != undefined ? (
                                      <Tooltip
                                        title="Edit Selected Question"
                                        arrow
                                        placement="top"
                                      >
                                        <EditOutlined
                                          fontSize="medium"
                                          className={classes.editIconButton}
                                          onClick={() => handleOnClickEditSelectedQuestion(row)}
                                        />
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                    {questionDeleteAction != "" &&
                                      questionDeleteAction != undefined ? (
                                      <Tooltip
                                        title="Delete Question"
                                        arrow
                                        placement="top"
                                      >
                                        <DeleteOutline
                                          className={classes.deleteIconButton}
                                          fontSize="medium"
                                          style={{ marginLeft: 8 }}
                                          onClick={() =>
                                            handleClickOpen(row.qId)
                                          }
                                        />
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder} {/* new */}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </TableContainer>
            </DragDropContext>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <DeleteAlert
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            handleDelete={packageQuestionDelete}
            rightButton={"Delete"}
            header={"Delete"}
            message={"Are you sure you want to delete?"}
          />

          <EditSelectedQuestionModal
            open={editOpen}
            setOpen={setEditOpen}
            data={dataToEdit}
            editSelectedQuestion={editSelectedQuestionByID}
            allSelectedQuestions={data}
            jobRoleDefaultValue={jobRoleDefaultValue}
          />
        </Box>
      </Box>
    </>
  );
};

export default SelectedQuestion;
