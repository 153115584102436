import * as types from "./actionTypes";
import { getLocalStorageData } from "../../utils/localStorage";
import { environmentDetails } from "../../../src/environment/environment";
import axios from "axios";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import { refreshTokenHeadersObject } from "../../utils/headers";
const apiUrl = environmentDetails.processApiUrl;
const isUserLoggedIn = getLocalStorageData("userLogin");
const userToken = isUserLoggedIn?.refreshToken;

const getProcessName = (processName) => (dispatch) => {
  dispatch({ type: types.GET_PROCESSNAME_REQUEST });
  // return axios
  //   .get(
  //     apiUrl,
  //     { headers: { Authorization: `Bearer ${userToken}` } }
  //   )
  const headers = {
    // 'Authorization': `Bearer ${userToken}`,
    ...refreshTokenHeadersObject(),
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8",
  };
  // return axios
  //   .get(
  //     apiUrl,
  //     {headers},
  //     {mode: "cors"}
  //   )
  return privateAxiosInstance
    .get(
      `/questionnaire/get-all-process-variable`,
      { headers },
      { mode: "cors" }
    )
    .then((res) => {
      return dispatch({
        type: types.GET_PROCESSNAME_SUCCESS,
        // payload: res.data.data,
        payload: res.data.data.filter(
          (i) => i.processDef.processtype === "RecA"
        ),
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_PROCESSNAME_FAILURE, payload: err });
    });
};

export { getProcessName };
