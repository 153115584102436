import {
  Box,
  Modal,
  Typography,
  TextField,
  Stack,
  Button,
  Slider,
  Grid,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Autocomplete,
  IconButton,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { getThemeData } from "../../Redux/Theme/action";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel";
import {
  extractUnCommonWords,
  findSameQuestion2,
  findSimilarQuestions,
  isContainsDoubleDollar,
} from "../../utils/helperFunctions";
import { Check, CopyAll } from "@mui/icons-material";
import { CkEditorComponent } from "../ReusableComponents/CkEditor";
import { getTemplateName } from "../../Redux/Templates/action";
import { schema } from "../../constants/masterQuestion";
import { useThrottle } from "../../hooks/useThrottle";
import { getQuestionsData } from "../../Redux/Questions/action";
import DeleteAlert from "../AlertBox/DeleteAlert";
import img_not_found from "../../assets/img_not_found.jpg";
import {
  getAllDocumentCategoriesActionFn,
  getDocumentSubCategoriesActionFn,
} from "../../Redux/documentCategory/action";
import { useParams } from "react-router-dom";
const initailState = {
  Question: "",
  Weight: "",
  Ack: "",
  defaultValue: "",
  Adaptive: "",
  yPath: "",
  nPath: "",
  "Next Question Prefix": "",
  "Help Text": "",
  processingNeeded: "",
  rangeStart: "",
  rangeEnd: "",
  mandatoryQuestion: "",
  // processingNeeded: "",
  "Template Name": "",
  "Attach Job Description": "",
  "Job Description": "",
  qId: "",
  "Scoring Engine Field Name": "",
  keywords: [],
  documentCategory: "",
  documentType: "",
  expectDocument: "",
  jdImage: "",
};

const EditSelectedQuestionModal = ({
  open,
  setOpen,
  data,
  editSelectedQuestion,
  jobRoleDesc,
  allSelectedQuestions,
  jobRoleDefaultValue,
}) => {
  const [questionTitle, setQuestionTitle] = useState("");
  const [isContainsDDollar, setIsContainsDDollar] = useState(false);
  const [copiedImgLink, setCopiedImgLink] = useState(false);
  const [JobDescCkEditor, setJobDescCkEditor] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [masterQuestions, setMasterQuestions] = useState([]);
  const [openSimilar, setOpenSimilar] = useState(false);
  const [similarQuestinToSave, setSimilarQuestionToSave] = useState(null);
  const [isSameQuestionExist, setIsSameQuestionExist] = useState(false);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [documentSubCategories, setDocumentSubCategories] = useState([]);
  const [selectedDocumentCategoryItem, setSelectedDocumentCategoryItem] =
    useState(null);
  const throlltedFunction = useThrottle();
  const dispatch = useDispatch();
  const params = useParams();
  let { jobRoleId } = params;
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initailState,
    resolver: yupResolver(schema),
  });

  const expectDocumentWatch = watch("expectDocument");
  const documentTypeWatch = watch("documentType");
  const documentCategoryWatch = watch("documentCategory");

  const handleClose = () => {
    setOpen(false);
    setFilteredQuestions([]);
    setMasterQuestions([]);
    setOpenSimilar(false);
    setSimilarQuestionToSave(null);
    setIsSameQuestionExist(false);
  };

  const handleSimilarClose = () => {
    setOpenSimilar(false);
    setFilteredQuestions([]);
    setMasterQuestions([]);
    setOpenSimilar(false);
    setSimilarQuestionToSave(null);
    setIsSameQuestionExist(false);
  };
  // const dispatch=useDispatch();
  // const themeData=useSelector((state)=>state.themeReducer.themeData);
  // console.log("edit",themeData);

  // useEffect(()=>{
  //   dispatch(getThemeData())
  // },[])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };
  const templateList = useSelector(
    (state) => state.templateDataReducer.templateName
  );
  // console.log("data", data);
  // console.log("edit selected question", editSelectedQuestion);
  // console.log("List", templateList);
  // use form hook
  const attachJd = watch("Attach Job Description");
  useEffect(() => {
    dispatch(getTemplateName());
    // dispatch(getQuestionsData(1, 5000)).then((res) => {
    //   if (res.type === "GET_QUESTIONS_SUCCESS") {
    //     setMasterQuestions(res.payload);
    //   } else {
    //     console.log("fetching master question error", res);
    //   }
    // });
  }, []);
  useEffect(() => {
    // DOCUMENT CATEGORY WORK IN PROGESS
    if (expectDocumentWatch === "yes" && !documentCategories.length) {
      if (data?.documentCategory) {
        setValue("documentCategory", data?.documentCategory);
      }
      dispatch(getAllDocumentCategoriesActionFn()).then((res) => {
        if (res.type === "GET_DOCUMENT_CATEGORIES_SUCCESS") {
          setDocumentCategories(res.payload);
        } else {
          setDocumentCategories([]);
        }
      });
    }
    if (expectDocumentWatch === "no") {
      setValue("documentType", "");
      setValue("documentCategory", "");
    }
  }, [expectDocumentWatch, setValue]);

  useEffect(() => {
    if (attachJd === "y") {
      setValue("Job Description", jobRoleDefaultValue.desc);
      setValue("jdImage", jobRoleDefaultValue.image);
    }
  }, [attachJd]);

  // DOCUMENT CATEGORY WORK IN PROGESS
  // const handleCategoryOnChange = (event) => {
  //   let selectedDocumentType = event.target.value;
  //   //setSelectedDocumentCategoryItem(selectedDocumentType)
  //   if (selectedDocumentType === "KYC" && !documentSubCategories.length) {
  //     dispatch(getDocumentSubCategoriesActionFn()).then((res) => {
  //       if (res.type === "GET_DOCUMENT_SUB_CATEGORIES_SUCCESS") {
  //         setDocumentSubCategories(res.payload);
  //       }
  //     });
  //   } else {
  //     setDocumentSubCategories([]);
  //     console.log("eeeeeeeee ", event.target.value);
  //   }
  // };
  useEffect(() => {
    if (documentCategoryWatch === "KYC" && !documentSubCategories.length) {
      if (data.documentType) {
        setValue("documentType", data.documentType);
      }
      dispatch(getDocumentSubCategoriesActionFn()).then((res) => {
        if (res.type === "GET_DOCUMENT_SUB_CATEGORIES_SUCCESS") {
          setDocumentSubCategories(res.payload);
        }
      });
    } else {
      setDocumentSubCategories([]);
      setValue("documentType", "");
      //console.log("eeeeeeeee ", event.target.value);
    }
  }, [documentCategoryWatch]);
  useEffect(() => {
    reset(data);
    if (data != null) {
      if (data.Adaptive == "") {
        data.Adaptive = "n";
      }
    }
    if (data && typeof data?.documentCategory === Object) {
      data.documentCategory = "";
    }
    setQuestionTitle(data?.Question);
    if (attachJd == "y") {
      setJobDescCkEditor(data?.["Job Description"]);
    } else setJobDescCkEditor("");
    // setAttachJobDescription(
    //   data?.["Attach Job Description"] ? data?.["Attach Job Description"] : ""
    // );
    reset(data);
    // console.log("hello", data?.["Template Name"]);
  }, [open]);

  useEffect(() => {
    if (questionTitle) {
      let res = isContainsDoubleDollar(questionTitle);
      if (res) {
        setIsContainsDDollar(true);
      } else {
        setIsContainsDDollar(false);
      }
    } else {
      setIsContainsDDollar(false);
    }
  }, [questionTitle]);
  const handleCopyImgLink = (imgLink) => {
    setCopiedImgLink(true);
    navigator.clipboard.writeText(imgLink);
    setTimeout(() => {
      setCopiedImgLink(false);
    }, 4000);
  };

  // on Submit
  const editSelectedQuestionFinalFn = (questionObj) => {
    editSelectedQuestion(questionObj);
    setOpenSimilar(false);
  };

  const onSubmit = (editData) => {
    // console.log("editdataiiiii", editData);
    if (editData["Attach Job Description"] === "y") {
      editData["Job Description"] = jobRoleDefaultValue.desc;
      editData.jdImage = jobRoleDefaultValue.image;
    } else {
      editData["Job Description"] = "";
      editData.jdImage = "";
    }
    const data = { ...editData };

    let title = editData.Question;
    let unCommonWords = extractUnCommonWords(title);
    editData.keywords = unCommonWords;
    const newObj = Object.keys(initailState).map((key) => {
      if (key in editData) {
        return { [key]: editData[key] };
      }
    });
    const mergedObj = Object.assign({}, ...newObj);
    //mergedObj["Job Description"] = JobDescCkEditor;
    if (filteredQuestions.length > 0) {
      setSimilarQuestionToSave(data);
      setOpenSimilar(true);
      return;
    }
    editSelectedQuestionFinalFn(mergedObj);
    //editSelectedQuestion(mergedObj);
  };
  const onQuestionTitleKeyUp = (e) => {
    let value = e.target.value;
    setQuestionTitle(value);
    let throttleFunc = throlltedFunction(
      (value) => handleThrottleFunction(value),
      800
    );
    throttleFunc(value);
  };
  let keyWordsQues = allSelectedQuestions?.filter((ques) => ques.keywords);
  //console.log("key workds ",keyWordsQues);
  const handleThrottleFunction = (input) => {
    let similarQues = findSimilarQuestions(input, keyWordsQues, data.qId);
    // console.log("similar qestion ", similarQues);
    if (input && similarQues.length > 0) {
      let sameQuestion = findSameQuestion2(input, similarQues);
      //console.log("Same question is ", sameQuestion);
      if (sameQuestion.length > 0) {
        setIsSameQuestionExist(true);
      } else {
        setIsSameQuestionExist(false);
      }
      setFilteredQuestions(similarQues);
    } else {
      setFilteredQuestions([]);
    }
  };
  const handleSimilarQuestionSubmit = () => {
    editSelectedQuestionFinalFn(similarQuestinToSave);
  };
  
  return (
    <>
      <div>
        <Modal
          open={open}
          //onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "scroll", zIndex: "800" }}
        >
          <Box sx={{ ...style, maxHeight: "80vh", overflowY: "auto" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  border: "0px solid blue",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 600,
                    // color: "#2B2A2C",
                    color: "primary.darkText",
                  }}
                >
                  Edit Question
                </Typography>
                {/* <Grid container spacing={2}>    */}
                {/*               
                  {attachJd === "y" && (
                    <Grid item xs={12}>
                      <Box sx={{ overflowY: "auto" }}>
                        <FormControl>
                          <FormLabel sx={{ fontSize: "14px" }}>
                            Job Description
                          </FormLabel>
                          </FormControl>
                          <CkEditorComponent
                            ckEditorData={JobDescCkEditor}
                            handleCkEditorChange={handleCkEditorChange}
                          />
                      </Box>
                    </Grid>
                  )}
                  */}
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", top: "0", right: "0" }}
                >
                  <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                  {/* JOB DESCRIPTION  */}
                  {attachJd === "y" && (
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        maxRows={8}
                        //rows={2}
                        sx={{ width: "100%" }}
                        label="Job Description"
                        variant="outlined"
                        //disabled
                        // Text
                        inputProps={{
                          maxLength: 30,
                          readOnly: "Job Description" ? true : false,
                        }}
                        {...register("Job Description")}
                        //error={Boolean(errors.jobdescription)}
                        //helperText={errors.jobdescription?.message}
                      />
                    </Grid>
                  )}
                  {attachJd === "y" && (
                    //job image
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          minHeight: "30px",
                          width: "auto",
                          height: "250px",
                          border: "1px solid blue",
                          position: "relative",
                          borderColor: "primary.main",
                          borderRadius: "5px",
                          overflow: "hidden",
                        }}
                      >
                        <IconButton
                          label="Copy Image Url"
                          color="primary"
                          sx={{
                            position: "absolute",
                            right: "0px",
                            top: "0px",
                          }}
                          onClick={() =>
                            handleCopyImgLink(jobRoleDefaultValue?.image)
                          }
                        >
                          {copiedImgLink ? <Check /> : <CopyAll />}
                        </IconButton>
                        <img
                          src={jobRoleDefaultValue?.image}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          onError={(event) => {
                            event.target.onerror = null;
                            event.target.src = img_not_found;
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                  {/* QUESTION  */}
                  <Grid item xs={12}>
                    <>
                      <TextField
                        multiline
                        sx={{ width: "100%" }}
                        label={<ReuseableLabel label={"Question"} />}
                        variant="outlined"
                        {...register("Question", {
                          required: "Question required",
                        })}
                        onKeyUp={onQuestionTitleKeyUp}
                        error={Boolean(errors?.Question)}
                        helperText={errors?.Question?.message}
                      />
                      {filteredQuestions.length > 0 && (
                        <Box
                          sx={{
                            height: "fitcontent",
                            maxHeight: "200px",
                            overflowY: "auto",
                            overflowX: "auto",
                            boxShadow: 3,
                            borderBottomRightRadius: "7px",
                            borderBottomLeftRadius: "7px",
                            // border:"1px solid black"
                          }}
                        >
                          {/* <Chip label="Questions already exists in Master Questions list." variant="outlined" color="secondary" size="small" /> */}
                          <>
                            {filteredQuestions.map((item, idx) => {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    //handleMenuItemClick(item);
                                  }}
                                  key={idx}
                                >
                                  {item.Question}
                                </MenuItem>
                              );
                            })}
                          </>
                        </Box>
                      )}
                    </>
                  </Grid>
                  {/* WEIGHT  */}
                  <Grid item xs={6}>
                    <Stack direction={"row"} spacing={2} padding={1.2}>
                      <FormLabel
                        sx={{
                          paddingTop: "1px",
                          paddingLeft: "5px",
                          //opacity: "0.6",
                          display: "flex",
                          gap: "2px",
                          fontSize: "14px",
                        }}
                      >
                        Weightage <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <PrettoSlider
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        sx={{ color: "blue", arialabelbgColor: "blue" }}
                        min={0.1}
                        step={0.1}
                        max={1}
                        defaultValue={data?.Weight}
                        style={{ width: "80%", marginLeft: "8%" }}
                        {...register("Weight", {
                          required: "Weightage is Required",
                        })}
                        error={Boolean(errors.Weight)}
                        helperText={errors.Weight?.message}
                      />
                    </Stack>
                  </Grid>
                  {/* ACK  */}
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        width: "auto",
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel sx={{ fontSize: "14px" }}>
                          Attach Job Description
                        </FormLabel>
                        <Controller
                          //rules={{ required: true }}
                          control={control}
                          name="Attach Job Description"
                          render={({ field }) => (
                            <RadioGroup {...field} row>
                              <FormControlLabel
                                value="y"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="n"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* ACK  */}
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Acknowledgement"
                      size="small"
                      variant="outlined"
                      // Text
                      {...register("Ack", {
                        //required: "Acknowledgement Required",
                      })}
                      error={Boolean(errors.Ack)}
                      helperText={errors.Ack?.message}
                    />
                  </Grid>
                  {/* RANGE START & END*/}
                  <Grid item xs={6}>
                    <Stack direction={"row"} spacing={1}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Range Start"
                        variant="outlined"
                        size="small"
                        type="number"
                        // Text
                        {...register("rangeStart", {
                          //required: "Default Value Required",
                        })}
                      />
                      <TextField
                        sx={{ width: "100%" }}
                        label="Range End"
                        variant="outlined"
                        size="small"
                        type="number"
                        // Text
                        {...register("rangeEnd", {
                          //required: "Default Value Required",
                        })}
                      />
                    </Stack>
                  </Grid>
                  {/* DEFAULT VALUE  */}
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label={
                        isContainsDDollar ? (
                          <ReuseableLabel label="Default Value" />
                        ) : (
                          "Default Value"
                        )
                      }
                      variant="outlined"
                      size="small"
                      // Text
                      {...register("defaultValue", {
                        required: isContainsDDollar
                          ? "Default Value is Required"
                          : null,
                      })}
                      error={Boolean(errors.defaultValue)}
                      helperText={errors.defaultValue?.message}
                    />
                  </Grid>
                  {/* MANDATORY QUESTION  */}
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      error={Boolean(errors.mandatoryQuestion)}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Mandatory Question
                      </InputLabel>
                      <Controller
                        name="mandatoryQuestion"
                        control={control}
                        defaultValue=""
                        // rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="mandatoryQuestion"
                              id="mandatoryQuestionId"
                              label="mandatoryQuestion"
                              defaultValue=""
                              //style={{ height: "43px",width:"100%" }}
                              size="small"
                              error={Boolean(errors.mandatoryQuestion)}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"yes"}>Yes</MenuItem>
                              <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            {errors.mandatoryQuestion && (
                              <FormHelperText error>
                                {errors.mandatoryQuestion.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* ADAPTIVE  */}
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      error={Boolean(errors.Adaptive)}
                      disabled
                    >
                      {/* Adaptive Select Input */}
                      <InputLabel id="demo-simple-select-required-label">
                        Adaptive *{/* <ReuseableLabel label={'Adaptive'} /> */}
                      </InputLabel>
                      <Controller
                        name="Adaptive"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="select-adaptive-required-label"
                              id="select-adaptive-required-label"
                              label="Adaptive"
                              defaultValue=""
                              //style={{ height: "43px" }}
                              error={Boolean(errors.Adaptive)}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"y"}>Yes</MenuItem>
                              <MenuItem value={"n"}>No</MenuItem>
                              <MenuItem value={"b"}>Both</MenuItem>
                            </Select>
                            {errors.Adaptive && (
                              <FormHelperText error>
                                {errors.Adaptive.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* SCRING ENGINE FIELD NAME  */}
                  <>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Scoring Engine Field Name"
                        type="text"
                        placeholder="Scoring Engine Field Name"
                        inputProps={{
                          style: {
                            //backgroundColor: "white",
                          },
                        }}
                        size="small"
                        {...register("Scoring Engine Field Name")}
                        error={Boolean(errors?.["Scoring Engine Field Name"])}
                        helperText={
                          errors?.["Scoring Engine Field Name"]?.message
                        }
                      />
                    </Grid>
                  </>
                  {/* yPath  */}
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        disabled
                        size="small"
                        error={Boolean(errors.yPath)}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          YPath
                        </InputLabel>
                        <Controller
                          name="yPath"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Please select an option" }}
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                label="YPath*"
                                defaultValue=""
                                style={{ height: "43px" }}
                                error={Boolean(errors.yPath)}
                              >
                                <MenuItem value={""}>
                                  Please select an option
                                </MenuItem>
                                <MenuItem value={"0"}>0</MenuItem>
                                <MenuItem value={"1"}>1</MenuItem>
                              </Select>
                              {errors.yPath && (
                                <FormHelperText error>
                                  {errors.yPath.message}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </>
                  {/* nPath  */}
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                        error={Boolean(errors.nPath)}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          NPath
                        </InputLabel>
                        <Controller
                          name="nPath"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Please select an option" }}
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                label="NPath"
                                defaultValue=""
                                style={{ height: "43px" }}
                                error={Boolean(errors.nPath)}
                              >
                                <MenuItem value={""}>
                                  Please select an option
                                </MenuItem>
                                <MenuItem value={"0"}>0</MenuItem>
                                <MenuItem value={"1"}>1</MenuItem>
                              </Select>
                              {errors.nPath && (
                                <FormHelperText error>
                                  {errors.nPath.message}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </>
                  {/* NEXT QUESTION PREFIX  */}
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Next Question Prefix"
                      size="small"
                      variant="outlined"
                      // Text
                      {...register("Next Question Prefix", {
                        //required: "Next Question Prefix Required",
                      })}
                      error={Boolean(errors?.["Next Question Prefix"])}
                      helperText={errors?.["Next Question Prefix"]?.message}
                    />
                  </Grid>
                  {/* HELP TEXT  */}
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Helptext"
                      variant="outlined"
                      size="small"
                      // Text
                      {...register("Help Text", {
                        //required: "Help Text Required",
                      })}
                      error={Boolean(errors?.["Help Text"])}
                      helperText={errors?.["Help Text"]?.message}
                    />
                  </Grid>
                  {/* TEMPLATE NAME  */}
                  <>
                    <Grid item xs={6}>
                      {/* <Controller
                        name="Template Name"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            filterSelectedOptions
                            defaultValue={templateList.find(
                              (option) =>
                                option.name === data?.["Template Name"]
                            )}
                            getOptionLabel={(option) => option.name}
                            id="combo-box-demo"
                            onChange={(event, value) =>
                              field.onChange(value.name ? value.name : "")
                            } // Store the entire selected option
                            options={templateList}
                            renderInput={(params) => (
                              <TextField
                                // error={!!errors.masterCompanyName}
                                // helperText={errors.masterCompanyName?.message}
                                id="Template Name"
                                label="Template Name"
                                {...params}
                              />
                            )}
                          />
                        )}
                      /> */}
                      <TextField
                        sx={{ width: "100%" }}
                        label="Template Name"
                        variant="outlined"
                        size="small"
                        // Text
                        {...register("Template Name", {
                          //required: "Help Text Required",
                        })}
                        // error={Boolean(errors?.["Help Text"])}
                        // helperText={errors?.["Help Text"]?.message}
                      />
                    </Grid>
                  </>
                  {/* EXPECT DOCUMENT */}
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      error={Boolean(errors.expectDocument)}
                    >
                      {/* Bot Interpreted Select Input */}
                      <InputLabel id="demo-simple-select-required-label">
                        Expect Document
                      </InputLabel>
                      <Controller
                        name="expectDocument"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="Expect Document"
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              {/* <MenuItem value={"true"}>True</MenuItem>
                                  <MenuItem value={"false"}>False</MenuItem> */}
                              <MenuItem value={"yes"}>Yes</MenuItem>
                              <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* DOCUMENT TYPE  */}
                  {/*
                  <Grid item xs={6}>
                    <FormControl

                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      error={Boolean(errors.documentType)}
                    >
                      <InputLabel id="documentType">Document Type</InputLabel>
                      <Controller
                        name="documentType"
                        control={control}
                        defaultValue=""
                        // rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="documentType"
                              id="documentTypeId"
                              label="Document Type"
                              defaultValue=""
                              //style={{ height: "43px" }}
                              error={Boolean(errors.documentType)}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"resume"}>Resume</MenuItem>
                              <MenuItem value={"coverLetter"}>
                                Cover Letter
                              </MenuItem>
                              <MenuItem value={"experienceLetters"}>
                                Experience Letters
                              </MenuItem>
                              <MenuItem value={"pancard"}>Pan Card</MenuItem>
                              <MenuItem value={"aadhar"}>Aadhar</MenuItem>
                              <MenuItem value={"passport"}>Passport</MenuItem>
                              <MenuItem value={"previousOffer"}>
                                Previous Offer
                              </MenuItem>
                              <MenuItem value={"educationalCertificates"}>
                                Educational Certificates
                              </MenuItem>
                              <MenuItem value={"certificates"}>
                                Certificates
                              </MenuItem>
                              <MenuItem value={"other"}>Other</MenuItem>
                            </Select>
                            {errors.documentType && (
                              <FormHelperText error>
                                {errors.documentType.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  */}
                  {/* DOCUMENT CATEGORIES */}
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 11, minWidth: 150, width: "100%" }}
                      size="small"
                      error={Boolean(errors.documentCategory)}
                    >
                      <InputLabel id="documentType">
                        Document Categories
                      </InputLabel>
                      <Controller
                        name="documentCategory"
                        control={control}
                        defaultValue=""
                        rules={{
                          required:
                            expectDocumentWatch === "yes" ? true : false,
                        }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="documentCategory"
                              id="documentCategory"
                              label="Document Categories"
                              style={{ height: "43px" }}
                              error={Boolean(errors.documentCategory)}
                              disabled={
                                expectDocumentWatch !== "yes" ? true : false
                              }
                            >
                              {documentCategories?.map((cate, idx) => {
                                return (
                                  <MenuItem key={idx} value={cate.code}>
                                    {cate.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.documentCategory && expectDocumentWatch == "yes" && (
                              <FormHelperText error>
                                {errors.documentCategory.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* DOCUMENT TYPE/CATEGORIES */}
                  <>
                    {documentSubCategories.length > 0 && (
                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          style={{
                            marginTop: 11,
                            minWidth: 150,
                            width: "100%",
                          }}
                          size="small"
                          error={Boolean(errors.documentType)}
                        >
                          <InputLabel id="documentType">
                            Document Type
                          </InputLabel>
                          <Controller
                            name="documentType"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Please select an option" }}
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  labelId="documentType"
                                  id="documentType"
                                  label="Document Type"
                                  defaultValue=""
                                  style={{ height: "43px" }}
                                  error={Boolean(errors.documentType)}
                                  disabled={
                                    expectDocumentWatch !== "yes" ? true : false
                                  }
                                  // onChange={handleCategoryOnChange}
                                >
                                  {/* <MenuItem value={""}>
                                Please select an option
                              </MenuItem> */}
                                  {documentSubCategories.map((scate, idx) => {
                                    return (
                                      <MenuItem key={idx} value={scate.name}>
                                        {scate.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                {errors.documentType && (
                                  <FormHelperText error>
                                    {errors.documentType.message}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"right"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Button
                    sx={{
                      borderRadius: "6px",
                      // background: "#1E0D61",
                      backgroundColor: "primary.main",
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        //background: "#220986",
                        backgroundColor: "primary.dark",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleClose}
                    sx={{
                      borderRadius: "6px",
                      // background: "#FE9A25",
                      backgroundColor: "secondary.main",
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        // background: "#e9983c",
                        backgroundColor: "secondary.dark",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </form>
          </Box>
        </Modal>
        <DeleteAlert
          open={openSimilar}
          onClose={() => {
            setOpenSimilar(false);
          }}
          handleClose={() => {
            setOpenSimilar(false);
          }}
          handleDelete={handleSimilarQuestionSubmit}
          header={`${isSameQuestionExist ? "Same" : "Similar"} Question`}
          message={`${
            isSameQuestionExist ? "Same" : "Similar"
          } question is available do you still want to save this question?`}
          rightButton={"Create"}
        />
      </div>
    </>
  );
};

export default EditSelectedQuestionModal;

// COSTUM SLIDER COMPONENT
const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "blue",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "blue",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
