import * as types from "./actionTypes";

const initailState = {
  isLoading: false,
  country: [],
  length: 0,
  isError: false,
};

export const countryReducer =
  (state = initailState,action) => {
    const {payload,datalength,type}=action
    switch (type) {
      case types.GET_COUNTRY_REQUEST: {
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      }
      case types.GET_COUNTRY_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          country: payload,
          length: datalength,
          isError: false,
        };
      }
      case types.GET_COUNTRY_FAILURE: {
        return {
          ...state,
          isLoading: false,
          country: [],
          isError: true,
        };
      }
      default:return state
    }
  };
