import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Select,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchFeild from "../Components/Table/SearchFeild";
import RecruiterTable from "../Components/Table/RecruiterTable";
import { useForm, Controller } from "react-hook-form";
import {
  getRecruitmentCompanyActionFn,
  postRecruitmentCompanyActionFn,
  editRecruitmentCompanyActionFn,
  deleteRecruitmentCompanyActionFn,
  getIflowChildCompanyUsingParentId,
} from "../Redux/RecruitmentCompany/action";
import { getCountryData } from "../Redux/Country/action";
import { environmentDetails } from "../environment/environment";
import { useDispatch, useSelector } from "react-redux";
import ApiAlert from "../Components/AlertBox/ApiAlert";
import {
  editCompanyStatus,
  getHiringCompanyData,
  getHiringCompanyThroughData,
  getIflowHirngCompany,
  postHiringCompanyInFlow,
} from "../Redux/HiringCompany/action";
import { getChildCompanies, getCompanyNumber } from "../Redux/Templates/action";
import PeopleToNotifyRepeater from "./PeopleToNotifyRepeater.jsx";
import TemplateRepeater from "./TemplateRepeater.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDebounce } from "../hooks/useDebounce.js";
import "./RecruiterTabDashboard.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ReuseableLabel from "../Components/ReusableComponents/ReuseableLabel.jsx";
import { schema } from "../Components/Table/RecruiterTable.jsx";
import { validatePeopleToBeNotified } from "../constants/recruiterTab.js";
import { getJobRoleData } from "../Redux/JobRole/action.js";
import { getQuestionsData } from "../Redux/Questions/action.js";
import { createNewOpeningActionFn } from "../Redux/QuestionPacakge/action.js";
import EmailRepeaters from "../Components/Repeaters/EmailRepeaters.jsx";
import Tooltip from "@mui/material/Tooltip";
import { removePlusFromPhoneNumber } from "../utils/helperFunctions.js";
import { useColorMode } from "../hooks/useColorMode.js";
const apiUrl = environmentDetails.apiUrl;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "85%",
  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const RecruiterTabDashboard = () => {
  var flag = 0;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [search, setSearch] = useState("");
  const [masterCompanyId, setMasterCompanyId] = useState("");
  const [masterCompanyName, setMasterCompanyName] = useState("");
  const { getThemeColor } = useColorMode();
  const [template, setTemplate] = useState([
    {
      templateIndex: "",
      templateName: "",
      paramNumber: "",
      params: [],
      verbiage: "",
    },
  ]);

  const [newSubmit, setNewSubmit] = useState(false);
  const [countryDetails, setCountryDetails] = useState([]);
  const [countrySelected, setCountrySelected] = useState("");
  const [countryInternalSelected, setCountryInternalSelected] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phonePrefixInternal, setPhonePrefixInternal] = useState("");
  const [recruiterCompanyDecLength, setRecruiterCompanyDecLength] = useState(0);
  const { debouncedValue } = useDebounce(search, 1500);
  const [notify, setNotify] = useState([
    { cname: "", cphone: "", email: "", cphonecode: countrySelected },
  ]);
  const [emailNotify, setEmailNotify] = useState([{ email: "" }]);
  const [peopleToNotifyAlert, setPeopleToNotifyAlert] = useState("");
  const [repeaterError, setRepeaterError] = useState(false);
  const [emailValidate, setEmailValidate] = useState(false);
  const [iflowCompanyId, setIflowCompanyId] = useState("");

  // handle open & close modal function
  const handleOpen = () => setOpen(true);

  const notifyInitialState = [
    { cname: "", cphone: "", email: "", cphonecode: countrySelected },
  ];
  const emailInitialState = [{ email: "" }];
  const templateInitialState = [
    {
      templateIndex: "",
      templateName: "",
      paramNumber: "",
      params: [],
      verbiage: "",
    },
  ];

  const [templateClick, setTemplateClick] = useState(false);

  const [conditionCheck, setConditionCheck] = useState(true);

  const initailState = {
    name: "",
    website: "",
    description: "",
    masterCompanyId: "",
    masterCompanyName: "",
    companyForHint: "",
    phone_number_id: "",
    phone_number_internal: "",
    phone_number: "",
    phone_number_id_internal: "",
    linkedinPage: "",
    instagramPage: "",
    careerPage: "",
    xHandle: "",
    reapplyDuration: "",
    //peopleToBeNotified:notify,
    // cname:"",
    // cphone:"",
    // email:""
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: initailState,
    resolver: yupResolver(schema),
  });

  // const currentValues = getValues();
  const phoneNumberId = watch("phone_number_id");
  const phoneNumberInternal = watch("phone_number_internal");
  const phoneNumber = watch("phone_number");
  const phoneNumberIdInternal = watch("phone_number_id_internal");

  const [childCompanySelected, setChildCompanySelected] = useState("");
  const [recruiterCompanySelected, setRecruiterCompanySelected] = useState("");
  const [childCompanyDetails, setChildCompanyDetails] = useState("");
  const [recruiterCompanyDetails, setRecruiterCompanyDetails] = useState("");
  const [valuex, setValuex] = useState(null);
  const [iflowCompanyData, setIflowCompanyData] = useState("");

  // console.log("valuex",valuex)
  const handleClose = (flg) => {
    setOpen(false);
    setNewSubmit(false);
    reset(initailState);
    setMasterCompanyId("");
    setMasterCompanyName("");
    setNotify(notifyInitialState);
    setTemplate(templateInitialState);
    setEmailNotify(emailInitialState);
    setTemplateClick(false);
    setChildCompanyDetails("");
    setRecruiterCompanyDetails("");
    setChildCompanySelected("");
    setRecruiterCompanySelected("");
    setCountrySelected("");
    setCountryInternalSelected("");
    setConditionCheck(true);
    setValuex(null);
    if (flg == "false") {
      console.log("delete recruiment company name to be implemented");
    }
  };
  // get recruiter companies;

  // console.log("masterCompanyId", masterCompanyId);
  // console.log("iflowCompanyId", iflowCompanyId);
  const handleiFlowData = (valuex) => {
    dispatch(
      postHiringCompanyInFlow({
        resource: {
          name: valuex,
          code: null,
          friendlyname: valuex,
          parentid: iflowCompanyId,
          comptype: "CUSTOMER",
          contacts: [],
          aliases: [],
          vendorsapid: [],
          customersapid: [],
          accountManagers: [],
          otherEmails: [],
          otherPhoneNumbers: [],
          partnerLevel: "",
          addresses: [
            {
              line: "",
              country: "India",
              state: "",
              city: "",
              postalcode: "",
              type: "PA",
              friendlyaddress: "",
            },
          ],
          group: null,
          primaryEmailId: "",
          primaryPhoneNumber: "",
          registered: "India",
          employeeName: "",
          employeeAddress: "",
          dob: "",
          aadhaarId: "",
          panId: "",
          companyId: "",
          gstinIds: [],
          ieCode: "",
          blackList: false,
          isactive: true,
          aadhaar: {
            aadhaarId: "",
            name: "",
            address: "",
            gender: "",
            care_of: "",
            dob: "",
            email: "",
            id: "",
            message: "",
            mobile_hash: "",
            photo_link: "",
            ref_id: "",
            split_address: null,
            status: "",
            year_of_birth: "",
          },
          pan: {
            id: "",
            name: "",
            dob: "",
            fathersName: "",
          },
          flags: {
            mobilemsa: null,
            voicemsa: null,
            smsmsa: null,
            iotmsa: null,
            nda: null,
            ndaiot: null,
          },
          processName: "Initiation Process Pre-Screening",
        },
      })
    )
      .then((res) => {
        if(res.type==="POST_IFLOWHIRINGCOMPANY_FAILURE"){
          setAlert(true);
          setAlertStatus(false);
          setalertcontent(res?.error);
          setValuex(null)
          return;
        }
        // console.log("dataGET", res.dataIflowHiringCompany);
        dispatch(getHiringCompanyThroughData(res?.dataIflowHiringCompany))
          .then((res) => {
            // console.log("helllooejdidbd", res?.idFlowHiringCompany?.code);
            // setAlert(true);
            // setAlertStatus(res.idFlowHiringCompany.status);
            // setalertcontent(res.idFlowHiringCompany.message);
            setIflowCompanyData(res?.idFlowHiringCompany?.code);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const iflowHiringcompanyData = useSelector(
    (state) => state.hiringCompanyReducer.iflowHiringCompany
  );

  const iflowChildCompanyData = useSelector(
    (state) => state.recruitmentReducer?.iflowChildCompany
  );
  console.log("childCompany",iflowChildCompanyData)

  useEffect(() => {
    dispatch(getIflowHirngCompany());
  }, []);

  useEffect(() => {
    // console.log("x",res?.idFlowHiringCompany?.id)
    // console.log("y",iflowCompanyId)

    if (
      masterCompanyId !== null &&
      masterCompanyId !== undefined &&
      masterCompanyId !== ""
    ) {
      if(masterCompanyId?.CompanyId != undefined){
        dispatch(getHiringCompanyThroughData(masterCompanyId))
          .then((res) => {
            console.log("CALLINGADDCHILDCOM", masterCompanyId);
            if(res?.idFlowHiringCompany?.id){
              dispatch(
                getIflowChildCompanyUsingParentId(res?.idFlowHiringCompany?.id)
              );
              console.log("CALLINGADDCHILDCOMAFTER", masterCompanyId);
              setIflowCompanyId(res?.idFlowHiringCompany?.id);
              // console.log("getHiringCompanyThroughData",res?.idFlowHiringCompany?.id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [masterCompanyId]);

  const recruitmentData = useSelector((state) => state.recruitmentReducer.data);
  const recruitmentDataLength = useSelector(
    (state) => state.recruitmentReducer.length
  );

  const [hiringCompanies, sethiringCompanies] = useState([]);
  const [metaCompanyDetails, setMetaCompanyDetails] = useState([]);

  useEffect(() => {
    dispatch(getHiringCompanyData(1, 5000)).then((res) => {
      sethiringCompanies(res.payload);
    });
  }, []);

  useEffect(() => {
    dispatch(getChildCompanies()).then((res) => {
      setMetaCompanyDetails(res.payload);
    });
  }, []);

  var filteredArray2 = metaCompanyDetails.filter(
    (object) => object.id !== recruiterCompanySelected
  );

  var filteredArray1 = metaCompanyDetails.filter(
    (object) => object.id !== childCompanySelected
  );

  useEffect(() => {
    if (childCompanySelected != "") {
      dispatch(getCompanyNumber(childCompanySelected)).then((res) => {
        let childDetails = res.payload[0];
        if (
          childDetails != undefined &&
          childDetails["display_phone_number"] != undefined
        ) {
          let childCompanyArr = childDetails["display_phone_number"].split(" ");
          setCountrySelected(childCompanyArr[0]);
          setChildCompanyDetails({
            display_phone_number: childCompanyArr[1] + childCompanyArr[2],
            internal_id: childDetails["id"],
            id: childCompanySelected,
          });
        }
      });
    }
  }, [childCompanySelected]);

  useEffect(() => {
    if (recruiterCompanySelected != "") {
      dispatch(getCompanyNumber(recruiterCompanySelected)).then((res) => {
        let recruiterDetails = res.payload[0];
        if (
          recruiterDetails != undefined &&
          recruiterDetails["display_phone_number"] != undefined
        ) {
          let recruitmentCompanyArr =
            recruiterDetails["display_phone_number"].split(" ");
          setCountryInternalSelected(recruitmentCompanyArr[0]);
          setRecruiterCompanyDetails({
            display_phone_number:
              recruitmentCompanyArr[1] + recruitmentCompanyArr[2],
            internal_id: recruiterDetails["id"],
            id: recruiterCompanySelected,
          });
        }
      });
    }
  }, [recruiterCompanySelected]);

  var updatedHiringCompanies = [];
  hiringCompanies.forEach((val, key) => {
    if (val.companyStatus != false) {
      updatedHiringCompanies.push(val);
    }
  });

  const getRecruitmentCompanyFn = () => {
    dispatch(
      getRecruitmentCompanyActionFn(page + 1, rowsPerPage, debouncedValue)
    );
  };
  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  let menustr = "";
  useEffect(() => {
    // getRecruitmentCompany();
    //dispatch(getRecruitmentCompanyActionFn(page + 1, rowsPerPage));
    getRecruitmentCompanyFn();
    dispatch(getCountryData())
      .then((res) => {
        // console.log(res.payload)
        // Changing the priority of the Country Data
        let countryData = res.payload;
        let newCountryData = [];
        countryData.forEach((resp) => {
          if (resp.numeric_code == "356") {
            newCountryData.push(resp);
          }
          if (resp.numeric_code == "840") {
            newCountryData.push(resp);
          }
        });
        newCountryData.sort(function (a, b) {
          return compareStrings(a.name, b.name);
        });
        let newCountryData1 = countryData;
        newCountryData1.sort(function (a, b) {
          return compareStrings(a.name, b.name);
        });
        newCountryData1.forEach((res) => {
          newCountryData.push(res);
        });
        // Changing the priority of the Country Data
        setCountryDetails(newCountryData);
        setPhonePrefix(newCountryData[0]["phone_code"]);
        setPhonePrefixInternal(newCountryData[0]["phone_code"]);
        setCountrySelected(newCountryData[0]["phone_code"]);
        setCountryInternalSelected(newCountryData[0]["phone_code"]);
      })
      .catch((err) => {
        console.log(err);
      });
    // getHiringCompany();
    // dispatch(getHiringCompanyData());
  }, [page, rowsPerPage, debouncedValue, open]);

  useEffect(() => {
    if (peopleToNotifyAlert) {
      setTimeout(() => {
        setPeopleToNotifyAlert("");
      }, 4000);
    }
  }, [peopleToNotifyAlert !== "" ? true : false]);

  const jobRoles = useSelector((state) => state.jobRoleReducer.jobRoles);
  const defaultJobRole = jobRoles?.data?.filter(
    (el) => el?.role == "Default job role" || el?.jobId == "1704710825374"
  );

  const masterQuestions = useSelector(
    (state) => state.questionReducer.questions
  );

  const [selectedQue, setSelectedQue] = useState([]);
  const defaultQuestions = masterQuestions?.filter(
    (el) => el.questionType === "Default Question"
  );

  useEffect(() => {
    dispatch(getJobRoleData(1, 50000));
    dispatch(getQuestionsData(1, 50000));
    dispatch(getIflowHirngCompany());
  }, []);

  useEffect(() => {
    setValue("phone_number", childCompanyDetails?.display_phone_number);
    setValue("phone_number_id", childCompanyDetails?.internal_id);
    setValue(
      "phone_number_internal",
      recruiterCompanyDetails?.display_phone_number
    );
    setValue("phone_number_id_internal", recruiterCompanyDetails?.internal_id);
  }, [childCompanyDetails]);

  const onSubmit = (data) => {
    // setValue("phone_number", childCompanyDetails.display_phone_number);
    // setValue("phone_number_id", childCompanyDetails.internal_id);
    // setValue(
    //   "phone_number_internal",
    //   recruiterCompanyDetails.display_phone_number
    // );
    // setValue("phone_number_id_internal", recruiterCompanyDetails.internal_id);

    setNewSubmit(true);
    // if (
    //   masterCompanyId === "" ||
    //   masterCompanyName === null ||
    //   notify[0].cname === "" ||
    //   notify[0].cphone === "" ||
    //   notify[0].email === ""
    // ) {
    //   console.log("please select all fields");
    //   return;
    // }
    var finalTemplateValues = {};
    template.forEach((val) => {
      if (val.templateIndex != "") {
        finalTemplateValues[val.templateIndex] = {
          name: val.templateName,
          params: val.params.toString(),
          verbiage: val.verbiage,
        };
      }
    });

    let ress = validatePeopleToBeNotified(notify);
    // console.log("ressssssssssssssss----> ", ress);
    if (!ress.status) {
      setPeopleToNotifyAlert(ress.message);
      setRepeaterError(true);
      return;
    }
    setRepeaterError(false);
    setPeopleToNotifyAlert(null);
    flag = false;
    emailNotify.forEach((el) => {
      if (el.isDuplicate === true) {
        //setEmailValidate(true);
        //return;
        flag = true;
        return;
      }
    });
    if (flag === true) {
      setEmailValidate(true);
      //console.log("sahna")
      return;
    }
    console.log("iflowdata", iflowCompanyData);

    setEmailValidate(false);
    data = {
      ...data,
      masterCompanyId,
      masterCompanyName,
      companyId: valuex?.code || iflowCompanyData,
      emailIdToListen: emailNotify,
      peopleToBeNotified: notify,
      // templates: template,
      templates: finalTemplateValues,
    };
    console.log("DATAAAAAAAAA",data)
    data.phone_number_prefix = phonePrefix;
    data.phone_number_internal_prefix = phonePrefixInternal;
    data.recruiterMetaCompany = recruiterCompanySelected;
    data.childMetaCompany = childCompanySelected;
    let companyId;
    let openingId = "null";

    dispatch(postRecruitmentCompanyActionFn(data))
      .then((res) => {
        companyId = res.payload.data.CompanyId;
        if (res.type === "POST_RECRUITMENT_COMPANY_SUCCESS") {
          setAlert(true);
          setNewSubmit(false);
          setAlertStatus(res.payload.status);
          setalertcontent(res.payload.message);
          reset(initailState);
          handleClose("true");
          setTemplateClick(true);
          // console.log("x", defaultJobRole[0].jobId);
          console.log("comapnyId", companyId);
          // console.log("z", [defaultQuestions]);
          dispatch(getRecruitmentCompanyActionFn());
          dispatch(
            createNewOpeningActionFn(openingId, {
              companyIds: [companyId],
              jobRoleId: defaultJobRole[0].jobId,
              selectedQuestionList: defaultQuestions,
              //peopleToBeNotified: notify
            })
          ).then((res) => {
            console.log(res);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose("false");
  };

  // delete a recruiter company
  const deleteRecruiterCompany = (companyId) => {
    dispatch(deleteRecruitmentCompanyActionFn(companyId))
      .then((res) => {
        setAlert(true);
        setAlertStatus(res.payload.status);
        setalertcontent(res.payload.message);
        setOpenDeleteModal(false);
        // dispatch(getRecruitmentCompanyActionFn());
        getRecruitmentCompanyFn();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // edit a recruiter company details
  const editRecruiterCompanyDetails = (data) => {
    // let ress = validatePeopleToBeNotified(data.peopleToBeNotified);
    // console.log("resss-----> ",ress);
    // if(!ress.status){
    //   setRepeaterError(true)
    //   setPeopleToNotifyAlert(ress.message)
    //   return;
    // }
    // console.log("edit data------> ",data)
    // //return
    // return
    dispatch(editRecruitmentCompanyActionFn(data.CompanyId, data))
      .then((res) => {
        setAlert(true);
        setAlertStatus(res.payload.status);
        setalertcontent(res.payload.message);
        // dispatch(getRecruitmentCompanyActionFn());
        getRecruitmentCompanyFn();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSwitch = (data) => {
    const status = !data.companyStatus;
    const id = data.CompanyId;
    console.log("statusChange", status);
    dispatch(editCompanyStatus(id, status))
      .then((res) => {
        if (res.payload.status === 200) {
          setAlert(true);
          setAlertStatus(res.payload.data.status);
          setalertcontent(res.payload.data.message);
        } else {
          setAlert(true);
          setAlertStatus(res.payload.response.data.status);
          setalertcontent(res.payload.response.data.message);
        }
        setOpenDeleteModal(false);
        dispatch(getRecruitmentCompanyActionFn());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleDescriptionKeyUp = (event) => {
    setRecruiterCompanyDecLength(event.target.value.length);
  };
  //console.log("rec data length---->: ",recruitmentDataLength)

  const handlePhoneChange = (event) => {
    // console.log(event.target.value)
    let phone_prefix = event.target.value;
    setPhonePrefix(phone_prefix);
  };

  const handlePhoneInternalChange = (event) => {
    // console.log(event.target.value)
    let phone_internal_prefix = event.target.value;
    setPhonePrefixInternal(phone_internal_prefix);
  };

  const handleTemplate = () => {
    setValue("phone_number", childCompanyDetails.display_phone_number);
    setValue("phone_number_id", childCompanyDetails.internal_id);
    setValue(
      "phone_number_internal",
      recruiterCompanyDetails.display_phone_number
    );
    setValue("phone_number_id_internal", recruiterCompanyDetails.internal_id);

    const formValues = getValues();

    if (templateClick === false) {
      if (
        formValues.phone_number?.length >= 10 &&
        formValues.phone_number_id?.length >= 10 &&
        formValues.phone_number_internal?.length >= 10 &&
        formValues.phone_number_id_internal?.length >= 10
      ) {
        setTemplateClick(true);
        setConditionCheck(true);
      } else {
        setConditionCheck(false);
      }
    } else if (templateClick === true) {
      setTemplateClick(false);
    }
  };

  const templateID = {
    childCompanyID: childCompanyDetails.id,
    recruiterCompanyID: recruiterCompanyDetails.id,
  };
 
  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      {/* {peopleToNotifyAlert && (
        <ApiAlert
          open={peopleToNotifyAlert?true:false}
          snackbaronClose={()=>setPeopleToNotifyAlert("")}
          alertonClose={()=>setPeopleToNotifyAlert("")}
          alertType={"warning"}
          Content={peopleToNotifyAlert}
        />
      )} */}
      <Box style={{ width: "100%" }}>
        <SearchFeild
          buttonName="Add Recruitment Company"
          placeholder="Search Recruitment Company"
          width="100%"
          TextFieldWidth="30%"
          large="0px"
          Navigate={handleOpen}
          onChange={(e) => setSearch(e.target.value)}
          marginBottom={"15px"}
          roleModule="Recruitment Company"
        />
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  border: "0px solid blue",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 600,
                    // color: "#2B2A2C",
                    color: "primary.darkText",
                  }}
                >
                  Add Recruitment Company
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  options={updatedHiringCompanies.filter(
                    (el) => el.companyStatus === true
                  )}
                  sx={{
                    width: "100%",
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  onChange={(event, value) => {
                    setMasterCompanyId(value?.CompanyId ? value.CompanyId : "");
                    setMasterCompanyName(value?.name ? value.name : "");
                  }}
                  renderInput={(params, idx) => {
                    // console.log("Params: ", params);
                    return (
                      <TextField
                        {...params}
                        label=<ReuseableLabel label={"Hiring Company"} />
                        error={newSubmit && masterCompanyId === ""}
                        helperText={
                          newSubmit &&
                          masterCompanyId === "" &&
                          "Hiring Company is required"
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name="name"
                  control={control}
                  // defaultValue={null}
                  // rules={{ required: "Please select an option" }} // Validation rule
                  render={({ field }) => (
                    <Autocomplete
                      sx={{ width: "100%" }}
                      value={valuex}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setValuex({
                            name: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          handleiFlowData(newValue.inputValue);
                          // Create a new value from the user input
                          setValuex({
                            name: newValue.inputValue,
                          });
                        } else {
                          setValuex(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const { inputValue } = params;

                        const uniqueNames = new Set();

                        const filtered = options.filter((option) => {
                          const inputLowerCase = inputValue.toLowerCase();
                          const optionNameLowerCase = option.name.toLowerCase();

                          if (uniqueNames.has(optionNameLowerCase)) {
                            return false; // Skip this option if the name is a duplicate
                          }
                          uniqueNames.add(optionNameLowerCase);

                          return true;
                        });

                        const matchedOptions = filtered.filter((option) =>
                          option.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        );

                        if (
                          inputValue !== "" &&
                          !uniqueNames.has(inputValue.toLowerCase())
                        ) {
                          matchedOptions.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return matchedOptions;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={iflowHiringcompanyData || []}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <ReuseableLabel
                              label={"Recruitment Company Name"}
                            />
                          }
                          {...register("name")}
                          error={Boolean(errors.name)}
                          helperText={errors.name?.message}
                        />
                      )}
                    />
                  )}
                />
                {/* <TextField
                  sx={{ width: "100%" }}
                  label={<ReuseableLabel label={"Recruitment Company Name"} />}
                  name="name"
                  variant="outlined"
                  {...register("name", {})}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                /> */}
                <TextField
                  sx={{ width: "100%" }}
                  label={<ReuseableLabel label={"Company Website"} />}
                  variant="outlined"
                  name="website"
                  {...register("website", {})}
                  error={Boolean(errors.website)}
                  helperText={errors.website?.message}
                />
                <Stack
                  direction={"row"}
                  gap={1}
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Autocomplete
                    id="recruiterCompanies"
                    // labelId="recruiterCompanies-label"
                    options={metaCompanyDetails}
                    // options={recruiterCompanySelected !== "" ? filteredArray1 : metaCompanyDetails}
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      width: "50%",
                    }}
                    onChange={(event, value) => {
                      setRecruiterCompanyDetails("");
                      setRecruiterCompanySelected(value?.id ? value.id : "");
                    }}
                    renderInput={(params, idx) => {
                      // console.log("Params: ", params);
                      return (
                        <TextField
                          {...params}
                          label=<ReuseableLabel
                            label={"Meta Company for Recruiter"}
                          />
                          error={newSubmit && recruiterCompanySelected === ""}
                          helperText={
                            newSubmit &&
                            recruiterCompanySelected === "" &&
                            "Meta Company for Recruiter is required"
                          }
                        />
                      );
                    }}
                  />
                  <Autocomplete
                    id="childCompanies"
                    // labelId="childCompanies-label"
                    // options={childCompanySelected !== "" ? filteredArray2  : metaCompanyDetails}
                    options={metaCompanyDetails}
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      width: "50%",
                    }}
                    onChange={(event, value) => {
                      setChildCompanyDetails("");
                      setChildCompanySelected(value?.id ? value.id : "");
                    }}
                    renderInput={(params, idx) => {
                      // console.log("Params: ", params);
                      return (
                        <TextField
                          {...params}
                          label=<ReuseableLabel
                            label={"Meta Company for Candidate"}
                          />
                          error={newSubmit && childCompanySelected === ""}
                          helperText={
                            newSubmit &&
                            childCompanySelected === "" &&
                            "Meta Company is required"
                          }
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.1}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Select
                    id="phone_flag"
                    // labelId="phone_flag-label"
                    // onChange={(e) => {
                    //     handlePhoneChange(e);
                    //     // field.onChange(e);
                    //   }
                    // }
                    sx={{ width: "8%" }}
                    defaultValue={countrySelected}
                    onChange={handlePhoneChange}
                  >
                    {countryDetails
                      ? countryDetails.map((res) => {
                          let flagstr = res.iso2;
                          if (flagstr == "UM") {
                            flagstr = "US";
                          }
                          let code =
                            "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                            flagstr +
                            ".svg";
                          let phone_code = "";
                          if (!res.phone_code.includes("+")) {
                            phone_code = "+" + res.phone_code;
                          } else {
                            phone_code = res.phone_code;
                          }

                          if (flag == 1) {
                            menustr = (
                              <MenuItem
                                key={res.iso2}
                                value={res.phone_code}
                                sx={{ borderBottom: "2px solid #ccc" }}
                              >
                                <img
                                  src={code}
                                  width="25"
                                  style={{ marginRight: "6px" }}
                                />{" "}
                                {res.name} ({res.native}) {phone_code}
                              </MenuItem>
                            );
                          } else {
                            menustr = (
                              <MenuItem value={res.phone_code}>
                                <img
                                  src={code}
                                  width="25"
                                  style={{ marginRight: "6px" }}
                                />{" "}
                                {res.name} ({res.native}) {phone_code}
                              </MenuItem>
                            );
                          }

                          if (flag == countryDetails.length - 1) {
                            flag = 0;
                          } else {
                            flag++;
                          }
                          return menustr;
                        })
                      : ""}
                  </Select>
                  <TextField
                    sx={{ width: "48.5%" }}
                    label={
                      <ReuseableLabel label={"WhatsApp Candidate Connect #"} />
                    }
                    variant="outlined"
                    inputProps={{ maxLength: 15 }}
                    {...register("phone_number", {})}
                    value={
                      childCompanyDetails
                        ? childCompanyDetails.display_phone_number
                        : ""
                    }
                    error={
                      (conditionCheck == false && phoneNumber?.length == 0) ||
                      Boolean(errors.phone_number)
                    }
                    helperText={
                      conditionCheck == false && phoneNumber?.length == 0
                        ? "This field is required"
                        : errors.phone_number?.message
                    }
                    id={
                      phoneNumber?.length == 0
                        ? "outlined-error-helper-text"
                        : ""
                    }
                  />
                  <IconButton
                    onClick={() => handleClose("false")}
                    sx={{ position: "absolute", top: "0", right: "0" }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <TextField
                    sx={{ width: "60%" }}
                    label={
                      <ReuseableLabel
                        label={"WhatsApp Candidate Connect Meta ID"}
                      />
                    }
                    variant="outlined"
                    // inputProps={{ maxLength: 15 }}
                    {...register("phone_number_id", {})}
                    value={
                      childCompanyDetails ? childCompanyDetails.internal_id : ""
                    }
                    error={
                      (conditionCheck == false && phoneNumberId?.length == 0) ||
                      Boolean(errors.phone_number_id)
                    }
                    helperText={
                      conditionCheck == false && phoneNumberId?.length == 0
                        ? "This field is required"
                        : errors.phone_number_id?.message
                    }
                    id={
                      phoneNumberId?.length == 0
                        ? "outlined-error-helper-text"
                        : ""
                    }
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.1}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Select
                    id="phone_flag_internal"
                    // labelId="phone_flag-label"
                    defaultValue={countryInternalSelected}
                    onChange={handlePhoneInternalChange}
                  >
                    {countryDetails
                      ? countryDetails.map((res) => {
                          let flagstr = res.iso2;
                          if (flagstr == "UM") {
                            flagstr = "US";
                          }
                          let code =
                            "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                            flagstr +
                            ".svg";
                          let phone_code = "";
                          if (!res.phone_code.includes("+")) {
                            phone_code = "+" + res.phone_code;
                          } else {
                            phone_code = res.phone_code;
                          }
                          if (flag == 1) {
                            menustr = (
                              <MenuItem
                                value={res.phone_code}
                                sx={{ borderBottom: "2px solid #ccc" }}
                              >
                                <img
                                  src={code}
                                  width="25"
                                  style={{ marginRight: "6px" }}
                                />{" "}
                                {res.name} ({res.native}) {phone_code}
                              </MenuItem>
                            );
                          } else {
                            menustr = (
                              <MenuItem value={res.phone_code}>
                                <img
                                  src={code}
                                  width="25"
                                  style={{ marginRight: "6px" }}
                                />{" "}
                                {res.name} ({res.native}) {phone_code}
                              </MenuItem>
                            );
                          }
                          flag++;
                          return menustr;
                        })
                      : ""}
                  </Select>

                  <TextField
                    sx={{ width: "48.5%" }}
                    label={
                      <ReuseableLabel label={"WhatsApp Recruiter Connect #"} />
                    }
                    variant="outlined"
                    inputProps={{ maxLength: 15 }}
                    {...register("phone_number_internal", {})}
                    value={
                      recruiterCompanyDetails
                        ? recruiterCompanyDetails.display_phone_number
                        : ""
                    }
                    error={
                      (conditionCheck == false &&
                        phoneNumberInternal?.length == 0) ||
                      Boolean(errors.phone_number_internal)
                    }
                    helperText={
                      conditionCheck == false &&
                      phoneNumberInternal?.length == 0
                        ? "This field is required"
                        : errors.phone_number_internal?.message
                    }
                    id={
                      phoneNumberInternal?.length == 0
                        ? "outlined-error-helper-text"
                        : ""
                    }
                  />

                  <TextField
                    sx={{ width: "60%" }}
                    label={
                      <ReuseableLabel
                        label={"WhatsApp Recruiter Connect Meta ID"}
                      />
                    }
                    variant="outlined"
                    // inputProps={{ maxLength: 15 }}
                    {...register("phone_number_id_internal", {})}
                    onChange={handleTemplate}
                    value={
                      recruiterCompanyDetails
                        ? recruiterCompanyDetails.internal_id
                        : ""
                    }
                    error={
                      (conditionCheck == false &&
                        phoneNumberIdInternal?.length == 0) ||
                      Boolean(errors.phone_number_id_internal)
                    }
                    helperText={
                      conditionCheck == false &&
                      phoneNumberIdInternal?.length == 0
                        ? "This field is required"
                        : errors.phone_number_id_internal?.message
                    }
                    id={
                      phoneNumberIdInternal?.length == 0
                        ? "outlined-error-helper-text"
                        : ""
                    }
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={2}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    sx={{ width: "100%" }}
                    label=<ReuseableLabel label={"Company Abbreviation"} />
                    variant="outlined"
                    {...register("companyForHint", {})}
                    error={Boolean(errors.companyForHint)}
                    helperText={errors.companyForHint?.message}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.1}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    sx={{ width: "50%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="LinkedIn Page"
                    {...register("linkedinPage")}
                    // error={Boolean(errors.baseUrl)}
                    // helperText={errors.baseUrl?.message}
                    //  style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                  <TextField
                    sx={{ width: "50%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="Instagram Page"
                    {...register("instagramPage")}
                    // error={Boolean(errors.verifyToken)}
                    // helperText={errors.verifyToken?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.1}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    sx={{ width: "50%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="Career Page"
                    {...register("careerPage")}
                    // error={Boolean(errors.baseUrl)}
                    // helperText={errors.baseUrl?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                  <TextField
                    sx={{ width: "50%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="X Handle"
                    {...register("xHandle")}
                    // error={Boolean(errors.verifyToken)}
                    // helperText={errors.verifyToken?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Box
                  // direction={"row"}
                  // gap={2}
                  // spacing={2}
                  sx={{ width: "100%" }}
                >
                  <FormControl
                    variant="outlined"
                    sx={{ width: "100%" }}
                    // style={{ marginTop: 11, minWidth: 150 }}
                    // size="small"
                    error={Boolean(errors.Path)}
                  >
                    {/* Path Select Input */}
                    <InputLabel id="demo-simple-select-required-label">
                      {/* Path* */}
                      <ReuseableLabel label={"Reapply Duration"} />
                    </InputLabel>
                    <Controller
                      name="reapplyDuration"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            // labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            label="Reapply Duration"
                            defaultValue=""
                            // style={{ height: "43px" }}
                            error={Boolean(errors.Path)}
                          >
                            <MenuItem value={""}>
                              Please select an option
                            </MenuItem>
                            <MenuItem value={"0"}>0 day</MenuItem>
                            <MenuItem value={"15"}>15 days</MenuItem>
                            <MenuItem value={"30"}>30 days</MenuItem>
                            <MenuItem value={"90"}>90 days</MenuItem>
                            <MenuItem value={"180"}>180 days</MenuItem>
                            <MenuItem value={"365"}>365 days</MenuItem>
                          </Select>
                          {errors.reapplyDuration && (
                            <FormHelperText error>
                              {errors.reapplyDuration.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ width: "100%", position: "relative" }}>
                  <TextField
                    multiline
                    sx={{ width: "100%" }}
                    label="Company Description"
                    variant="outlined"
                    name="description"
                    rows={5}
                    // maxRows={5}
                    inputProps={{ maxLength: 4000 }}
                    {...register("description")}
                    onKeyUp={handleDescriptionKeyUp}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      position: "absolute",
                      right: "4px",
                      bottom: "-12px",
                      color: `${
                        recruiterCompanyDecLength < 4000 ? "gray" : "red"
                      }`,
                    }}
                  >
                    {recruiterCompanyDecLength}/4000
                  </span>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <EmailRepeaters
                    emailFormValues={emailNotify}
                    setEmailFormValues={setEmailNotify}
                    emailValidate={emailValidate}
                    setEmailValidate={setEmailValidate}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <PeopleToNotifyRepeater
                    formValues={notify}
                    setFormValues={setNotify}
                    countryDetails={countryDetails}
                    countrySelected={countrySelected}
                    repeaterError={repeaterError}
                    setRepeaterError={setRepeaterError}
                  />
                  {peopleToNotifyAlert && (
                    <span style={{ color: "red" }}>{peopleToNotifyAlert}</span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Stack direction={"column"}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      // color: "black",
                      color: "primary.darkText",
                      fontFamily: "sans-serif",
                      mt: "10px",
                    }}
                  >
                    Meta Template :
                  </Typography>
                  {!conditionCheck ? (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontWeight: "400",
                        fontSize: "0.95rem",
                      }}
                    >
                      Please fill the required filed
                    </span>
                  ) : (
                    ""
                  )}
                </Stack>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    borderBottom: `1px solid`,
                    borderBottomColor: getThemeColor("#000", "#fff"),
                    width: "98%",
                  }}
                ></span>
                {templateClick == false ? (
                  <span
                    style={{ width: "1%", cursor: "pointer" }}
                    onClick={handleTemplate}
                  >
                    {conditionCheck ? (
                      <AddCircleIcon
                        fontSize="medium"
                        sx={{ color: "primary.darkText" }}
                      />
                    ) : (
                      <Tooltip
                        title="Please fill the required fields"
                        arrow
                        placement="top-end"
                      >
                        <AddCircleIcon
                          fontSize="medium"
                          sx={{ color: "primary.darkText" }}
                        />
                      </Tooltip>
                    )}
                  </span>
                ) : (
                  <span
                    style={{ width: "1%", cursor: "pointer" }}
                    onClick={handleTemplate}
                  >
                    <RemoveCircleIcon
                      fontSize="medium"
                      sx={{ color: "primary.darkText" }}
                    />
                  </span>
                )}
              </Box>
              <Box sx={{ width: "100%" }}>
                {templateClick == true ? (
                  <TemplateRepeater
                    templateValues={template}
                    setTemplateValues={setTemplate}
                    templateID={templateID}
                  />
                ) : (
                  ""
                )}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Stack
                  direction={"row"}
                  justifyContent={"right"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Button
                    sx={{
                      borderRadius: "6px",
                      textTransform: "none",
                      // background: "#1E0D61",
                      backgroundColor: "primary.main",
                      color: "secondary.main",
                      color: "#fff",
                      "&:hover": {
                        //background: "#220986"
                        backgroundColor: "primary.dark",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => handleClose("false")}
                    sx={{
                      borderRadius: "6px",
                      textTransform: "none",
                      // background: "#FE9A25",
                      // color: "#fff",
                      backgroundColor: "secondary.main",
                      color: "#fff",
                      "&:hover": {
                        //background: "#e9983c"
                        backgroundColor: "secondary.dark",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </form>
          </Box>
        </Modal>
        <RecruiterTable
          recruitmentData={recruitmentData}
          // deleteRecruiterCompany={deleteRecruiterCompany}
          handleChangeSwitch={handleChangeSwitch}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          editRecruiterCompanyDetails={editRecruiterCompanyDetails}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          recruitmentDataLength={recruitmentDataLength}
          countryDetails={countryDetails}
          countrySelected={countrySelected}
        />
      </Box>
    </>
  );
};

export default RecruiterTabDashboard;
